import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import {
  getDateFromEvent,
  getTitleFromEvent,
  getYearWithoutDivision,
  goBackIfUrlStateHavingSearchData,
  makeIcsDescription,
  makeIcsFile,
} from '../../../helpers/commonHelper';
import OverlayBlock from '../../Common/overlayBlock';
import { RootState } from '../../../store/reducers';

interface IProps {
  eventDate?: Date;
  event: any;
  onClose: () => void;
}

const ViewCalendarEventBar = (props: React.PropsWithChildren<IProps>) => {
  const {
    eventDate,
    event,
    onClose,
  } = props;
  const history = useHistory();
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );

  const [icsUrl, setIcsUrl] = useState('');

  const inSDate = getDateFromEvent(event, true);
  const inEDate = getDateFromEvent(event, false);
  const startDate = moment(inSDate, 'DD-MM-YYYY HH:mm:ss');
  const endDate = (inEDate && moment(inEDate, 'DD-MM-YYYY HH:mm:ss')) || null;
  const startDay = startDate.date();
  const endDay = (endDate && endDate.date()) || -1;
  let convertedDate = '';
  if (eventDate
      && (
        (endDate && startDay <= eventDate.getDate() && endDay >= eventDate.getDate())
        || (!endDate && startDay === eventDate.getDate())
      )
  ) {
    convertedDate = moment(eventDate).format('dddd, Do MMMM yyyy');
  } else if (startDate) {
    convertedDate = moment(startDate).format('dddd, Do MMMM yyyy');
  }
  const startTime = (startDate && moment(startDate).format('HH:mm')) || '';
  const endTime = (endDate && moment(endDate).format('HH:mm')) || '';

  let eventTime = 'All Day';
  if (!event.is_all_day) {
    // const start = moment.utc(startDate, 'DD-MM-yyyy hh:mm:ss');
    // const end = moment.utc(endDate, 'DD-MM-yyyy hh:mm:ss');
    // return `${start.local().format('hh:mm')} - ${end.local().format('hh:mm')}`;

    eventTime = `${startTime}`;
    if (endTime) {
      eventTime = `${startTime} - ${endTime}`;
    }
  }

  const onCloseBar = () => {
    const el = document.getElementById('gallery-sidebar');
    if (el) {
      el.classList.remove('!right-0');
      // el.classList.remove('!z-30');
    }
    setTimeout(() => {
      onClose();
      if (!sidebarName) {
        document.body.classList.remove('overflow-sidebar');
      }

      goBackIfUrlStateHavingSearchData(history);
    }, 200);
  };

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    setTimeout(() => {
      document.getElementById('viewevent_popup')!.classList.add('!right-0');
    }, 100);
    const icsFile = makeIcsFile(
      getTitleFromEvent(event),
      startDate,
      endDate,
      event.is_all_day,
      event.location,
      makeIcsDescription(event.description, event?.fixture?.url || event?.url || ''),
    );
    setIcsUrl(icsFile);
  }, []);

  useEffect(() => {
    if (sidebarName) {
      onCloseBar();
    }
  }, [sidebarName]);

  const
    {
      fixture,
      // eslint-disable-next-line camelcase
      is_training,
      years,
      division,
    } = event;
  const yearWithoutDiv = getYearWithoutDivision(years, division);
  const eventTitle = getTitleFromEvent(event);

  return (
    <>
      <div id="viewevent_popup" className="sidebar-popup fixed z-[39] bottom-0 top-16 transition-all duration-300 ease-in-out bg-white w-full overflow-y-auto overflow-auto-themered-only -right-full md:top-24 md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem]">
        <div className="pt-6 px-4 pb-6 sticky z-10 right-0 left-0 top-0 bg-white">
          <div className="flex justify-between items-center">
            <a href={icsUrl} download="event.ics" className="underline underline-offset-4 text-themered text-lg font-bold hover:text-black">Add To Calendar</a>
            <button
              type="button"
              className="h-8 w-8 text-black bg-white bg-opacity-70 group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseBar}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </button>
          </div>
        </div>
        <div className="px-4 pt-6 flex flex-col h-[calc(100%_-_80px)]">
          <div className="pb-6">
            <h2 className="mb-3">{eventTitle}</h2>
            <div className="grid grid-cols-3 pb-3">
              <div className="text-base font-bold">Date</div>
              <div className="text-base col-span-2">{convertedDate}</div>
            </div>
            {fixture ? (
              <>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Meet Time</div>
                  <div className="text-base col-span-2">{fixture.meet_time}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Fixture Time</div>
                  <div className="text-base col-span-2">{fixture.time}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Return Time</div>
                  <div className="text-base col-span-2">{fixture.return_time}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Location</div>
                  <div className="text-base col-span-2">{fixture.location}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Fixture Location</div>
                  <div className="text-base col-span-2">{event.location}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Transport</div>
                  <div className="text-base col-span-2">{fixture.transport}</div>
                </div>
                <div className="grid grid-cols-3 pb-3 post-detail-info">
                  <div className="text-base font-bold">Details</div>
                  {fixture.details && (
                    <div
                      className="text-base col-span-2"
                      dangerouslySetInnerHTML={{ __html: fixture.details }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Time</div>
                  <div className="text-base col-span-2">{eventTime}</div>
                </div>
                <div className="grid grid-cols-3 pb-3">
                  <div className="text-base font-bold">Location</div>
                  <div className="text-base col-span-2">{event.location}</div>
                </div>
                <div className="grid grid-cols-3 pb-3 post-detail-info">
                  <div className="text-base font-bold">Description</div>
                  {event.description && (
                    <p
                      className="text-base col-span-2"
                      dangerouslySetInnerHTML={{ __html: event.description }}
                    />
                  )}
                </div>
              </>
            )}
            <div className="grid grid-cols-3 pb-3">
              <div className="text-base font-bold">Category</div>
              <div className="text-base col-span-2">{event.original_categories}</div>
            </div>
            {(fixture && fixture?.ts && fixture?.ts.length > 0) && (
              <div className="grid grid-cols-3 pb-3">
                <div className="text-base font-bold">Team sheet</div>
                <div className="text-base col-span-2">
                  {fixture?.ts?.map((item: any, index: number) => (
                    <Fragment key={`${item?.first_name} ${item?.last_name}`}>
                      {`${item?.first_name} ${item?.last_name}`}
                      {index !== fixture?.ts.length - 1 && <br />}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
            {/* eslint-disable-next-line camelcase */}
            {(!fixture && !is_training) && (
              <div className="grid grid-cols-3 pb-3">
                <div className="text-base font-bold">Year</div>
                <div className="text-base col-span-2">
                  {yearWithoutDiv?.map((item: any, index: number) => (
                    <Fragment key={item.name}>
                      {item.name}
                      {index !== event.years.length - 1 && <br />}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* event.description && (
            <div className="pb-6">
              <h2 className="h3">Further Information</h2>
              <div className="pt-2">
                <p>{event.description}</p>
              </div>
            </div>
          ) */}
          {(event.url || fixture?.url) && (
            <div className="text-center mt-auto pb-6">
              <a
                href={fixture?.url || event.url}
                target="_blank"
                rel="noreferrer"
                className="btn btn-red w-full"
              >
                Find out more
              </a>
            </div>
          )}
        </div>
      </div>
      <OverlayBlock
        id="viewevent_popup_mask"
        onClickOverlay={onCloseBar}
      />
    </>
  );
};

ViewCalendarEventBar.defaultProps = {
  eventDate: null,
};

export default ViewCalendarEventBar;
