import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import SearchBarItem from './SearchBarItem';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { getSearch } from '../../../actions/mspUser';
import { RootState } from '../../../store/reducers';
import * as uiActionCreators from '../../../store/actioncreators/uiActionCreators';
import OverlayBlock from '../../Common/overlayBlock';
import SearchEdit from './SearchEdit';
import INameId from '../../../interfaces/nameItem';
import ISearchItem from '../../../interfaces/searchItem';
import Loader from '../../Common/Loader';
import { getSelectedRole } from '../../../actions/AuthUtil';
import CommonDropdown from '../../Common/commonDropdown';

interface IProps {
  withoutInput?: boolean,
}

const SearchBar = (props: React.PropsWithChildren<IProps>) => {
  const {
    withoutInput,
  } = props;

  const dispatch = useDispatch();
  const searchBarDivRef = useRef<HTMLDivElement>(null);
  const { setSearchLoading, addSearchHistory } = bindActionCreators(uiActionCreators, dispatch);
  const [barLoading, setBarLoading] = useState<boolean>(false);
  const searchHistory: ISearchItem[] = useSelector(
    (state: RootState) => state.ui.searchItemHistory,
  );
  const gSearchText: string = useSelector(
    (state: RootState) => state.ui.searchText,
  );
  const searchLoading: boolean = useSelector(
    (state: RootState) => state.ui.searchLoading,
  );
  let query: any = null;
  if (window.location.search) {
    query = new URLSearchParams(window.location.search);
  }
  const defaultFilter = query?.get('filter') || null;
  const defaultSort = query?.get('sort') || null;
  const filterDropdownData: INameId[] = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Notices', iconId: SVGID.INBOX_SIDEBAR },
    { id: 2, name: 'News', iconId: SVGID.NEWS_SIDEBAR },
    { id: 3, name: 'Calendar', iconId: SVGID.CALENDAR_SIDEBAR },
  ];
  const sortDropdownData: INameId[] = [
    { id: 0, name: 'Relevance' },
    { id: 1, name: 'Recent' },
  ];

  const [searchData, setSearchData] = useState<any>(null);
  const [selFilter, setSelFilter] = useState<INameId | null>(
    filterDropdownData.find(item => item.id.toString() === defaultFilter) || null,
  );
  const [selSort, setSelSort] = useState<INameId | null>(
    sortDropdownData.find(item => item.id.toString() === defaultSort) || sortDropdownData[0],
  );
  const selRole = getSelectedRole();
  if (selRole) {
    const roleName = selRole?.name || '';
    const foundIndex = filterDropdownData.findIndex(e => e.name === 'Notices');
    if (roleName.toLowerCase() === 'vip' && foundIndex > -1) {
      filterDropdownData.splice(foundIndex, 1);
    }
  }

  const fetchSearchData = (searchValue: string, isSetLoading: boolean) => {
    if (searchValue.trim().length === 0) {
      setSearchData([]);
      return;
    }

    if (isSetLoading) {
      setSearchLoading(true);
    }
    setBarLoading(true);
    getSearch(searchValue, selSort?.id === 1)
      .then((result: any) => {
        // @ts-ignore
        const notices: ISearchItem[] = ((selRole?.name.toLowerCase() !== 'vip' && (!selFilter || selFilter.id === 0 || selFilter.id === 1))
          && result?.notices.map((item: any) => (
            {
              id: item.id,
              title: 'Notices',
              type: 1,
              description: item.title,
              routePath: `/need-to-know?id=${item.id}`,
              approveDate: item.approval_date?.split(' ')[0],
              SVGId: SVGID.INBOX_SIDEBAR,
              relevance: item.relevance,
            } as ISearchItem
          )))
          || [];

        const articles: ISearchItem[] = ((!selFilter || selFilter.id === 0 || selFilter.id === 2)
          && result?.articles.filter((fItem: any) => fItem.news_id).map((item: any) => (
            {
              id: item.id,
              title: 'News',
              type: 2,
              description: item.title,
              routePath: `/newsdetail?id=${item.news_id}&article=${item.id}`,
              approveDate: item.approval_date?.split('T')[0],
              SVGId: SVGID.NEWS_SIDEBAR,
              relevance: item.relevance,
            } as ISearchItem
          ))) || [];

        const events = ((!selFilter || selFilter.id === 0 || selFilter.id === 3)
          && result?.events.map((item: any) => (
            {
              id: item.id,
              title: 'Calendar',
              type: 3,
              description: item.title,
              routePath: `/calendar?id=${item.id}`,
              approveDate: item.start_at?.split('T')[0],
              SVGId: SVGID.CALENDAR_SIDEBAR,
              relevance: item.relevance,
            } as ISearchItem
          ))) || [];

        let rc = 50;
        if (selSort?.id === 0 && (!selFilter || selFilter.id === 0)) {
          rc = 20;
        }
        const newData = [...notices.slice(0, rc), ...articles.slice(0, rc), ...events.slice(0, rc)];
        if (selSort?.id === 1) {
          newData.sort((a: any, b: any) => {
            if (!a.approveDate || !b.approveDate) {
              return 1;
            }
            const compare = b.approveDate.localeCompare(a.approveDate);
            if (compare === 0) {
              return b.relevance - a.relevance;
            }
            return compare;
          });
          setSearchData(newData);
        } else {
          setSearchData(newData);
        }
        if (isSetLoading) {
          setSearchLoading(false);
        }
        setBarLoading(false);
      })
      .catch(() => {
        if (isSetLoading) {
          setSearchLoading(false);
        }
        setBarLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(withoutInput ? UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR : UISidebarType.SEARCH_SIDEBAR)!.classList.add('!right-0');
    }, 100);
  }, []);

  useEffect(() => {
    if (withoutInput) {
      fetchSearchData(gSearchText, true);
    }
  }, [gSearchText]);

  useEffect(() => {
    fetchSearchData(gSearchText, false);
  }, [selFilter, selSort]);

  const onSearchChange = (searchTxt: string) => {
    const { setSearchText } = bindActionCreators(uiActionCreators, dispatch);
    setSearchText(searchTxt);
    fetchSearchData(searchTxt, true);
  };

  const onCloseSidebar = () => {
    toggleRightSidebar(UISidebarType.CLOSE_SIDEBAR, document, dispatch);
  };

  const searchItemClick = (id: number, type: number) => {
    const searchItem: ISearchItem = { id, type, title: '' };
    addSearchHistory(searchItem);
  };

  const isLastSearched = (id: number, type: number) => (
    searchHistory.length > 0
    && searchHistory[searchHistory.length - 1].id === id
    && searchHistory[searchHistory.length - 1].type === type
  );
  const isViewed = (id: number, type: number) => (
    searchHistory.findIndex(item => (item.id === id && item.type === type)) > -1
  );

  return (
    <>
      <div
        id={withoutInput ? UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR : UISidebarType.SEARCH_SIDEBAR}
        className="sidebar-popup sidebar-toggle flex flex-col fixed z-[39] bottom-0 top-16 md:top-24 transition-all duration-300 ease-in-out bg-white w-full drop-shadow-lg max-h-full md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem] -right-full"
      >
        <div className="pt-6 px-4 sticky bg-white z-10 right-0 left-0 top-0 border-b">
          <div className="flex space-x-2 items-center">
            <button
              type="button"
              className="h-8 w-8 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center first-line:text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseSidebar}
            >
              <em className="i- i-arrow-left" />
            </button>
            <h2 className="h1">Search TomCom</h2>
          </div>
          {!withoutInput && (
            <div className="w-full pt-3">
              <div className="field has-required has-warning">
                <div className="control">
                  <SearchEdit
                    isLeftSearchIcon
                    editId="searchbarInput"
                    editClassName="is-required input placeholder:text-black placeholder:font-light pl-14"
                    isSubmitSearchBtn
                    closeBtnClass="mt-2 mr-2 h-5 w-5 text-black flex items-center justify-center  text-sm font-light cursor-pointer group"
                    searchBtnClass="ml-3 h-10 w-10 text-black flex items-center justify-center  text-sm font-light rounded-md cursor-pointer group hover:bg-themered hover:bg-opacity-5"
                    onSearchEditChangeFn={(searchTxt) => onSearchChange(searchTxt)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center">
            <div className="font-bold pl-0 pb-3 pt-6">Search Results</div>
            <CommonDropdown
              btnContent={() => (
                <>
                  {selFilter?.name || 'Filter'}
                  <GetSVG ID={SVGID.DROPDOWN_ARROW} svgClass="w-2.5 h-5 ms-3 ml-auto" />
                </>
              )}
              btnClass="inline-flex justify-center item-center items-center w-28 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-800 text-white text-xs font-medium hover:bg-indigo-700 focus:outline-none"
              containerClass="relative inline-block text-left ml-auto pt-2 mr-3"
              dropdownClass="origin-top-right absolute right-0 mt-2 w-40 rounded-md border border-gray-300 shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              dropdownData={filterDropdownData}
              showCheckMark={false}
              selectedOption={selFilter || filterDropdownData[0]}
              onSelectChange={(selItem) => setSelFilter(selItem)}
            />
            <CommonDropdown
              btnContent={() => <GetSVG ID={SVGID.SORT_ICON} svgClass="ml-auto" />}
              btnClass="h-10 w-10 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
              containerClass="relative inline-block text-left ml-auto pt-2 ml-0"
              dropdownClass="origin-top-right absolute right-0 mt-2 w-40 rounded-md border border-gray-300 shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              dropdownData={sortDropdownData}
              selectedOption={selSort}
              onSelectChange={(selItem) => setSelSort(selItem)}
            />
          </div>
        </div>
        {barLoading && (
          <Loader
            containerClass="mt-2 justify-center w-full"
            label="Loading..."
          />
        )}
        <div className="overflow-y-auto overflow-auto-themered-only flex-auto" ref={searchBarDivRef}>
          {!barLoading && (
            <>
              {searchData && searchData.map((item: any) => (
                <SearchBarItem
                  key={`${item.id}_${item.title}`}
                  title={item.title}
                  description={item.description}
                  itemCurPath={`${window.location.pathname}?search=${gSearchText}&type=${item.type}&sid=${item.id}${selFilter ? `&filter=${selFilter.id}` : ''}${selSort ? `&sort=${selSort.id}` : ''}`}
                  itemRoutePath={item.routePath}
                  approveDate={item.approveDate}
                  SVGId={item.SVGId}
                  isLastSearched={isLastSearched(item.id, item.type)}
                  isViewed={isViewed(item.id, item.type)}
                  parentRef={searchBarDivRef?.current}
                  onClickItem={() => searchItemClick(item.id, item.type)}
                />
              ))}
              {searchData && searchData.length > 0 && (
                <div className="text-center text-xs text-gray-500 py-4">End of Results</div>
              )}
              {((!searchData || searchData.length === 0)
                && !searchLoading && gSearchText.length > 0) && (
                <div className="text-center text-xs text-gray-500 py-4">No results found</div>
              )}
            </>
          )}
        </div>
      </div>
      <OverlayBlock
        id="search_popup_mask"
        onClickOverlay={onCloseSidebar}
      />
    </>
  );
};

SearchBar.defaultProps = {
  withoutInput: false,
};

export default SearchBar;
