import React from 'react';
import { Link } from 'react-router-dom';
import tomcomImg from '../../assets/img/tomcom-unicorn.svg';
import GetSVG, { SVGID } from '../Common/GetSVG';
import GetMetaHeader, { PAGEID } from '../Common/seoHelper';

const AccessDenied = () => (
  <>
    <GetMetaHeader page={PAGEID.PAGE_ACCESSDENIED} />
    <section className="flex justify-center mx-auto items-center min-h-screen px-5 py-24 relative">
      <div className="absolute top-8 right-0 left-0 text-center">
        <Link to="/home" className="inline-block mx-auto p-3">
          <img src={tomcomImg} alt="Tom-Com" />
        </Link>
      </div>
      <div className="text-center pt-24">
        <h1 className="md:text-[200px] text-[100px] leading-none text-themered mb-6 select-none">
          <GetSVG ID={SVGID.ACCESSDENIED_ICON} svgClass="mx-auto" />
        </h1>
        <h2 className="h2 mb-4">Access Denied</h2>
        <div className="pb-7 max-w-sm">
          {/* <p>
            Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros eget
            libero posuere vulputate.
          </p> */}
        </div>
        <Link to="/home" className="btn btn-red">Back to Home</Link>
      </div>
    </section>
  </>
);

export default AccessDenied;
