import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BasketItem from '../Gallery/BasketItem';
import { forceDownloadFile, getOrderById } from '../../../actions/mspUser';
import OverlayBlock from '../../Common/overlayBlock';
import { RootState } from '../../../store/reducers';
import { isVideoFile } from '../../../helpers/commonHelper';

interface IProps {
  orderId: number;
  onClose: () => void;
}

const OrderItemSidebar = (props: React.PropsWithChildren<IProps>) => {
  const {
    orderId,
    onClose,
  } = props;
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );
  const [order, setOrder] = useState<any>({});

  const onCloseBar = () => {
    const el = document.getElementById('order-item-sidebar');
    if (el) {
      el.classList.remove('!right-0');
    }
    setTimeout(() => {
      onClose();
      if (!sidebarName) {
        document.body.classList.remove('overflow-sidebar');
      }
    }, 200);
  };

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    getOrderById(orderId)
      .then(result => {
        setOrder(result?.data || {});
      })
      .catch(() => {

      });
    setTimeout(() => {
      const el = document.getElementById('order-item-sidebar');
      if (el) {
        el.classList.add('!right-0');
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (sidebarName) {
      onCloseBar();
    }
  }, [sidebarName]);

  const onClickDownload = (id: number) => {
    const curMedia = order?.items?.filter((item: any) => item.id === id) || [];
    if (curMedia.length > 0) {
      forceDownloadFile(curMedia[0]?.media?.original_file_url || '')
        .then(() => {
        })
        .catch((e) => {
          toast.error(`${curMedia[0]?.media?.name} : ${e?.message}`);
        });
    }
  };

  const onClickDownloadAll = () => {
    order?.items?.forEach((item:any) => {
      forceDownloadFile(item?.media?.original_file_url || '')
        .then(() => {
        })
        .catch((e) => {
          toast.error(`${item?.media?.name} : ${e?.message}`);
        });
    });
  };

  return (
    <>
      <div
        id="order-item-sidebar"
        className="sidebar-popup max-h-full flex flex-col fixed z-[39] bottom-0 top-16 transition-all duration-300 ease-in-out bg-themebody-sidebar w-full drop-shadow-lg -right-full md:top-24 md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem]"
      >
        <div className="pt-6 px-4 pb-6 sticky z-10 right-0 left-0 top-0 bg-white">
          {order?.reference && (
            <>
              <div className="flex space-x-2 items-center pb-12">
                <button
                  type="button"
                  className="h-8 w-8 min-w-[2rem] text-black group relative group flex items-center justify-center text-sm font-light rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 focus:bg-themered focus:bg-opacity-5"
                  onClick={onCloseBar}
                >
                  <em className="i- i-arrow-left" />
                </button>
                <h2 className="text-2xl break-words break-all">{`Order ${order?.reference}`}</h2>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-xs pb-1">Total Cost:</div>
                  <span className="text-sm font-bold">{`£${order?.total || '0.00'}`}</span>
                </div>
                <button
                  type="button"
                  className="btn btn-red !pl-10 !pr-10"
                  onClick={onClickDownloadAll}
                >
                  Download All
                </button>
              </div>
            </>
          )}
        </div>
        <div className="px-4 py-6 overflow-y-auto overflow-auto-themered-only flex-auto">
          <div className="pb-6">
            {order?.items?.map((item: any) => (
              <BasketItem
                key={item.id}
                imageName={item?.media?.name || ''}
                price={item.price}
                studentName={item?.student?.name || ''}
                imagePath={item?.media?.thumb_file_url || ''}
                isVideo={isVideoFile(item?.media?.original_file_url) || false}
                actionText="Download"
                onActionClick={() => onClickDownload(item.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <OverlayBlock
        id="order_popup_mask"
        onClickOverlay={onCloseBar}
      />
    </>
  );
};

export default OrderItemSidebar;
