import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import StudentProfile from './StudentProfile';
import IUserDataStates from '../../../interfaces/userDataStates';
import { RootState } from '../../../store/reducers';
import { getCurrentUserDivisions, getCurrentUserStudents } from '../../../actions/mspUser';
import { getSelectedMSP, getSelectedRole } from '../../../actions/AuthUtil';
import { studentData } from '../../Common/StudentSelection';
import MyOrders from './MyOrders';
import OrderItemSidebar from './OrderItemSidebar';
import * as userDataActionCreators from '../../../store/actioncreators/userDataActionCreators';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import CalendarSettings from './CalendarSettings';
import { getMSPUrls } from '../../../helpers/commonHelper';

const MyAccount = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData: IUserDataStates | null = useSelector(
    (state: RootState) => state.userData,
  );
  const [profiles, setProfiles] = useState<studentData[]>([]);
  const [orderId, setOrderId] = useState<number>(0);
  const [role, setRole] = useState('');
  const [msp, setMSP] = useState<any>({});

  useEffect(() => {
    const selMSP = getSelectedMSP();
    setMSP(getMSPUrls(selMSP));

    const selRole = getSelectedRole();
    if (selRole) {
      const roleName = selRole?.name || '';
      setRole(roleName);
      if (roleName.toLowerCase() === 'staff' || roleName.toLowerCase() === 'student 11+') {
        getCurrentUserDivisions()
          .then((result: any) => {
            const divisions: studentData[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                schoolId: item?.id,
                schoolName: item?.name,
                imgPath: item?.image,
              }
            ));
            setProfiles([...divisions]);
          })
          .catch(() => {

          });
      } else if (roleName.toLowerCase() === 'guardian' || roleName.toLowerCase() === 'vip') {
        getCurrentUserStudents()
          .then((result: any) => {
            const divisions: studentData[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                studentName: item?.name,
                schoolId: item?.divisions?.length > 0 && item?.divisions[0]?.id,
                schoolName: item?.divisions?.length > 0 && item?.divisions[0]?.name,
                year: item?.years?.length > 0 && item?.years[0]?.name,
                imgPath: item?.image,
              }
            ));
            setProfiles([...divisions]);
          })
          .catch(() => {

          });

        const query = new URLSearchParams(location.search);
        const order: number = parseInt(query.get('order') || '0', 10);
        if (order > 0) {
          setOrderId(order);
        }
      }
    }
  }, []);

  const onClickRoleSwitcher = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    e.preventDefault();
    const { setUserData } = bindActionCreators(userDataActionCreators, dispatch);
    if (userData) {
      userData.selectedRole = null;
      setUserData(userData);
    }
  };

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_MYACCOUNT} />
      <section className="main-section bg-white">
        <div className="main-sidebar-section mb-4 lg:mb-0 lg:space-x-6 lg:flex flex-wrap lg:flex-nowrap min-h-[calc(100vh_-_4rem)] lg:min-h-[calc(100vh_-_6rem)]">
          <div className="main-content grow -mx-4 lg:mx-0 p-4 lg:p-6 bg-themebody-bg flex flex-col">
            <h1 className="mb-4 flex">My Account</h1>
            <div className="grid sm:grid-cols-2 gap-5 pt-5 pb-14">
              {profiles.map((item) => (
                <StudentProfile
                  key={item.id}
                  name={item?.studentName || ''}
                  schoolName={item?.schoolName || ''}
                  year={item?.year || ''}
                  image={item.imgPath}
                />
              ))}
            </div>
            <div className="border-b border-[#E1E1E1] mb-14">
              <h2 className="pb-4">My Details</h2>
              <div className="justify-between flex items-center md:grid md:grid-cols-2 border-t border-[#E1E1E1] py-3">
                <strong className="text-sm lg:text-lg font-bold text-navy">Full Name</strong>
                <span className="text-xs lg:text-base font-normal text-black">{userData?.name}</span>
              </div>
              <div className="justify-between flex items-center md:grid md:grid-cols-2 border-t border-[#E1E1E1] py-3">
                <strong className="text-sm lg:text-lg font-bold text-navy">Email</strong>
                <span className="text-xs lg:text-base font-normal text-black">{userData?.email}</span>
              </div>
              <div className="justify-between flex items-center md:grid md:grid-cols-2 border-t border-[#E1E1E1] py-3">
                <strong className="text-sm lg:text-lg font-bold text-navy">Profile</strong>
                <div className="text-xs lg:text-base font-normal text-black flex justify-between items-center">
                  {role?.toLowerCase() === 'guardian' && 'Parent'}
                  {role?.toLowerCase() === 'vip' && 'Applicant'}
                  {role?.toLowerCase() !== 'guardian' && role?.toLowerCase() !== 'vip' && role}
                  {userData && userData.roles.length > 1 && (
                    <a
                      href="#"
                      onClick={onClickRoleSwitcher}
                      className="btn btn-red btn-xs md:btn-md md:!text-base ml-4"
                    >
                      Switch Profile
                    </a>
                  )}
                </div>
              </div>
            </div>
            {(role?.toLowerCase() === 'guardian' || role?.toLowerCase() === 'vip') && <CalendarSettings />}
            <div className="text-center mt-auto md:text-left">
              <div className="text-xs md:text-sm text-gray-500 pb-2">
                <p>
                  Your account details are the same as for My School Portal.
                  To view or update your details please go to
                  {' '}
                  {Object.keys(msp).map((key, index) => (
                    <Fragment key={key}>
                      <a
                        href={msp[key]}
                        className="text-themered underline text-sm font-semibold hover:text-black focus:text-black focus-visible:text-black"
                        target="_blank"
                        rel="noreferrer"
                        key={key}
                      >
                        {`MSP for ${key}`}
                      </a>
                      {index === Object.keys(msp).length - 1 ? '' : ' or '}
                    </Fragment>
                  ))}
                  .
                </p>
              </div>
            </div>
          </div>
          {role.toLowerCase() === 'guardian' && <MyOrders onClickOrderItem={(id) => setOrderId(id)} />}
        </div>
      </section>
      {orderId > 0 && (
        <OrderItemSidebar
          orderId={orderId}
          onClose={() => setOrderId(0)}
        />
      )}
    </>
  );
};
export default MyAccount;
