import React, { useEffect, useState } from 'react';
// import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { forceDownloadFile } from '../../../actions/mspUser';
import foundationImage from '../../../assets/img/thomassfoundation-logo.png';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  onClose: () => void;
  imagePath: string;
  imageName: string;
  isVideo: boolean;
}

const DownloadImageModal = (props: React.PropsWithChildren<IProps>) => {
  const {
    onClose,
    imagePath,
    imageName,
    isVideo,
  } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('download-modal')!.classList.add('active');
    }, 100);
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('download-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const onDownloadClick = () => {
    console.log(imageName);
    /* try {
      // @ts-ignore
      const imgName = imagePath?.split('\\').pop().split('/').pop();
      saveAs(imagePath, imgName);
    } catch (e) {
      toast.error(e?.message || 'Error in downloading');
    } */

    setLoading(true);
    forceDownloadFile(imagePath)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        toast.error(e?.message);
      });
  };

  return (
    <div id="download-modal" className="modal">
      <div className="modal-card">
        <div className="modal-card-body !rounded-xl !shadow-md max-w-sm">
          <div className="modal-card-head text-right relative">
            <button
              type="button"
              className="absolute md:relative -right-3 -top-3 md:-right-6 md:-top-4 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
              onClick={onClosePopup}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
            </button>
          </div>
          <div className="text-center sm:pb-6">
            <div className="pb-4">
              <h2 className="h2 mb-2">{`Free ${isVideo ? 'Video' : 'Image'} Download`}</h2>
              <p className="text-xs sm:px-10">Click the button below to make a donation to Thomas’s Foundation</p>
            </div>
            <div className="flex flex-col gap-2">
              <a
                href="https://thomassfoundation.enthuse.com/tomcom"
                target="_blank"
                rel="noreferrer"
                className="py-4 px-8 flex gap-6 items-center justify-around bg-[#328F37] rounded-xl ease-linear transition-all duration-150 hover:bg-opacity-90  focus:bg-opacity-90  focus-within:bg-opacity-90"
              >
                <img className="flex-none" src={foundationImage} alt="Thomas's Foundation" />
                <div className="flex flex-col">
                  <span className="h3 !text-white">Donate</span>
                  <span className="text-4xl text-white font-bold">£2</span>
                </div>
              </a>
              <p className="text-xs sm:px-10">Reg. charity number: 1181145</p>
              <button
                type="button"
                onClick={onDownloadClick}
                className="btn btn-red"
                disabled={loading}
              >
                {loading && (
                  <GetSVG
                    ID={SVGID.LOADER_ICON}
                    svgClass="motion-reduce:hidden animate-spin flex-none -ml-1 mr-3 h-5 w-5 text-white"
                  />
                )}
                {loading ? 'Downloading' : 'Download'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-background" />
    </div>
  );
};

export default DownloadImageModal;
