import React from 'react';
import moment from 'moment';

interface IProps {
  newsDate: string;
  schoolName: string;
}

const WeeklyNewsTitle = (props: React.PropsWithChildren<IProps>) => {
  const {
    newsDate,
    schoolName,
  } = props;

  const parsedDate = moment(newsDate, 'YYYY-MM-DD');
  const day = parsedDate.format('DD');
  const month = parsedDate.format('MMMM');
  return (
    <div className="flex flex-wrap items-center gap-4 py-4 px-5 -mx-4 md:mx-0 lg:gap-7 lg:py-5 lg:px-12 bg-white rounded-b-2xl shadow-lg">
      <div className="flex items-center flex-nowrap">
        <div className="text-7xl font-bold md:leading-normal">{day}</div>
        <span className="text-7xl font-bold md:leading-normal text-themered pl-2.5">/</span>
      </div>
      <div className="text-left">
        <h1 className="bulletin-title">
          <div className="h2 !text-themered">{month}</div>
          <strong className="text-navy">{schoolName}</strong>
        </h1>
      </div>
    </div>
  );
};

export default WeeklyNewsTitle;
