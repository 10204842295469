import React, { useEffect, useState } from 'react';
import FeatureBlock from '../../Common/FeatureBlock';
import paperPlaneImage from '../../../assets/img/paper-plane.svg';
import lightBlubImage from '../../../assets/img/light-bulb.svg';
import LatestNewsSection from './LatestNewsSection/LatestNewsSection';
import CalendarEventSection from './CalendarEventSection/CalendarEventSection';
import HighlightSection from './HighlightSection/HighlightSection';
import ViewCalendarEventBar from '../../Layout/ViewCalendarEventBar/ViewCalendarEventBar';
import { getSelectedMSP } from '../../../actions/AuthUtil';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import MagazineSection from './MagazineSection/MagazineSection';
import { getMSPUrls } from '../../../helpers/commonHelper';

const Home = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window?.innerWidth <= 766);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [msp, setMSP] = useState<any>({});

  const handleResize = () => {
    const curView:boolean = window.innerWidth <= 766;
    setIsMobile(curView);
  };

  useEffect(() => {
    const selMSP = getSelectedMSP();
    setMSP(getMSPUrls(selMSP));

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onViewEvent = (event: any, selDate: Date) => {
    setSelectedEvent(event);
    setSelectedDate(selDate);
  };

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_DASHBOARD} />
      <section className="main-section bg-white">
        <div className="main-sidebar-section mb-4 lg:mb-0 lg:space-x-6 lg:flex flex-wrap lg:flex-nowrap min-h-[calc(100vh_-_4rem)] lg:min-h-[calc(100vh_-_6rem)]">
          <div className="main-content grow -mx-4 lg:mx-0 p-4 lg:p-6 bg-themebody-bg">
            <h1 className="mb-4">Welcome</h1>
            <FeatureBlock
              title="Need to Know"
              description="Essential messages from the School that you must read."
              routePath="/need-to-know"
              image={paperPlaneImage}
              imgClass="w-16 lg:w-24"
            />
            {isMobile && (
              <>
                <HighlightSection />
                <LatestNewsSection />
              </>
            )}
            <CalendarEventSection
              onViewEvent={(event: any, selDate: Date) => onViewEvent(event, selDate)}
            />
            {!isMobile && <LatestNewsSection />}
            <FeatureBlock
              title="Gallery"
              description="Personal photo and video galleries of your child."
              routePath="/gallery"
              image={lightBlubImage}
              imgClass="w-16 lg:w-24"
            />
            <MagazineSection />
            <div className="-mx-4 mb-6 lg:mb-0">
              <div className="snap-x snap-always scroll-px-4 overflow-x-auto pt-3 px-4 mb-3 pb-3 flex flex-row gap-3 lg:gap-5 lg:pb-3 lg:grid lg:grid-cols-2 xl:grid-cols-2">
                {Object.keys(msp).map((key) => (
                  <div className="snap-start shrink-0 w-4/5 md:w-full" key={key}>
                    <a
                      href={msp[key]}
                      className="btn btn-red w-full flex flex-col text-center justify-between gap-10 lg:gap-3 lg:flex-initial lg:flex-row rounded-2xl shadow-lg h-full p-4 group"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`MSP for ${key}`}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!isMobile && <HighlightSection />}
        </div>
      </section>
      {selectedEvent && (
        <ViewCalendarEventBar
          event={selectedEvent}
          eventDate={selectedDate}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </>
  );
};

export default Home;
