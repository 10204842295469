import React from 'react';
import defaultStdImg from '../../../assets/img/profileplaceholder.svg';

interface IProps {
  name: string;
  schoolName: string;
  year: string;
  image: string;
}

const StudentProfile = (props: React.PropsWithChildren<IProps>) => {
  const {
    name,
    schoolName,
    year,
    image,
  } = props;

  const getBgStyle = (imgUrl: string) => {
    const bgStyle = {
      backgroundImage: `url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
    return bgStyle;
  };

  return (
    <div className="flex items-center group">
      <div
        style={getBgStyle(image || defaultStdImg)}
        className="w-14 h-14 lg:w-24 lg:h-24 overflow-hidden block rounded-xl lg:rounded-2xl flex-none transition-all duration-500 ease-in-out scale-100 group-hover:scale-105 group-hover:opacity-100 group-focus:scale-105 group-focus:opacity-100"
      />
      <div className="lg:pl-5 pl-3">
        {name && (
          <div className="flex items-center mt-auto flex-wrap lg:text-base text-xs font-normal">
            <span className="mr-2">{schoolName}</span>
            <div className="w-1 h-1 bg-themered rounded-full flex-none inline-block mr-2" />
            <div>{year}</div>
          </div>
        )}
        <div className="lg:text-2xl text-base font-bold group-hover:text-themered group-focus:text-themered group-focus-visible:text-themered">
          {name || schoolName}
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
