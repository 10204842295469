import React, { useEffect, useState } from 'react';
import { getCurrentUserDivisions, getCurrentUserStudents } from '../../actions/mspUser';
import { getSelectedRole } from '../../actions/AuthUtil';
import defaultStdImg from '../../assets/img/profileplaceholder.svg';

export interface studentData {
  id: number;
  imgPath: string;
  studentId?: number;
  studentName?: string;
  schoolId?: number;
  schoolName?: string;
  year?: string;
}

interface IProps {
  onItemChange?: (item: studentData) => void;
  isNeedToNow?: boolean;
}

const StudentSelection = (props: React.PropsWithChildren<IProps>) => {
  const {
    onItemChange,
    isNeedToNow,
  } = props;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const [students, setStudents] = useState<studentData[]>([]);

  const setResponseData = (divisions: studentData[]) => {
    setStudents([...divisions]);
    setSelectedId(divisions[0].id);
    if (typeof onItemChange !== 'undefined') {
      onItemChange(divisions[0]);
    }
  };

  useEffect(() => {
    const selRole = getSelectedRole();
    if (selRole) {
      const roleName = selRole?.name || '';
      if (roleName.toLowerCase() === 'staff' || roleName.toLowerCase() === 'student 11+') {
        getCurrentUserDivisions()
          .then((result: any) => {
            const divisions: studentData[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                schoolId: item?.id,
                schoolName: item?.name,
                imgPath: item?.image,
              }
            ));
            setResponseData(divisions);
          })
          .catch(() => {

          });
      } else if (roleName.toLowerCase() === 'vip' || roleName.toLowerCase() === 'guardian') {
        getCurrentUserStudents()
          .then((result: any) => {
            const divisions: studentData[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                studentId: item?.id,
                studentName: item?.name,
                schoolId: item?.divisions?.length > 0 && item?.divisions[0]?.id,
                schoolName: item?.divisions?.length > 0 && item?.divisions[0]?.name,
                year: item?.years?.length > 0 && item?.years[0]?.name,
                imgPath: item?.image,
              }
            ));
            setResponseData(divisions);
          })
          .catch(() => {

          });
      }
    }
  }, []);

  const onSelectStudent = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>, id: number) => {
    event.preventDefault();
    const selStudent = students.filter((item) => item.id === id);
    if (selStudent && selStudent.length > 0) {
      setSelectedId(selStudent[0].id);
      if (typeof onItemChange !== 'undefined') {
        onItemChange(selStudent[0]);
      }
    }
  };

  let curStudentArray = students;
  let selectedStudent: studentData | null = null;
  if (selectedId) {
    const index = students.findIndex((item) => item.id === selectedId);
    selectedStudent = students[index];
    curStudentArray = students.slice(index).concat(students.slice(0, index));
  }

  const getBgStyle = (imgUrl: string) => {
    const bgStyle = {
      backgroundImage: `url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#F4F4F4',
    };
    return bgStyle;
  };

  return (
    <div className="flex flex-nowrap items-start -ml-4 -mr-4 lg:-ml-6 lg:mr-0 pb-8">
      <div className="bg-white shadow-lg rounded-br-2xl flex-none p-4 pt-3 lg:p-5 lg:!pt-3 lg:rounded-b-3xl ">
        <div className="rounded-2xl block w-24 h-24 lg:w-32 lg:h-32" style={selectedStudent ? getBgStyle(selectedStudent?.imgPath || defaultStdImg) : {}} />
      </div>
      <div className="grow">
        <div className="bg-white lg:rounded-br-xl">
          <div className="relative flex gap-3 scroll-smooth snap-x overflow-x-auto overflow-y-hidden justify-items-start p-3">
            {curStudentArray.filter((item) => item.id !== selectedStudent?.id).map((item) => (
              <div key={item.id} className="snap-start shrink-0">
                {/* eslint-disable-next-line */}
                <a
                  href="#"
                  onClick={(e) => onSelectStudent(e, item.id)}
                  className="w-16 h-16 rounded-lg block transition-all duration-500 ease-in-out scale-100 opacity-75 hover:scale-105 hover:opacity-100 focus:scale-105 focus:opacity-100"
                  title={item.studentName || item.schoolName}
                  style={getBgStyle(item.imgPath || defaultStdImg)}
                />
              </div>
            ))}
          </div>
        </div>
        {selectedStudent && (
          <div className=" pt-3 pl-3 lg:pl-6 lg:pt-5">
            {selectedStudent.studentName && (
              <div className="flex items-center gap-2 mt-auto flex-wrap lg:text-base text-xs font-normal">
                <span>{selectedStudent.schoolName}</span>
                <div className="w-1 h-1 bg-themered rounded-full flex-none inline-block" />
                <div>{selectedStudent.year}</div>
              </div>
            )}
            {(!selectedStudent.studentName && isNeedToNow) && (
              <div className="flex items-center gap-2 mt-auto flex-wrap lg:text-base text-xs font-normal">
                <span>Need to Know</span>
              </div>
            )}
            <h1 className="font-bold">{selectedStudent.studentName || selectedStudent.schoolName}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

StudentSelection.defaultProps = {
  onItemChange: () => {},
  isNeedToNow: false,
};

export default StudentSelection;
