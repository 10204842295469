import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { createRoot } from 'react-dom/client';
import Calendar from 'react-calendar';
import CalendarEventListBlock from './CalendarEventListBox';
import CalendarFilterPopup from './CalendarFilterPopup';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import CalendarFilterTagBox from './CalendarFilterTagBox';
import {
  getCalendarEvents,
  getCalendarEventById,
  getCurrentUserDivisionYear,
  getCalendarEventCategories,
} from '../../../actions/mspUser';
import ViewCalendarEventBar from '../../Layout/ViewCalendarEventBar/ViewCalendarEventBar';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import { formatDate, getDateFromEvent, getNumber } from '../../../helpers/commonHelper';

let root: any = null;
const CalendarView = () => {
  // const curDate = new Date();
  const location = useLocation();
  const [curDateValue, setCurDateValue] = useState<Date>(new Date());
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [activeYears, setActiveYears] = useState<string[]>([]);
  const [activeSchools, setActiveSchools] = useState<string[]>([]);
  const [activeCategories, setActiveCategories] = useState<string[]>([]);
  const [eventList, setEventList] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [allDivsAndYears, setAllDivsAndYears] = useState<any[]>([]);
  const [allEventCategories, setAllEventCategories] = useState<any[]>([]);

  const getEventForDay = (date: Date) => {
    if (!eventList) {
      return [];
    }
    const curDay: number = getNumber(moment(date).format('DD-MM-yyyy'));
    if (!eventList[curDay]) {
      return [];
    }
    // const curDate = moment(date); // .format('DD-MM-yyyy');
    return eventList[curDay].filter((item: any) => {
      if (activeYears.length > 0 || activeSchools.length > 0 || activeCategories.length > 0) {
        let sRet = true;
        if (activeSchools.length > 0) {
          sRet = activeSchools
            .findIndex(school => school.toLowerCase() === (item.division?.name?.toLowerCase() || '')) > -1;
        }

        let yRet = true;
        if (activeYears.length > 0) {
          yRet = item.years?.findIndex(
            (year: any) => activeYears.findIndex(
              ayear => ayear.toLowerCase() === year.name.toLowerCase(),
            ) > -1,
          ) > -1 || false;
        }

        let cRet = true;
        if (activeCategories.length > 0) {
          cRet = activeCategories
            .findIndex(cat => cat.toLowerCase() === (item.event_type?.name?.toLowerCase() || '')) > -1;
        }
        return sRet && yRet && cRet;
      }
      return true;
    });
  };

  const onChangeFilter = (
    selectedYears: string[],
    selectedSchools: string[],
    selectedCategories: string[],
  ) => {
    setActiveYears(selectedYears);
    setActiveSchools(selectedSchools);
    setActiveCategories(selectedCategories);
    let isCreate = false;
    if (!document.getElementById('filter-tag-node')) {
      const filterTagNode = document.createElement('DIV');
      filterTagNode.id = 'filter-tag-node';
      const div = document.querySelector('.react-calendar__viewContainer');
      div?.parentNode?.insertBefore(filterTagNode, div);
      isCreate = true;
    }
    setTimeout(() => {
      if (isCreate) {
        // @ts-ignore
        root = createRoot(document.getElementById('filter-tag-node'));
      }
      root?.render(
        <CalendarFilterTagBox
          activeYears={selectedYears}
          activeSchools={selectedSchools}
          activeCategories={selectedCategories}
          onChangeTagBox={
            (
              selectYears,
              selectSchools,
              selectCategories,
            ) => onChangeFilter(selectYears, selectSchools, selectCategories)
          }
        />,
      );
    }, 100);
  };

  const setTileContent = (
    { activeStartDate, date, view }: {activeStartDate: Date, date: Date, view: string},
  ) => {
    if (view === 'month' && date.getMonth() === activeStartDate.getMonth()) {
      const events = getEventForDay(date);
      return (
        <>
          <div className="is-day-xs">{date.toLocaleString('default', { weekday: 'short' })}</div>
          {events.length > 0 && <span className="is-event">.</span>}
          {events.slice(0, 2).map((item: any) => (
            <div className="is-day-event" key={item.id}>
              <span className="day-event-title text-xs font-light truncate">{item.title}</span>
              <span className="day-event-border bg-c4" style={{ backgroundColor: item.event_type?.color }} />
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const setTileClassName = (
    { activeStartDate, date, view }: {activeStartDate: Date, date: Date, view: string},
  ) => {
    if (view === 'month') {
      if (date.getMonth() !== activeStartDate.getMonth()) {
        return 'no-display';
      }

      const inDay: number = date.getDate();
      if (inDay > 3 && inDay < 15) {
        return ''; // 'is-break';
      }
    }
    return null;
  };

  const setTileDisabled = (
    { activeStartDate, date, view }: {activeStartDate: Date, date: Date, view: string},
  ) => {
    if (view === 'month') {
      return (date.getMonth() !== activeStartDate.getMonth());
    }
    return false;
  };

  const setNavigationLabel = ({ date, view }: {date: Date, view: string}) => {
    if (view === 'month') {
      return date.toLocaleString('default', { month: 'short', year: 'numeric' });
    }
    return null;
  };

  const fetchMonthEvents = (month: string) => {
    setEventList(null);
    getCalendarEvents(month)
      .then((result: any) => {
        const monthStrArray = month.split('-');
        let lastDate = new Date();
        if (monthStrArray.length >= 2) {
          lastDate = new Date(getNumber(monthStrArray[1]), getNumber(monthStrArray[0]), 0);
        }
        const lastDay = lastDate.getDate();
        const cMon = lastDate.getMonth() + 1;
        const cYear = lastDate.getFullYear();
        const monthEvents: any = {};
        for (let i = 1; i <= lastDay; i += 1) {
          monthEvents[i] = result?.data.filter((item: any) => {
            const sDay: number = getNumber(item.start_date);
            const eDay: number = getNumber(item.end_date);
            if ((eDay > -1 && sDay <= i && eDay >= i) || (eDay === -1 && sDay === i)) {
              const sArr = item.start_date.split('-');
              if (sArr.length >= 3 && getNumber(sArr[1]) === cMon && getNumber(sArr[2]) === cYear) {
                if (eDay > -1) {
                  if (moment(item.start_date, 'DD-MM-YYYY').valueOf() <= moment(item.end_date, 'DD-MM-YYYY').valueOf()) {
                    return true;
                  }
                } else {
                  return true;
                }
              }
            }
            return false;
          });
        }

        for (let i = 1; i <= lastDay; i += 1) {
          monthEvents[i].sort((a: any, b: any) => {
            if (a.is_all_day && b.is_all_day) {
              return a.title.localeCompare(b.title);
            }

            if (a.is_all_day) {
              return -1;
            }

            if (!a.is_all_day && !b.is_all_day) {
              const aDate = formatDate(getDateFromEvent(a, true), 'DD-MM-YYYY HH:mm:ss', 'HH:mm:ss');
              const bDate = formatDate(getDateFromEvent(b, true), 'DD-MM-YYYY HH:mm:ss', 'HH:mm:ss');
              return aDate.localeCompare(bDate);
            }
            return 1;
          });
        }
        setEventList(monthEvents);
      })
      .catch(() => {
      });
  };

  const onViewChange = (
    {
      action,
      activeStartDate,
      view,
    } : {
      action: string,
      activeStartDate: Date,
      view: string
    },
  ) => {
    if (view === 'month' && (action === 'next' || action === 'prev')) {
      const month = moment(activeStartDate).format('MM-yyyy');
      fetchMonthEvents(month);
    }
  };

  useEffect(() => {
    const curDate = new Date();
    const month = moment(new Date(curDate.getFullYear(), curDate.getMonth(), 1)).format('MM-yyyy');
    fetchMonthEvents(month);

    getCurrentUserDivisionYear()
      .then((result: any) => {
        setAllDivsAndYears(result?.data || []);
      })
      .catch(() => {
      });

    getCalendarEventCategories()
      .then((result: any) => {
        setAllEventCategories(result?.data || []);
      })
      .catch(() => {
      });

    const query = new URLSearchParams(location.search);
    const eventId: number = parseInt(query.get('id') || '0', 10);
    if (eventId <= 0) {
      return;
    }

    // history.replace({ state: {} });
    getCalendarEventById(eventId)
      .then((result: any) => {
        setSelectedEvent(result?.data);
      })
      .catch(() => {
      });
  }, []);

  const onChangeDate = (value: Date) => {
    setCurDateValue(value);
  };

  const formatDay = (locale: string, date: Date) => date.toLocaleString('default', { day: '2-digit' });

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_CALENDAR} />
      <section className="main-section bg-white">
        <div className="main-sidebar-section lg:space-x-6 flex flex-wrap flex-col lg:flex-row lg:flex-nowrap min-h-[calc(100vh_-_4rem)] lg:min-h-[calc(100vh_-_6rem)]">
          <div className="main-content grow-0 lg:grow -mx-4 lg:mx-0 p-4 lg:p-6 bg-white md:bg-themebody-bg border-b border-themered md:border-none">
            <h1 className="mb-4 hidden md:block">Calendar</h1>
            <div className="full-calendar text-center mb-2 md:mb-6 relative">
              <div className="absolute right-0">
                <button
                  type="button"
                  className="flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 --jbi-modal"
                  onClick={() => setIsFilterPopupOpen(true)}
                >
                  <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light rounded-md cursor-pointer">
                    <GetSVG ID={SVGID.FILTER_ICON} />
                  </div>
                  <strong className="p-1 text-lg hover:text-themered group-hover:text-themered hidden md:block">Filter</strong>
                </button>
              </div>
              <Calendar
                onChange={onChangeDate}
                value={curDateValue}
                showNavigation
                tileContent={setTileContent}
                tileClassName={setTileClassName}
                tileDisabled={setTileDisabled}
                formatDay={formatDay}
                prev2Label={null}
                prevLabel={<em className="i- i-arrow-left h-4 w-4" />}
                next2Label={null}
                nextLabel={<em className="i- i-arrow-right h-4 w-4" />}
                maxDetail="month"
                minDetail="month"
                navigationLabel={setNavigationLabel}
                onActiveStartDateChange={onViewChange}
              />
            </div>
          </div>
          <div className="sidebar-content bg-themebody-bg -mx-4 lg:mx-0 p-4 lg:p-6 flex-none w-auto lg:w-[22rem] max-h-full grow lg:grow-0">
            <CalendarEventListBlock
              selectedDate={curDateValue}
              events={getEventForDay(curDateValue)}
              onViewEvent={(event: any) => setSelectedEvent(event)}
            />
          </div>
        </div>
      </section>
      {isFilterPopupOpen && (
        <CalendarFilterPopup
          allDivsAndYears={allDivsAndYears}
          allEventCategories={allEventCategories}
          activeYears={activeYears}
          activeSchools={activeSchools}
          activeCategories={activeCategories}
          onChangeFilter={
            (
              selectedYears,
              selectedSchools,
              selectedCategories,
            ) => onChangeFilter(selectedYears, selectedSchools, selectedCategories)
          }
          onClose={() => setIsFilterPopupOpen(false)}
        />
      )}
      {selectedEvent && (
        <ViewCalendarEventBar
          eventDate={curDateValue}
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </>
  );
};

export default CalendarView;
