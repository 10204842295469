import React from 'react';
import { bindActionCreators } from 'redux';
import * as uiActionCreators from '../store/actioncreators/uiActionCreators';
import { UISidebarType } from '../store/actiontypes/uiActionTypes';
import store from '../store/store';

const updateBtnAsPerSidebar = (
  childBtnId: string,
  closeBtnId: string,
  parentBtnId: string,
  sidebarToCheck: string,
  curSidebar: string,
  oldSidebar: string,
) => {
  const childBtn = document.getElementById(childBtnId);
  const parentBtn = document.getElementById(parentBtnId);
  const closeBtn = document.getElementById(closeBtnId);
  if (curSidebar === sidebarToCheck && oldSidebar === sidebarToCheck) {
    childBtn!.classList.toggle('hidden');
    parentBtn!.classList.toggle('bg-themered');
    closeBtn!.classList.toggle('hidden');
  } else if (curSidebar === sidebarToCheck) {
    childBtn!.classList.add('hidden');
    parentBtn!.classList.add('bg-themered');
    closeBtn!.classList.remove('hidden');
  } else if (oldSidebar === sidebarToCheck) {
    childBtn!.classList.remove('hidden');
    parentBtn!.classList.remove('bg-themered');
    closeBtn!.classList.add('hidden');
  }
};

export const setRightSidebar = (sidebarName: UISidebarType, document: Document, dispatch: any) => {
  const { setRightBar } = bindActionCreators(uiActionCreators, dispatch);
  const oldSidebarName: string = store.getState()?.ui?.sidebarName || '';

  if (oldSidebarName) {
    const element = document.getElementById(oldSidebarName);
    if (element) {
      const isContain = element.classList.contains('!right-0');
      if (isContain) {
        element.classList.remove('!right-0');
      }
    }
  }

  if (sidebarName === UISidebarType.CLOSE_SIDEBAR) {
    document.body.classList.remove('overflow-sidebar');
  } else {
    document.body.classList.add('overflow-sidebar');
  }

  updateBtnAsPerSidebar('notification_btn', 'not_close_btn', 'not_parent_btn', UISidebarType.NOTIFICATION_SIDEBAR, sidebarName, oldSidebarName);
  updateBtnAsPerSidebar('favourite_btn', 'fav_close_btn', 'fav_parent_btn', UISidebarType.FAVOURITE_SIDEBAR, sidebarName, oldSidebarName);

  setTimeout(() => {
    setRightBar(sidebarName);
  }, 100);
};

export const toggleRightSidebar = (
  sidebarName: UISidebarType,
  document: Document,
  dispatch: any,
) => {
  const oldSidebarName: string = store.getState()?.ui?.sidebarName || '';

  if (oldSidebarName !== UISidebarType.CLOSE_SIDEBAR && oldSidebarName === sidebarName) {
    setRightSidebar(UISidebarType.CLOSE_SIDEBAR, document, dispatch);
  } else {
    setRightSidebar(sidebarName, document, dispatch);
  }
};

export const toggleSidebar = (document: Document) => {
  document.querySelector('.mainmenu')!.classList.toggle('expanded');
  document.querySelector('.mainmenu-mask')!.classList.toggle('hidden');
  document.querySelector('.m-svg-open')!.classList.toggle('hidden');
  document.querySelector('.m-svg-close')!.classList.toggle('hidden');
};

export const animateOnMouseMove = (
  e: React.MouseEvent<HTMLElement | MouseEvent>, ref: HTMLElement | null,
) => {
  if (ref) {
    const bounds = ref.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;

    ref.style.setProperty('--x', `${x}px`);
    ref.style.setProperty('--y', `${y}px`);
  }
};
