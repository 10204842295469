import React from 'react';
import { Link } from 'react-router-dom';
import GetMetaHeader, { PAGEID } from '../Common/seoHelper';

interface IProps {
  showBackHomeBtn?: boolean;
}

const NotFound = (props: React.PropsWithChildren<IProps>) => {
  const {
    showBackHomeBtn,
  } = props;
  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_NOTFOUND} />
      <section className="main-section bg-white">
        <div className="main-sidebar-section space-y-4 lg:space-x-6 flex flex-nowrap min-h-[calc(100vh_-_4rem)] lg:min-h-[calc(100vh_-_6rem)]">
          <section className="main-content grow -mx-4 lg:mx-0 p-4 lg:p-6 bg-themebody-bg">
            <div className="text-center pt-24">
              <h1 className="md:text-[175px] text-[100px] leading-none text-themered mb-6 select-none">404</h1>
              <h2 className="h2 mb-4">Oh no! Page not found.</h2>
              <div className="pb-7 max-w-sm mx-auto" />
              {showBackHomeBtn && (
                <Link
                  to="/home"
                  className="btn btn-red"
                >
                  Back to Home
                </Link>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

NotFound.defaultProps = {
  showBackHomeBtn: true,
};

export default NotFound;
