import React from 'react';
import { useDispatch } from 'react-redux';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';

const FavouriteButton = () => {
  const dispatch = useDispatch();

  return (
    <button
      id="fav_parent_btn"
      type="button"
      className="h-10 w-10 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
      onClick={() => toggleRightSidebar(UISidebarType.FAVOURITE_SIDEBAR, document, dispatch)}
    >
      <span id="favourite_btn">
        <GetSVG ID={SVGID.FAVOURITE_ICON} svgClass="fill-themered h-8 w-8" />
      </span>

      <span id="fav_close_btn" className="hidden">
        <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-5 w-5 fill-white hover:fill-themered group-hover:fill-themered" />
      </span>
    </button>
  );
};

export default FavouriteButton;
