import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import OverlayBlock from '../../Common/overlayBlock';
import { RootState } from '../../../store/reducers';
import { getBasket } from '../../../actions/AuthUtil';
import withLightBox from '../../HOC/withLightBox';

interface IProps {
  imageId: number;
  originalPath: string;
  imagePath: string;
  imageName: string;
  studentName: string;
  categoryId: number;
  isVideo: boolean;
  onClose: () => void;
  onReportImage: () => void;
  onDownloadImage: () => void;
  onPrintImage: () => void;
}

const GallerySidebar = (props: React.PropsWithChildren<IProps>) => {
  const {
    imageId,
    originalPath,
    imagePath,
    imageName,
    studentName,
    categoryId,
    isVideo,
    onClose,
    onReportImage,
    onDownloadImage,
    onPrintImage,
  } = props;

  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );
  const [alreadyInBasket, setAlreadyInBasket] = useState(false);

  const onCloseBar = () => {
    const el = document.getElementById('gallery-sidebar');
    if (el) {
      el.classList.remove('!right-0');
      // el.classList.remove('!z-30');
    }
    setTimeout(() => {
      onClose();
      if (!sidebarName) {
        document.body.classList.remove('overflow-sidebar');
      }
    }, 200);
  };

  const onReport = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    event.preventDefault();
    onReportImage();
  };

  useEffect(() => {
    if (sidebarName) {
      onCloseBar();
    }
  }, [sidebarName]);

  useEffect(() => {
    if (imageId) {
      const basketObj = getBasket();
      const filterItem = basketObj.filter((item: any) => item.id === imageId);
      setAlreadyInBasket(filterItem && filterItem.length > 0);
    }
  }, [imageId]);

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    setTimeout(() => {
      const el = document.getElementById('gallery-sidebar');
      if (el) {
        el.classList.add('!right-0');
        // el.classList.add('!z-30');
      }
    }, 100);
  }, []);

  return (
    <>
      <div
        id="gallery-sidebar"
        className="sidebar-popup flex flex-col overflow-hidden fixed z-[39] bottom-0 top-16 md:top-24 transition-all duration-300 ease-in-out bg-themebody-sidebar w-full drop-shadow-lg max-h-full md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem] overflow-y-auto -right-full"
      >
        <div className="card-head border-b-2 border-gray-300 p-5 relative bg-white">
          <div className="top-0 right-0 sticky z-10 text-right flex justify-end">
            <button
              type="button"
              className="absolute -top-2 -right-2 h-8 w-8 text-black bg-white bg-opacity-70 group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseBar}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </button>
          </div>
          <div className="block text-center">
            <a
              href={originalPath}
              className="inline-block relative mx-auto transition-all duration-500 ease-in-out hover:scale-105 focus:scale-105 overflow-hidden glightbox"
              data-gallery="gallery-download"
            >
              <img src={imagePath} className="overflow-hidden rounded-xl mx-auto max-w-full max-h-72 object-cover" alt="student" />
              {isVideo && (
                <span className="post-play absolute inset-0 flex items-center justify-center transition ease-in-out delay-150 hover:scale-110  duration-300">
                  <GetSVG ID={SVGID.VIDEOPLAY_ICON} />
                </span>
              )}
            </a>
          </div>
        </div>
        <div className="card-body p-5 flex-auto">
          <div className="flex flex-wrap gap-3 items-center justify-between pb-7">
            <div className="text-base font-bold">{studentName}</div>
            <div className="text-xs break-words">{imageName}</div>
          </div>
          <div className="text-sm text-center pb-11 font-bold max-w-[22rem] mx-auto">
            <p>
              {`PLEASE READ: These ${isVideo ? 'videos' : 'images'} are for personal use only
              and cannot be shared on any social media platform.
              Please`}
              {' '}
              <a
                href="https://cdn.thomas-s.co.uk/wp-content/uploads/2022/02/Personal-Devices-and-Photography-Policy-ALL-2022-Feb.pdf"
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-4 text-themered text-sm font-semibold hover:text-black"
              >
                refer
              </a>
              {' '}
              to our Personal Devices and Photography
              Policy on our website.
            </p>
          </div>
          <div className="text-center grid gap-4">
            <button type="button" onClick={onDownloadImage} className="btn btn-red">
              {alreadyInBasket ? 'View in Basket' : `Download ${isVideo ? 'Video' : 'Image'}`}
            </button>
            {(categoryId === 2 && !isVideo) && (
              <button
                type="button"
                onClick={onPrintImage}
                className="btn-link"
              >
                Print Image
              </button>
            )}
          </div>
        </div>
        {!isVideo && (
          <div className="mt-auto">
            <a href="#" onClick={onReport} className="card-bottom flex item-center gap-3 justify-center p-5  --jbi-modal">
              <em className="i- i-error i-24px flex-none" />
              <span className="text-sm text-themered underline font-semibold">
                {`Report this ${isVideo ? 'video' : 'image: This photo is not my child'}`}
              </span>
            </a>
          </div>
        )}
      </div>
      <OverlayBlock
        id="gallery_popup_mask"
        onClickOverlay={onCloseBar}
      />
    </>
  );
};

export default withLightBox(GallerySidebar);
