import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { formatDate } from '../../../helpers/commonHelper';

interface IProps {
  title: string;
  description: string;
  itemRoutePath: string;
  itemCurPath: string;
  approveDate: string;
  SVGId: string;
  isLastSearched: boolean;
  isViewed: boolean;
  parentRef: any;
  onClickItem: () => void;
}

const SearchBarItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    title,
    description,
    itemRoutePath,
    itemCurPath,
    approveDate,
    SVGId,
    isLastSearched,
    isViewed,
    onClickItem,
    parentRef,
  } = props;

  const history = useHistory();
  const query = new URLSearchParams(window.location.search);

  const getQueryPath = () => {
    let spQuery = '';
    if (parentRef) {
      spQuery = `&sp=${Math.round(parentRef.scrollTop)}`;
    }
    return `${itemCurPath}${spQuery}`;
  };

  useEffect(() => {
    if (isLastSearched) {
      const position = query?.get('sp') || 0;
      setTimeout(() => {
        parentRef?.scrollTo({ top: position });
      }, 100);
    }
  }, []);

  const onItemClick = () => {
    onClickItem();
    const routeStrs = itemRoutePath.split('?');
    history.replace(getQueryPath());
    history.push({
      pathname: (routeStrs.length > 0 && routeStrs[0]) || '',
      search: (routeStrs.length > 1 && routeStrs[1]) || '',
      state: { itemCurPath: getQueryPath() },
    });
  };

  return (
    <div className="border-b">
      <div
        className="w-full flex gap-3.5 items-center px-5 py-4 outline-0 ring-inset ring-2 ring-transparent hover:bg-themered hover:bg-opacity-5 focus-visible:ring-black cursor-pointer"
        onClick={onItemClick}
      >
        <div>
          <GetSVG ID={SVGId} />
        </div>
        <div className="grow">
          <div className="flex flex-between content-center">
            <h3 className="text-[#747474] font-light text-xs">{title}</h3>
            <span className="text-themered text-xs font-medium ml-auto">
              {formatDate(approveDate, 'yyyy-MM-DD', 'DD MMM yyyy')}
            </span>
          </div>
          <p className={`text-xs font-bold ${isViewed ? 'text-gray-400' : ''}`}>{description}</p>
        </div>
        <div className="ml-auto">
          <GetSVG ID={SVGID.RIGHTARROW_ICON} />
        </div>
      </div>
    </div>
  );
};

export default SearchBarItem;
