import React, { useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { animateOnMouseMove } from '../../helpers/uiHelper';

interface IProps {
  newsDate: string;
  newsTime?: string;
  schoolName: string;
  routePath: string;
  isRead?: boolean;
}

const NewsCard = (props: React.PropsWithChildren<IProps>) => {
  const {
    newsDate,
    newsTime,
    isRead,
    schoolName,
    routePath,
  } = props;
  const containerRef = useRef(null);

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    e.preventDefault();
    animateOnMouseMove(e, containerRef?.current);
  };

  const cutOffDate = moment.utc().subtract(7, 'days');
  const parsedDate = moment(`${newsDate} ${newsTime}`, 'YYYY-MM-DD hh:mm:ss');
  const day = parsedDate.format('DD');
  const month = parsedDate.format('MMM');
  const isNew = parsedDate > cutOffDate;
  return (
    <Link
      ref={containerRef}
      to={routePath}
      className="is-hover flex flex-col justify-between gap-10 lg:gap-3 lg:flex-initial lg:flex-row bg-white rounded-2xl shadow-lg h-full p-4 transition-all group"
      onMouseEnter={onMouseMoveEvent}
      onMouseLeave={onMouseMoveEvent}
    >
      {isNew && !isRead && (
        <span
          className="--is-not-hover card-news-status bg-themered text-white text-base leading-none font-bold px-3 py-1.5 rounded-bl-xl rounded-tr-lg absolute bottom-0 left-0 z-10"
        >
          New
        </span>
      )}
      <div className="flex items-center">
        <div className={`text-6xl font-bold transition duration-500 ease-in-out --is-text-hover ${isRead ? 'text-[#5F5F5F]' : ''}`}>{day}</div>
        <span className={`text-6xl font-bold leading-none ${isRead ? 'text-[#5F5F5F]' : 'text-themered'} pl-2.5`}>/</span>
      </div>
      <div className="text-right mt-auto">
        <h3 className={`font-normal pb-0 md:pb-1.5 text-overflow transition duration-500 ease-in-out --is-text-hover ${isRead ? 'text-[#5F5F5F]' : 'md:text-themered'}`}>{schoolName}</h3>
        <div className={`md:text-4xl text-lg text-themered font-bold transition duration-500 ease-in-out --is-text-hover ${isRead ? 'text-[#5F5F5F]' : 'text-themered'}`}>{month}</div>
      </div>
    </Link>
  );
};

NewsCard.defaultProps = {
  isRead: false,
  newsTime: '00:00:00',
};

export default NewsCard;
