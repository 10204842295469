import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import {
  getNumber,
  goBackIfUrlStateHavingSearchData,
} from '../../../helpers/commonHelper';
import OverlayBlock from '../../Common/overlayBlock';
import { sendViewTime } from '../../../actions/mspUser';
import { getSelectedRole } from '../../../actions/AuthUtil';
import NoticeDetail from './NoticeDetail';

interface IProps {
  onClose: () => void;
  notice: any;
}

const NoticeDetailSidebar = (props: React.PropsWithChildren<IProps>) => {
  const {
    onClose,
    notice,
  } = props;
  const timeInterval = useRef<any>(null);
  const viewTimer = useRef<any>(null);
  const history = useHistory();
  const parentDivRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    setTimeout(() => {
      const el = document.getElementById('notice-detail-sidebar');
      if (el) {
        el.classList.add('!right-0');
      }
    }, 100);
  }, []);

  const startTimer = () => {
    const selRole = getSelectedRole();
    if (selRole && (selRole.name.trim().toLowerCase() === 'guardian' || selRole.name.trim().toLowerCase() === 'vip') && notice) {
      if (getNumber(timeInterval?.current) > 0) {
        window.clearInterval(timeInterval.current);
        viewTimer.current = 0;
      }
      timeInterval.current = window.setInterval(() => {
        viewTimer.current += 1;
      }, 1000);
    }
  };

  const endTimer = () => {
    if (notice && timeInterval.current > 0) {
      window.clearInterval(timeInterval.current);
      if (viewTimer.current > 5) {
        sendViewTime(2, notice.id, viewTimer.current)
          .then((result: any) => {
            console.log(result);
          })
          .catch(() => {
          });
      }
      timeInterval.current = 0;
      viewTimer.current = 0;
    }
  };

  useEffect(() => {
    startTimer();
    return () => {
      endTimer();
    };
  }, [notice]);

  const onCloseBar = () => {
    document.body.classList.remove('overflow-sidebar');
    const el = document.getElementById('notice-detail-sidebar');
    if (el) {
      el.classList.remove('!right-0');
    }
    setTimeout(() => {
      onClose();
      goBackIfUrlStateHavingSearchData(history);
    }, 200);
  };

  return (
    <>
      <div
        id="notice-detail-sidebar"
        className="sidebar-bulletin-popup fixed z-[51] bottom-0 top-0 transition-all duration-300 ease-in-out bg-white overflow-touch overflow-y-auto w-full md:w-4/5 lg:w-2/4 xl:w-2/5 -right-full"
        ref={parentDivRef}
      >
        <div className="sidebar-top-action py-2 px-2 sticky z-50 top-0 flex items-center font-semibold text-sm text-slate-900  bg-slate-50/90 dark:bg-slate-700/90 backdrop-blur-sm ring-1 ring-slate-900/10">
          <button type="button" className="flex items-center group ml-auto" onClick={onCloseBar}>
            <span className="p-1 hover:text-themered group-hover:text-themered font-bold">Close</span>
            <div className="h-6 w-6 text-black relative flex items-center justify-center hover:bg-themered hover:bg-opacity-5 text-sm font-light rounded-md cursor-pointer">
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </div>
          </button>
        </div>
        <NoticeDetail notice={notice} parentRef={parentDivRef} showShareBtn showFavArchiveBtn />
      </div>
      <OverlayBlock
        id="notice_popup_mask"
        overlayZIndex={50}
        onClickOverlay={onCloseBar}
      />
    </>
  );
};

export default NoticeDetailSidebar;
