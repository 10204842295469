import React, { useEffect, useRef } from 'react';
import { animateOnMouseMove } from '../../../../helpers/uiHelper';

interface IProps {
  imgData: any;
  title: string;
  category: string;
  minRead: number;
  yearTags: string[];
  containerClass?: string;
  imgClass?: string;
  titleClass?: string;
  headClass?: string;
  bodyClass?: string;
  onClickCard: () => void;
}

const ArticleCard = (props: React.PropsWithChildren<IProps>) => {
  const {
    imgData,
    title,
    category,
    minRead,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    yearTags,
    containerClass,
    imgClass,
    titleClass,
    headClass,
    bodyClass,
    onClickCard,
  } = props;
  const containerRef = useRef(null);

  const onCardClick = (e: any) => {
    e.preventDefault();
    onClickCard();
  };

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
    animateOnMouseMove(e, containerRef?.current);
  };

  useEffect(() => {
    const el = containerRef?.current;
    if (el) {
      // @ts-ignore
      el.style.setProperty('--is-hovers-bg', '#f2f2f2');
    }
  }, []);

  return (
    <div className={`post-card is-card-zoom shadow-lg bg-white overflow-hidden ${containerClass}`}>
      {imgData && imgData.high_path && (
        <a href="#" onClick={onCardClick} className={`post-card-head relative overflow-hidden ${headClass}`} tabIndex={-1}>
          <img className={`object-contain is-img-zoom ${imgClass}`} src={imgData.high_path} alt={title} />
        </a>
      )}
      <div
        ref={containerRef}
        className={`post-card-body is-hover group ${bodyClass}`}
        onMouseEnter={onMouseMoveEvent}
        onMouseLeave={onMouseMoveEvent}
      >
        {/* <p class="text-xs">If need catgory name</p> */}
        <h2 className={`pb-4 !leading-tigh ${titleClass}`}>
          <a
            href="#"
            onClick={onCardClick}
            className="hover:text-themered focus:text-themered focus-within:text-themered break-words"
          >
            {title}
          </a>
        </h2>
        <div className="mt-auto">
          <div className="flex items-center flex-wrap">
            {category && <span className="text-xs font-bold mr-2">{category}</span>}
            {minRead && (
              <>
                {category && <div className="w-2 h-2 bg-themered rounded-full flex-none inline-block mr-2" />}
                <div className="text-gray-500 font-medium text-xs">{`${minRead} min read`}</div>
              </>
            )}
          </div>
          {/* (yearTags && yearTags.length > 0) && (
            <div className="text-xs pt-1" style={{ lineHeight: '14px' }}>
              {yearTags.map((item: string, index: number) => (
                <span className="font-semibold" key={item}>
                  {item}
                  {yearTags.length - 1 !== index &&
                  <div
            className="w-2 h-2 bg-themered rounded-full flex-none inline-block align-middle mx-2"
                  />}
                </span>
              ))}
            </div>
          ) */}
        </div>
      </div>
    </div>
  );
};

ArticleCard.defaultProps = {
  containerClass: '',
  imgClass: '',
  titleClass: '',
  headClass: '',
  bodyClass: '',
};

export default ArticleCard;
