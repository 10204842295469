import React, { useEffect, useRef, useState } from 'react';
import INameId from '../../interfaces/nameItem';
import GetSVG, { SVGID } from './GetSVG';

interface IProps {
  containerClass?: string,
  // eslint-disable-next-line
  btnContent: () => JSX.Element,
  btnClass: string,
  dropdownClass: string,
  dropdownData: INameId[],
  selectedOption: INameId | null,
  showCheckMark?: boolean,
  onSelectChange: (selItem: INameId) => void;
}

const CommonDropdown = (props: React.PropsWithChildren<IProps>) => {
  const {
    containerClass,
    btnContent,
    btnClass,
    dropdownClass,
    dropdownData,
    selectedOption,
    showCheckMark,
    onSelectChange,
  } = props;

  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef && dropdownRef.current) {
        // @ts-ignore
        if (!dropdownRef.current.contains(event.target)) {
          setIsShowDropdown(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFilterOptionChange = (item: INameId) => {
    onSelectChange(item);
    setIsShowDropdown(false);
  };

  return (
    <div className={containerClass} ref={dropdownRef}>
      <button
        className={btnClass}
        type="button"
        onClick={() => setIsShowDropdown(!isShowDropdown)}
      >
        {btnContent()}
      </button>
      <div
        className={`${isShowDropdown ? '' : 'hidden'} ${dropdownClass}`}
      >
        <ul className="text-sm text-gray-700 dark:text-gray-200 popover-dropdown overflow-hidden">
          {dropdownData.map((item) => (
            <li key={item.id} className="border-b border-gray-200">
              <button
                type="button"
                onClick={() => handleFilterOptionChange(item)}
                className="text-left block w-full px-3 py-3 text-xs font-medium hover:bg-themered hover:bg-opacity-5 flex items-center"
              >
                {(showCheckMark && selectedOption?.id === item.id) && (
                  <GetSVG ID={SVGID.CHECKMARK_ICON} svgClass="h-3 w-3 self-center" />
                )}
                {(showCheckMark && selectedOption?.id !== item.id) && (
                  <div className="w-3" />
                )}
                {item.iconId && (
                  <GetSVG ID={item.iconId} svgClass="h-4 w-4 self-center fill-gray-500" />
                )}
                <span className="ml-2">{item.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

CommonDropdown.defaultProps = {
  containerClass: '',
  showCheckMark: true,
};

export default CommonDropdown;
