import IUserDataStates from '../../interfaces/userDataStates';
import { UserDataAction } from '../actions/userDataActions';
import { UserDataActionType } from '../actiontypes/userDataActionTypes';

const initialState: IUserDataStates | null = null;

const reducer = (
  state: IUserDataStates | null = initialState,
  action: UserDataAction,
): IUserDataStates | null => {
  switch (action.type) {
    case UserDataActionType.SET_USERDATA:
      if (!action.payload) {
        return null;
      }
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
