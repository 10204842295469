import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { RootState } from '../../../store/reducers';
import * as uiActionCreators from '../../../store/actioncreators/uiActionCreators';

const BasketButton = () => {
  const dispatch = useDispatch();
  const { setBasketItemCount } = bindActionCreators(uiActionCreators, dispatch);
  const basketItemCount: number = useSelector(
    (state: RootState) => state.ui.basketItemCount,
  );

  useEffect(() => {
    setBasketItemCount();
  }, []);

  let remWidth: string = '0.875rem';
  switch (basketItemCount.toString().length) {
    case 2:
      remWidth = '1.25rem';
      break;
    case 3:
      remWidth = '1.5rem';
      break;
    case 4:
      remWidth = '1.75rem';
      break;
    case 5:
      remWidth = '2.25rem';
      break;
    default:
      remWidth = '0.875rem';
      break;
  }

  return (
    <button
      type="button"
      className="h-10 w-10 text-black group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center  text-sm font-light rounded-md cursor-pointer"
      onClick={() => toggleRightSidebar(
        UISidebarType.BASKET_SIDEBAR,
        document,
        dispatch,
      )}
    >
      <GetSVG ID={SVGID.CART_ICON} />
      {basketItemCount > 0 && (
        <>
          <span
            className="absolute bottom-0.5 right-1.5 h-3.5 tracking-tighter border-2 border-themered rounded-full bg-themered text-white flex items-center justify-center font-semibold"
            style={{ fontSize: '10px', width: remWidth }}
          >
            {basketItemCount}
          </span>
          <span className="animate-ping h-3.5 bg-themered/50 rounded-full absolute bottom-0.5 right-1.5" style={{ width: remWidth }} />
        </>
      )}
    </button>
  );
};

export default BasketButton;
