import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import * as uiActionCreators from '../../../store/actioncreators/uiActionCreators';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { getSearchKeywords } from '../../../actions/mspUser';
import { RootState } from '../../../store/reducers';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { isUrlHavingSearchData } from '../../../helpers/commonHelper';

interface IProps {
  editId: string,
  editClassName: string,
  isLeftSearchIcon: boolean,
  isSubmitSearchBtn: boolean,
  searchBtnClass: string,
  closeBtnClass: string,
  onSearchBtnClickFn?: () => void,
  onSearchEditFocusFn?: () => void,
  onSearchEditChangeFn?: (changeTxt: string) => void,
}

const SearchEdit = (props: React.PropsWithChildren<IProps>) => {
  const {
    editId,
    editClassName,
    isLeftSearchIcon,
    isSubmitSearchBtn,
    searchBtnClass,
    closeBtnClass,
    onSearchBtnClickFn,
    onSearchEditFocusFn,
    onSearchEditChangeFn,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const parentRef = useRef<HTMLDivElement | null>(null);
  const gSearchText: string = useSelector(
    (state: RootState) => state.ui.searchText,
  );
  const searchLoading: boolean = useSelector(
    (state: RootState) => state.ui.searchLoading,
  );
  const [keywordData, setKeywordData] = useState<string[]>([]);
  const [searchInputText, setSearchInputText] = useState<string>('');
  const [isShowSearchDropDown, setIsShowSearchDropDown] = useState<boolean>(false);

  useEffect(() => {
    if (isShowSearchDropDown) {
      getSearchKeywords()
        .then((res: any) => {
          if (res) {
            setKeywordData([...res]);
          }
        })
        .catch(() => {
        });
    }
  }, [isShowSearchDropDown]);

  useEffect(() => {
    if (window.location.search) {
      const query = new URLSearchParams(window.location.search);
      const searchTxt = query.get('search');
      if (searchTxt) {
        setSearchInputText(searchTxt);
        if (onSearchEditChangeFn) {
          onSearchEditChangeFn(searchTxt);
        }

        if (window.innerWidth > 768) {
          setTimeout(() => toggleRightSidebar(
            UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR,
            document,
            dispatch,
          ), 200);
        }
      }
    }

    const handleClickOutside = (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          setIsShowSearchDropDown(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onChangeSearchInput = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchInputText(e.currentTarget.value);
  };

  const onSearchFocus = () => {
    // @ts-ignore
    const curState: string = history?.location?.state?.itemCurPath || '';
    if (!curState || !isUrlHavingSearchData(curState)) {
      setIsShowSearchDropDown(true);
    }
    if (onSearchEditFocusFn) {
      onSearchEditFocusFn();
    }
  };

  const onSearch = () => {
    setIsShowSearchDropDown(false);
    if (onSearchEditChangeFn) {
      onSearchEditChangeFn(searchInputText);
    }
  };

  const onSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const onDropdownItemSelect = (item: string) => {
    setSearchInputText(item);
    if (onSearchEditChangeFn) {
      onSearchEditChangeFn(item);
    }
    setIsShowSearchDropDown(false);
  };

  const onDropdownCloseBtnClick = () => {
    setIsShowSearchDropDown(false);
  };

  const onCloseBtnClick = () => {
    if (gSearchText.trim().length > 0 || searchInputText.length > 0) {
      onDropdownItemSelect('');
      const { setSearchText } = bindActionCreators(uiActionCreators, dispatch);
      setSearchText('');
    }
  };

  const onSearchBtnClick = () => {
    onSearch();
    if (onSearchBtnClickFn) {
      onSearchBtnClickFn();
    }
  };

  return (
    <div ref={parentRef}>
      <input
        type="text"
        name={editId}
        id={editId}
        className={editClassName}
        placeholder="Search here"
        autoComplete="off"
        list="browsers"
        onChange={onChangeSearchInput}
        onFocus={onSearchFocus}
        onKeyPress={onSearchKeyPress}
        value={searchInputText}
      />
      {keywordData.length > 0 && (
        <div className={`absolute top-14 md:top-10 z-10 bg-white w-full rounded-md border border-gray-300 mt-2 rounded-b-lg shadow-lg popover-dropdown overflow-hidden ${isShowSearchDropDown ? '' : 'hidden'}`}>
          <div className="flex justify-between w-full border-b border-themered">
            <div className="py-3 px-4 font-semibold text-sm">Recent Search</div>
            <button type="button" className="border-b mr-2" onClick={onDropdownCloseBtnClick}>
              <GetSVG ID={SVGID.SEARCHCLOSE_ICON} svgClass="h-5 w-5 mt-2 ml-2 fill-gray-500 text-black text-sm font-light cursor-pointer" />
            </button>
          </div>
          <ul>
            {keywordData.map((keyword) => (
              <li className="border-b border-gray-200" key={keyword}>
                <button
                  type="button"
                  className="text-left block w-full px-3 py-3 text-xs font-medium hover:bg-themered hover:bg-opacity-5 flex"
                  onClick={() => onDropdownItemSelect(keyword)}
                >
                  {keyword}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <span className={`absolute inset-y-0 flex items-center ${isLeftSearchIcon ? 'left-0' : 'right-0'}`}>
        {(!searchLoading && searchInputText.trim().length > 0 && !isLeftSearchIcon) && (
          <button
            type="button"
            className={closeBtnClass}
            onClick={onCloseBtnClick}
          >
            <GetSVG ID={SVGID.SEARCHCLOSE_ICON} svgClass="fill-gray-500" />
          </button>
        )}
        {!searchLoading && (
          <button
            type={isSubmitSearchBtn ? 'submit' : 'button'}
            className={searchBtnClass}
            onClick={onSearchBtnClick}
          >
            <GetSVG ID={SVGID.SEARCH_ICON} />
          </button>
        )}
        {searchLoading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="animate-spin flex-none h-5 w-5 ml-4 mr-2" />}
      </span>
      {isLeftSearchIcon && (
        <span className="absolute inset-y-0 flex items-center right-0">
          {(!searchLoading && searchInputText.trim().length > 0) && (
            <button
              type="button"
              className={closeBtnClass}
              onClick={onCloseBtnClick}
            >
              <GetSVG ID={SVGID.SEARCHCLOSE_ICON} svgClass="fill-gray-500" />
            </button>
          )}
        </span>
      )}
    </div>
  );
};

SearchEdit.defaultProps = {
  onSearchBtnClickFn: null,
  onSearchEditFocusFn: null,
  onSearchEditChangeFn: null,
};

export default SearchEdit;
