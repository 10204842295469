import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import WeeklyNewsTitle from './WeeklyNewsTitle';
import GetSVG, { SVGID } from '../../../Common/GetSVG';
import ArticlePopup from './ArticlePopup';
import ArticleFilterPopup from './ArticleFilterPopup';
import ArticleFilterTagBox from './ArticleFilterTagBox';
import ArticleFilterView from './ArticleFilterView';
import ArticleNormalView from './ArticleNormalView';
import { getWeeklyNewsById } from '../../../../actions/mspUser';
import GetMetaHeader, { PAGEID } from '../../../Common/seoHelper';
import NotFound from '../../NotFound';

const WeeklyNewsView = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedArticle, setSelectedArticle] = useState(-1);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [activeYears, setActiveYears] = useState<string[]>([]);
  const [activeSubjects, setActiveSubjects] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [news, setNews] = useState<any>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [noFound, setNoFound] = useState(false);

  const [allYears, setAllYears] = useState<string[]>([]);
  const [allSubjects, setAllSubjects] = useState<string[]>([]);

  const onGoBack = () => {
    history.goBack();
  };

  const onChangeFilter = (selectedYears: string[], selectedSubjects: string[]) => {
    setActiveYears(selectedYears);
    setActiveSubjects(selectedSubjects);
  };

  const fetchAllYearsAndSubjects = (curNews: any) => {
    if (!curNews) return;
    let allNews: any[] = [];
    if (curNews.heads_message) {
      allNews.push(curNews.heads_message);
    }
    allNews = [...allNews, ...curNews.pinned_articles, ...curNews.other_articles];
    const subjects: string[] = [];
    const years: string[] = [];
    allNews.forEach(item => {
      if (item.subject && subjects.findIndex(sub => sub === item.subject.name) < 0) {
        subjects.push(item.subject.name);
      }

      item.years?.forEach((year: any) => {
        if (year && years.findIndex(sub => sub === year.name) < 0) {
          years.push(year.name);
        }
      });
    });
    subjects.sort((a, b) => a.localeCompare(b));
    const yearSortOptions = { numeric: true, sensitivity: 'base' };
    years.sort((a, b) => a.localeCompare(b, undefined, yearSortOptions));
    setAllSubjects(subjects);
    setAllYears(years);

    if (allNews.length > 1) {
      setShowFilter(subjects.length > 0 || years.length > 0);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const newsId: number = parseInt(query.get('id') || '0', 10);
    if (newsId <= 0) {
      return;
    }

    const articleId: number = parseInt(query.get('article') || '0', 10);

    setLoading(true);
    getWeeklyNewsById(newsId)
      .then((result: any) => {
        setNews(result?.data);
        setLoading(false);
        if (articleId > 0) {
          setSelectedArticle(articleId);
        }
        fetchAllYearsAndSubjects(result?.data);
      })
      .catch((err) => {
        if (err.status === 404) {
          setNoFound(true);
        }
        setLoading(false);
      });
  }, []);

  if (noFound) {
    return <NotFound />;
  }

  if (loading || !news) {
    return null;
  }

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_NEWSDETAIL} customTitle={`${news.title} | News`} />
      <section className="main-section">
        <div className="main-content-full grow -mx-4 lg:mx-0 p-4 !pt-0 lg:p-6">
          <WeeklyNewsTitle newsDate={news.date_of_publication} schoolName={news.division.name} />
          <div className="flex justify-between items-center mt-6 mb-4 ">
            <button type="button" onClick={onGoBack} className="flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5">
              <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light ">
                <GetSVG ID={SVGID.LEFTARROW_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
              </div>
              <h3 className="p-1 hover:text-themered group-hover:text-themered">Back</h3>
            </button>
            {showFilter && (
              <button
                type="button"
                className="flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5"
                onClick={() => setIsFilterPopupOpen(true)}
              >
                <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light rounded-md cursor-pointer">
                  <GetSVG ID={SVGID.FILTER_ICON} />
                </div>
                <strong className="p-1 text-lg hover:text-themered group-hover:text-themered">Filter</strong>
              </button>
            )}
          </div>
          <ArticleFilterTagBox
            activeYears={activeYears}
            activeSubjects={activeSubjects}
            onChangeTagBox={
              (selectedYears, selectedSubjects) => onChangeFilter(selectedYears, selectedSubjects)
            }
          />
          <div className="main-sidebar-section space-y-4 lg:space-x-6 lg:space-y-0 lg:flex flex-wrap lg:flex-nowrap">
            <ArticleFilterView
              news={news}
              activeYears={activeYears}
              activeSubjects={activeSubjects}
              onClickCard={(articleId) => setSelectedArticle(articleId)}
            />
            <ArticleNormalView
              news={news}
              activeYears={activeYears}
              activeSubjects={activeSubjects}
              onClickCard={(articleId) => setSelectedArticle(articleId)}
            />
          </div>
        </div>
      </section>
      {selectedArticle > 0 && (
        <ArticlePopup
          news={news}
          articleId={selectedArticle}
          onClose={() => setSelectedArticle(0)}
        />
      )}
      {isFilterPopupOpen && (
        <ArticleFilterPopup
          allYears={allYears}
          allSubjects={allSubjects}
          activeYears={activeYears}
          activeSubjects={activeSubjects}
          onChangeFilter={
            (selectedYears, selectedSubjects) => onChangeFilter(selectedYears, selectedSubjects)
          }
          onClose={() => setIsFilterPopupOpen(false)}
        />
      )}
    </>
  );
};

export default WeeklyNewsView;
