import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowContainer, Popover, PopoverState } from 'react-tiny-popover';
import { toggleSidebar } from '../../../helpers/uiHelper';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import SidebarItem from './SidebarItem';
import { isPageAccesible } from '../../../helpers/roleHelper';
import { logoutUser } from '../../../actions/mspUser';
import { getSelectedMSP } from '../../../actions/AuthUtil';
import { getMSPUrls } from '../../../helpers/commonHelper';

interface IProps {
  role: string;
}

const Sidebar = (props: React.PropsWithChildren<IProps>) => {
  const {
    role,
  } = props;

  const [msp, setMSP] = useState<any>({});
  const popupRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const location = useLocation();
  const pathName = location?.pathname || '/';
  const upperItems = [
    {
      title: 'Home', itemRoutePath: '/home', isActive: false, SVGId: SVGID.HOME_SIDEBAR,
    },
    {
      title: 'Need to Know', itemRoutePath: '/need-to-know', isActive: false, SVGId: SVGID.INBOX_SIDEBAR,
    },
    {
      title: 'Calendar', itemRoutePath: '/calendar', isActive: false, SVGId: SVGID.CALENDAR_SIDEBAR,
    },
    {
      title: 'Highlights', itemRoutePath: '/highlights', isActive: false, SVGId: SVGID.HIGHLIGHTS_SIDEBAR,
    },
    {
      title: 'News', itemRoutePath: '/news', isActive: false, SVGId: SVGID.NEWS_SIDEBAR,
    },
    {
      title: 'Gallery', itemRoutePath: '/gallery', isActive: false, SVGId: SVGID.GALLERY_SIDEBAR,
    },
  ];

  const lowerItems = [
    {
      title: 'Logout', itemRoutePath: '/', isActive: false, SVGId: SVGID.LOGOUT_SIDEBAR, liClass: 'mb-0', titleClass: 'whitespace-nowrap',
    },
  ];

  useEffect(() => {
    const selMSP = getSelectedMSP();
    if (selMSP) {
      setMSP(getMSPUrls(selMSP));
    }
  }, []);

  const toggleSidePanel = () => {
    if (window.innerWidth <= 767) {
      toggleSidebar(document);
    }
  };

  const sidebarItemClick = (
    event: React.MouseEvent<HTMLAnchorElement | MouseEvent>, title: string,
  ) => {
    if (title === 'Logout') {
      event.preventDefault();
      logoutUser()
        .then(() => {
          window.location.href = '/login';
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    toggleSidePanel();
  };

  const renderMenu = ({ position, childRect, popoverRect }: PopoverState) => (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor="white"
      arrowSize={0}
      arrowStyle={{ opacity: 0.7 }}
      className="popover-arrow-container"
      arrowClassName="popover-arrow"
    >
      <div className="popover-dropdown w-48 z-[100]">
        <ul className="w-full rounded-lg overflow-hidden">
          {Object.keys(msp).map((key, index) => (
            <li className={index === Object.keys(msp).length - 1 ? '' : 'border-b border-themered'} key={key}>
              <a
                href={msp[key]}
                target="_blank"
                rel="noreferrer"
                className="text-left block w-full px-3 py-3 text-xs font-medium hover:bg-themered hover:bg-opacity-5 flex items-center"
                onClick={() => setIsPopoverOpen(false)}
              >
                <span className="ml-2">{`MSP for ${key}`}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </ArrowContainer>
  );

  const highWidth = (window.innerWidth > 768);
  return (
    <aside className="main-menu relative">
      <nav className="mainmenu  bg-white fixed expanded transition-all duration-300 ease-in-out drop-shadow-lg min-h-0 z-40 flex-1 flex flex-col-left-full bottom-0 top-16 md:top-24 right-auto w-72 md:left-0 md:w-44">
        <div className="flex py-6 h-auto flex-1 pt-5 md:pt-10 flex-col justify-between overflow-x-hidden overflow-y-auto" ref={popupRef}>
          <ul className="px-4 flex items-center w-full flex-col">
            {upperItems.map((item) => {
              if (!isPageAccesible(item.itemRoutePath, role.toLowerCase())) {
                return null;
              }
              const isActive = (item.itemRoutePath === '/home' && pathName === '/') || (item.itemRoutePath !== '/' && pathName.includes(item.itemRoutePath));
              return (
                <SidebarItem
                  key={item.title}
                  title={item.title}
                  itemRoutePath={item.itemRoutePath}
                  isActive={isActive}
                  SVGId={item.SVGId}
                  titleClass=""
                  onClickItem={sidebarItemClick}
                />
              );
            })}
          </ul>
          <ul className="px-4 items-center flex-shrink-0">
            <li className="relative h-10 w-full mb-5">
              <Popover
                containerClassName="z-[60]"
                isOpen={isPopoverOpen}
                positions={[highWidth ? 'right' : 'top', 'bottom']}
                align="end"
                padding={highWidth ? 20 : 0}
                reposition
                onClickOutside={() => setIsPopoverOpen(false)}
                content={(popoverState) => renderMenu(popoverState)}
                parentElement={popupRef.current || undefined}
              >
                <a className="link-item group cursor-pointer --jbi-popover-dropdown" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <small className="link-active ">MSP</small>
                  <GetSVG ID={SVGID.SCHOOLPORTAL_SIDEBAR} />
                  <span className="link-label ml-3 leading-tight w-fit group-hover:text-themered group-focus:text-themered ">MSP</span>
                </a>
              </Popover>
            </li>
            {lowerItems.map((item) => (
              <SidebarItem
                key={item.title}
                title={item.title}
                itemRoutePath={item.itemRoutePath}
                isActive={false}
                SVGId={item.SVGId}
                liClass={item.liClass}
                whitespace-nowrap={item.titleClass}
                onClickItem={sidebarItemClick}
              />
            ))}
          </ul>
        </div>
      </nav>
      <div
        className="mainmenu-mask visible md:invisible fixed inset-0 bg-white w-screen h-screen z-30 bg-opacity-50 hidden transition-all duration-300 ease-in-out"
        onClick={toggleSidePanel}
      />
    </aside>
  );
};

export default Sidebar;
