import React, { useEffect, useState } from 'react';
import NewsCategory from './NewsCategory';
import { getSelectedRole } from '../../../../actions/AuthUtil';
import { getAllDivisions, getCurrentUserDivisions, getCurrentUserStudents } from '../../../../actions/mspUser';
import GetMetaHeader, { PAGEID } from '../../../Common/seoHelper';
import GetSVG, { SVGID } from '../../../Common/GetSVG';
import NewsFilterPopup from './NewsFilterPopup';
import NewsFilterTagBox from './NewsFilterTagBox';

const News = () => {
  const [allDivisions, setAllDivisions] = useState<any[]>([]);
  const [activeDivisions, setActiveDivisions] = useState<string[]>([]);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);

  const onChangeFilter = (selectedSchools: string[]) => {
    setActiveDivisions(selectedSchools);
  };

  useEffect(() => {
    const selRole = getSelectedRole();
    if (selRole) {
      const roleName = selRole?.name || '';
      if (roleName.toLowerCase() === 'staff') {
        getAllDivisions()
          .then((result: any) => {
            const divisions: any[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                name: item?.name,
              }
            ));
            divisions.sort((a, b) => a.name.localeCompare(b.name));
            setAllDivisions(divisions);
          })
          .catch(() => {
          });
      } else if (roleName.toLowerCase() === 'student 11+') {
        getCurrentUserDivisions()
          .then((result: any) => {
            const divisions: any[] = result?.data.map((item: any) => (
              {
                id: item?.id,
                name: item?.name,
              }
            ));
            divisions.sort((a, b) => a.name.localeCompare(b.name));
            setAllDivisions(divisions);
          })
          .catch(() => {
          });
      } else if (roleName.toLowerCase() === 'vip' || roleName.toLowerCase() === 'guardian') {
        getCurrentUserStudents()
          .then((result: any) => {
            const divisions: any[] = result?.data.map((item: any) => (
              {
                id: item?.divisions?.length > 0 && item?.divisions[0]?.id,
                name: item?.divisions?.length > 0 && item?.divisions[0]?.name,
              }
            ));
            const uniqueDivs = divisions.filter((item, pos) => (
              divisions.findIndex(nitem => item.id === nitem.id) === pos
            ));
            uniqueDivs.sort((a, b) => a.name.localeCompare(b.name));
            setAllDivisions(uniqueDivs);
          })
          .catch(() => {
          });
      }
    }
    document.getElementById('root')?.classList.add('overflow-x-hidden');
    return () => document.getElementById('root')?.classList.remove('overflow-x-hidden');
  }, []);

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_NEWS} />
      <section className="main-section">
        <div className="main-content-full -mx-4 lg:mx-0 p-4 lg:p-6 lg:pb-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="mb-0">News</h1>
            {allDivisions.length > 1 && (
              <button
                type="button"
                className="flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 --jbi-modal"
                onClick={() => setIsFilterPopupOpen(true)}
              >
                <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light rounded-md cursor-pointer">
                  <GetSVG ID={SVGID.FILTER_ICON} />
                </div>
                <strong className="p-1 text-lg hover:text-themered group-hover:text-themered hidden md:block">Filter</strong>
              </button>
            )}
          </div>
          <div className="mb-6 max-w-2xl">
            <p>
              Welcome to your weekly news update. Read the
              latest school news and browse past publications.
            </p>
          </div>
          <NewsFilterTagBox
            activeSchools={activeDivisions}
            onChangeTagBox={
                (selectedSchools) => onChangeFilter(selectedSchools)
            }
          />
        </div>
        <NewsCategory id={0} title="Latest" activeSchools={activeDivisions} />
        {allDivisions.map(item => (
          <NewsCategory
            key={item.id}
            id={item.id}
            title={item.name}
            activeSchools={activeDivisions}
          />
        ))}
      </section>
      {isFilterPopupOpen && (
        <NewsFilterPopup
          allSchools={allDivisions.map(item => item.name)}
          activeSchools={activeDivisions}
          onChangeFilter={
            (selectedSchools) => onChangeFilter(selectedSchools)
          }
          onClose={() => setIsFilterPopupOpen(false)}
        />
      )}
    </>
  );
};

export default News;
