import React, { useState } from 'react';
import {
  formatDate,
  getNameFromFilePath,
} from '../../../helpers/commonHelper';
import ShareContentButton from '../../Common/ShareContentButton';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import FavouriteContent from '../../Common/FavouriteContent';
import ArchiveContent from '../../Common/ArchiveContent';

interface IProps {
  notice: any;
  showShareBtn: boolean;
  showFavArchiveBtn: boolean;
  parentRef?: any;
}

const NoticeDetail = (props: React.PropsWithChildren<IProps>) => {
  const {
    notice,
    showShareBtn,
    showFavArchiveBtn,
    parentRef,
  } = props;

  const [curNotice, setCurNotice] = useState(notice);

  const onClickFavourite = () => {
    const newNotice = { ...curNotice };
    newNotice.favourite = +!newNotice.favourite;
    setCurNotice(newNotice);
  };

  const onClickArchiveButton = (res: any) => {
    const newNotice = { ...curNotice };
    newNotice.archived = res;
    setCurNotice(newNotice);
  };

  return (
    <>
      <div className="overflow-hidden pt-4 pb-4 px-5 md:px-14 top-11">
        <div className="flex justify-between w-full">
          <div>
            <div className="text-sm font-light">
              <span>
                {formatDate(curNotice.calculated_date, 'yyyy-MM-DD HH:mm:ss', 'DD MMM yyyy')}
              </span>
              <span className="ml-2">
                {formatDate(curNotice.calculated_date, 'yyyy-MM-DD HH:mm:ss', 'HH:mm')}
              </span>
            </div>
            <h2 className="h2">{curNotice?.title}</h2>
          </div>
          {showFavArchiveBtn && (
            <div className="flex">
              {(curNotice.archived === 0) && (
                <FavouriteContent
                  contentId={curNotice.id}
                  favourite={curNotice.favourite}
                  favouriteIconClass="h-8 w-8"
                  onChangeFavourite={onClickFavourite}
                />
              )}
              {(curNotice.favourite === 0 || curNotice.archived === 1) && (
                <ArchiveContent
                  contentId={curNotice.id}
                  archive={curNotice.archived}
                  containerClass="ml-2 w-[22px]"
                  archiveIconClass="w-100"
                  onChangeArchive={onClickArchiveButton}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pt-2 pb-6 px-5 md:px-14">
        {curNotice?.description && (
          <div
            className="post-detail-info pb-6 post-md-px-ulol"
            dangerouslySetInnerHTML={{ __html: curNotice?.description }}
          />
        )}
        {(curNotice?.important_date || curNotice?.important_time) && (
          <div className="pb-6 max-w-2xl">
            {curNotice?.important_date && (
              <div className="grid grid-cols-3 pb-3">
                <div className="text-themered font-bold">Date</div>
                <div className="text-base col-span-2 font-bold text-right">{formatDate(curNotice?.important_date, 'DD-MM-yyyy', 'dddd, Do MMMM yyyy')}</div>
              </div>
            )}
            {curNotice?.important_time && (
              <div className="grid grid-cols-3 pb-3">
                <div className="text-themered font-bold">Time</div>
                <div className="text-base col-span-2 font-bold text-right">{curNotice?.important_time}</div>
              </div>
            )}
          </div>
        )}
        {(curNotice?.pdf && curNotice?.pdf.length > 0) && (
          <>
            <h3 className="mb-2">PDF Downloads</h3>
            {curNotice?.pdf.map((item: any) => (
              <div className="text-center mt-auto pb-3 max-w-2xl" key={item.url}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-red w-full btn-px-4 flex space-x-6"
                >
                  <span className="whitespace-normal text-left">
                    {item.name || getNameFromFilePath(item.url)}
                  </span>
                  <span className="flex-none">
                    <GetSVG ID={SVGID.DOWNLOAD_ICON} svgClass="fill-white h-6 w-6" />
                  </span>
                </a>
              </div>
            ))}
          </>
        )}
        {showShareBtn && (
          <ShareContentButton
            contentId={curNotice.id}
            contentType="notice"
            contentTitle={curNotice.title}
            contentDate={formatDate(curNotice.calculated_date, 'yyyy-MM-DD HH:mm:ss', 'DD MMM yyyy')}
            parentRef={parentRef}
          />
        )}
      </div>
    </>
  );
};

NoticeDetail.defaultProps = {
  parentRef: null,
};

export default NoticeDetail;
