import cookie from 'react-cookies';

export function saveCookie(name: string, value: any, exdays = 0) {
  const cookieOptions = {
    path: '/',
    // eslint-disable-next-line no-restricted-globals
    domain: location.hostname,
  };

  // eslint-disable-next-line no-restricted-globals
  if (location.protocol?.toLowerCase().includes('https')) {
    // @ts-ignore
    cookieOptions.secure = true;
  }

  if (exdays > 0) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    // @ts-ignore
    cookieOptions.expires = d;
  }

  cookie.save(name, value, cookieOptions);
}

export function loadCookie(name: string) {
  return cookie.load(name);
}

export function removeCookie(name: string) {
  const cookieOptions = {
    path: '/',
    // eslint-disable-next-line no-restricted-globals
    domain: location.hostname,
  };
  cookie.remove(name, cookieOptions);
}
