import React from 'react';

interface IProps {
  activeSchools: string[],
  onChangeTagBox: (selectedSchools: string[]) => void
}

const NewsFilterTagBox = (props: React.PropsWithChildren<IProps>) => {
  const {
    activeSchools,
    onChangeTagBox,
  } = props;

  const onResetTags = () => {
    onChangeTagBox([]);
  };

  const onSelectedSchoolsChange = (school: string) => {
    const curSchools = activeSchools;
    const index = curSchools.indexOf(school);
    if (index >= 0) {
      curSchools.splice(index, 1);
      onChangeTagBox([...curSchools]);
    }
  };

  if (activeSchools.length === 0) {
    return null;
  }

  return (
    <div className="flex justify-between items-start mb-6 gap-3">
      <div className="shrink flex justify-start items-center gap-3 flex-wrap">
        {activeSchools.map((item) => (
          <button type="button" className="flex items-center rounded-md text-xs font-bold p-2 border border-transparent cursor-pointer hover:bg-themered hover:bg-opacity-5 hover:border-themered">
            <svg className="i-svg fill-black h-4 w-4" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => onSelectedSchoolsChange(item)}>
              <path d="M2 2L9.9429 10" stroke="#D10027" strokeWidth="2.5" strokeLinecap="round" />
              <path d="M2 10L9.9429 2" stroke="#D10027" strokeWidth="2.5" strokeLinecap="round" />
            </svg>
            <span className="pl-2 hover:text-themered focus:text-themered">{item}</span>
          </button>
        ))}
      </div>
      <div className="flex-none">
        <button
          type="button"
          onClick={onResetTags}
          className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default NewsFilterTagBox;
