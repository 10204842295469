import { Dispatch } from 'redux';
import { UIActionType, UISidebarType } from '../actiontypes/uiActionTypes';
import { UIAction } from '../actions/uiActions';
import { getBasket } from '../../actions/AuthUtil';
import ISearchItem from '../../interfaces/searchItem';

export const setRightBar = (sidebarName: UISidebarType) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_SIDEBAR,
    payload: sidebarName,
  });
};

export const setSearchText = (searchText: string) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_SEARCH_TEXT,
    payload: searchText,
  });
};

export const setSearchLoading = (isLoading: boolean) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_SEARCH_LOADING,
    payload: isLoading,
  });
};

export const setToggleLightbox = (isOpen: boolean) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_TOGGLE_LIGHTBOX,
    payload: isOpen,
  });
};

export const setBasketItemCount = () => (dispatch: Dispatch<UIAction>) => {
  const basketObj = getBasket();
  dispatch({
    type: UIActionType.SET_BASKET_ITEM_COUNT,
    payload: (basketObj && basketObj.length) || 0,
  });
};

export const setFavouriteChangeItem = (id: number) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_FAVOURITECHANGE_ITEM,
    payload: id,
  });
};

export const setArchiveChangeItem = (id: number) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_ARCHIVECHANGE_ITEM,
    payload: id,
  });
};

export const addSearchHistory = (searchItem: ISearchItem) => (dispatch: Dispatch<UIAction>) => {
  dispatch({
    type: UIActionType.SET_SEARCHHISTORY_ITEM,
    payload: searchItem,
  });
};
