import React, { useRef } from 'react';
import { animateOnMouseMove } from '../../../helpers/uiHelper';

interface IProps {
  id: number,
  name: string,
  imgSrc: string,
  onSelectRole: (id: number) => void,
}

const RoleItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    name,
    imgSrc,
    onSelectRole,
  } = props;
  const containerRef = useRef(null);

  const onChangeSelection = (e: React.MouseEvent<HTMLElement | MouseEvent>, roleId: number) => {
    e.preventDefault();
    onSelectRole(roleId);
  };

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    animateOnMouseMove(e, containerRef?.current);
  };

  return (
    <a
      ref={containerRef}
      href="#"
      className="is-hover bg-white rounded-2xl shadow-lg p-6 max-w-[9rem] w-full transition-all group sm:p-9 sm:max-w-xs mb-3 sm:mb-11"
      onClick={(e) => onChangeSelection(e, id)}
      onMouseEnter={onMouseMoveEvent}
      onMouseLeave={onMouseMoveEvent}
    >
      <div className="pb-3 sm:pb-6">
        <img src={imgSrc} className="m-auto max-h-20 sm:max-h-44" alt={name} />
      </div>
      <h2 className="group-hover:text-white group-focus:text-white transition duration-500 ease-in-out">
        {name}
      </h2>
    </a>
  );
};

export default RoleItem;
