// import { loadCookie, removeCookie, saveCookie } from '../helpers/cookieHelper';
import { bindActionCreators } from 'redux';
import INameItem from '../interfaces/nameItem';
import * as uiActionCreators from '../store/actioncreators/uiActionCreators';

export const getAuthToken = () => (
  // loadCookie('token')
  localStorage.getItem('token')
);

export const saveAuthToken = (token: string) => {
  // saveCookie('token', token);
  localStorage.setItem('token', token);
};

export const removeAuthToken = () => {
  // removeCookie('token');
  localStorage.removeItem('token');
};

export const getSelectedRole = (): INameItem | null => {
  const selRole = localStorage.getItem('selrole');
  if (selRole) {
    return JSON.parse(selRole) as INameItem;
  }
  return null;
};

export const saveSelectedRole = (id: number, name: string) => {
  localStorage.setItem('selrole', JSON.stringify({ id, name }));
};

export const removeSelectedRole = () => {
  localStorage.removeItem('selrole');
};

export const getSelectedMSP = (): string[] => {
  const selMSP = localStorage.getItem('curMSP');
  if (selMSP) {
    return JSON.parse(selMSP) as string[];
  }
  return [];
};

export const saveSelectedMSP = (msp: string[]) => {
  localStorage.setItem('curMSP', JSON.stringify(msp));
};

export const removeSelectedMSP = () => {
  localStorage.removeItem('curMSP');
};

export const clearAllAuthData = () => {
  removeAuthToken();
  removeSelectedMSP();
  removeSelectedRole();
};

export const getBasket = () => {
  const selRole = getSelectedRole();
  if (selRole) {
    const label = `Basket_${selRole.id}`;
    const jsonStr = localStorage.getItem(label);
    let basketObj = [];
    if (jsonStr) {
      basketObj = JSON.parse(jsonStr) || [];
    }
    return basketObj;
  }
  return [];
};

export const setBasket = (basket: any[], dispatch: any) => {
  const selRole = getSelectedRole();
  if (selRole) {
    const label = `Basket_${selRole.id}`;
    localStorage.setItem(label, JSON.stringify(basket));

    const { setBasketItemCount } = bindActionCreators(uiActionCreators, dispatch);
    setBasketItemCount();
  }
};
