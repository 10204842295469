import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import { useDispatch } from 'react-redux';
import { ArrowContainer, Popover, PopoverState } from 'react-tiny-popover';
import GetSVG, { SVGID } from './GetSVG';
import { makeIcsDescription, makeIcsFile } from '../../helpers/commonHelper';
// import { toggleRightSidebar } from '../../helpers/uiHelper';
// import { UISidebarType } from '../../store/actiontypes/uiActionTypes';

interface IProps {
  title: string;
  schoolName: string;
  startDate: string;
  endDate: string;
  isFullDay: boolean;
  location: string;
  description: string;
  findOurUrl: string;
  colorCode: string;
  parentRef: any;
  containerClass: string;
  onViewEvent: () => void;
}

const EventItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    title,
    schoolName,
    startDate,
    endDate,
    isFullDay,
    location,
    description,
    findOurUrl,
    colorCode,
    parentRef,
    containerClass,
    onViewEvent,
  } = props;

  // const dispatch = useDispatch();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onScroll = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    parentRef?.current?.addEventListener('scroll', onScroll, { passive: true });
    return () => parentRef?.current?.removeEventListener('scroll', onScroll);
  }, []);

  const onViewEventDetail = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    event.preventDefault();
    // toggleRightSidebar(UISidebarType.VIEWCALENDAREVENT_SIDEBAR, document, dispatch);
    onViewEvent();
    setIsPopoverOpen(false);
  };

  const dowloadICS = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    e.preventDefault();
    const start = moment(startDate, 'DD-MM-yyyy HH:mm:ss');
    const end = (endDate && moment(endDate, 'DD-MM-yyyy HH:mm:ss')) || null;

    const icsFile = makeIcsFile(
      title,
      start,
      end,
      isFullDay,
      location,
      makeIcsDescription(description, findOurUrl),
    );
    const a = document.createElement('a');
    a.href = icsFile;
    a.setAttribute('download', 'event.ics');
    a.click();
    setIsPopoverOpen(false);
  };

  const renderMenu = ({ position, childRect, popoverRect }: PopoverState) => (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor="white"
      arrowSize={0}
      arrowStyle={{ opacity: 0.7 }}
      className="popover-arrow-container"
      arrowClassName="popover-arrow"
    >
      <div className="popover-dropdown w-32 z-10">
        <ul className="w-full rounded-lg overflow-hidden">
          <li className="border-b border-themered">
            <a
              href="#"
              className="block text-xs font-medium p-3 text-black outline-none hover:text-themered hover:bg-themered hover:bg-opacity-5 focus:text-themered focus:bg-themered focus:bg-opacity-5 focus-visible:text-themered focus-visible:bg-themered focus-visible:bg-opacity-5"
              onClick={onViewEventDetail}
            >
              View Detail
            </a>
          </li>
          <li>
            <a href="#" onClick={dowloadICS} className="block text-xs font-medium p-3 text-black outline-none hover:text-themered hover:bg-themered hover:bg-opacity-5 focus:text-themered focus:bg-themered focus:bg-opacity-5 focus-visible:text-themered focus-visible:bg-themered focus-visible:bg-opacity-5">Add to calendar</a>
          </li>
        </ul>
      </div>
    </ArrowContainer>
  );

  let eventTime = 'All Day';
  if (!isFullDay) {
    // const start = moment.utc(startDate, 'DD-MM-yyyy hh:mm:ss');
    // const end = moment.utc(endDate, 'DD-MM-yyyy hh:mm:ss');
    // return `${start.local().format('hh:mm')} - ${end.local().format('hh:mm')}`;

    const start = moment(startDate, 'DD-MM-yyyy HH:mm:ss');
    const end = (endDate && moment(endDate, 'DD-MM-yyyy HH:mm:ss')) || null;
    eventTime = `${start.format('HH:mm')}`;
    if (end) {
      eventTime = `${eventTime} - ${end.format('HH:mm')}`;
    }
  }
  return (
    <div className={containerClass}>
      <div className="card-event is-event-hover relative h-full flex flex-col justify-between p-2 pl-6 md:pl-6 md:p-4 bg-white rounded-lg shadow-md transition-all group">
        <Popover
          containerClassName="z-10"
          isOpen={isPopoverOpen}
          positions={['left', 'right', 'top', 'bottom']}
          align="start"
          padding={10}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={(popoverState) => renderMenu(popoverState)}
        >
          <button
            type="button"
            className="--is-not-hover group p-2 rounded-tr-md rounded-bl-md h-7 w-10 flex-shrink-0 absolute z-10 transition-all top-0 right-0 text-black group hover:bg-themered focus:bg-themered hover:text-white focus:text-white --jbi-popover-dropdown"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <GetSVG ID={SVGID.THREEDOTMENU_ICON} />
          </button>
        </Popover>
        <h3 className="h4 text-base font-bold pr-5">
          <a
            href="#"
            className="--is-not-hover inline-block text-black no-underline outline-none pb-2 focus-within:text-themered"
            onClick={onViewEventDetail}
          >
            {title}
          </a>
        </h3>
        <div className="flex justify-between flex-wrap gap-2">
          <p className="flex text-overflow text-xs items-center text-black --is-not-hover">
            <GetSVG ID={SVGID.SCHOOL_ICON} />
            {schoolName}
          </p>
          <p className="flex text-overflow text-xs items-center text-black --is-not-hover">
            <GetSVG ID={SVGID.CLOCK_ICON} />
            {eventTime}
          </p>
        </div>
        <div className="--is-not-hover card-event-border" style={{ backgroundColor: colorCode }} />
      </div>
    </div>
  );
};

export default EventItem;
