import React, { Fragment, useEffect, useState } from 'react';
import withLightBox from '../../../HOC/withLightBox';

interface IProps {
  imageGallery: any[],
}

const ArticlePopupImageGallery = (props: React.PropsWithChildren<IProps>) => {
  const {
    imageGallery,
  } = props;
  const [validValue, setValidValue] = useState(true);
  useEffect(() => {
    let validVal = false;
    for (let i = 0; i < imageGallery.length; i += 1) {
      if (imageGallery[i]) {
        validVal = true;
        break;
      }
    }
    setValidValue(validVal);
  }, []);

  if (!validValue) {
    return null;
  }

  return (
    <div className="grid gap-4 grid-cols-2 mb-6">
      {imageGallery.map((imgItem) => (
        <Fragment key={imgItem?.id}>
          {imgItem && (
            <a
              href={imgItem.high_path}
              className="overflow-hidden rounded-lg glightbox is-card-zoom ratio ratio-1x1"
              data-gallery="postimg_image_gallery"
            >
              <img src={imgItem.high_path} alt="Img Title" className="object-contain w-full img is-img-zoom" />
            </a>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default withLightBox(ArticlePopupImageGallery);
