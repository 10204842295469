import React, { useRef } from 'react';
import moment from 'moment';
import EventItem from '../../Common/EventItem';
import EmptyDataBlock from '../../Common/EmptyDataBlock';
import { getDateFromEvent, getTitleFromEvent } from '../../../helpers/commonHelper';

interface IProps {
  selectedDate: Date;
  events: any[];
  onViewEvent: (event: any) => void,
}

const CalendarEventListBlock = (props: React.PropsWithChildren<IProps>) => {
  const {
    selectedDate,
    events,
    onViewEvent,
  } = props;

  const containerRef = useRef(null);

  const dateTitle = moment(selectedDate).format('Do MMMM YYYY');
  return (
    <div className="lg:fixed lg:w-[19rem] 2xl:w-[19.5rem]">
      <div className="flex justify-between items-center mb-4 lg:pr-0">
        <h2 className="mr-4">{dateTitle}</h2>
      </div>
      {events.length === 0 && (
        <EmptyDataBlock
          title="No events are listed today"
          containerClass="p-4 md:pt-20"
          imgClass="w-40 h-40"
          titleContainerClass="pt-4"
          titleClass="text-xs font-bold"
        />
      )}
      {events.length > 0 && (
        <div ref={containerRef} className="flex justify-start space-y-3 overflow-x-auto pb-4 lg:rounded-lg mr-0 lg:pr-2 lg:-mr-2 lg:pb-4 lg:max-h-[calc(100vh_-_11rem)] overflow-y-scroll overflow-auto-themered-only flex-wrap">
          <div className="bg-c1 bg-c2 bg-c3 bg-c4 bg-c5 bg-c6 bg-c7 bg-c8 bg-c9 bg-c10 hidden" />
          {events.map((item) => (
            <EventItem
              key={item.id}
              title={getTitleFromEvent(item)}
              schoolName={item.division?.name || ''}
              startDate={getDateFromEvent(item, true)}
              endDate={getDateFromEvent(item, false)}
              isFullDay={item.is_all_day}
              location={item.location}
              description={item.description}
              findOurUrl={item.url || ''}
              colorCode={item.event_type?.color}
              parentRef={containerRef}
              containerClass="w-full"
              onViewEvent={() => onViewEvent(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CalendarEventListBlock;
