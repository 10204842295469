import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NewsCard from '../../../Common/NewsCard';
import { getWeeklyNews } from '../../../../actions/mspUser';

const NewsListBlock = () => {
  const [newsItems, setNewsItems] = useState<any[]>([]);

  useEffect(() => {
    getWeeklyNews(-1)
      .then((result: any) => {
        setNewsItems(result?.data || []);
      })
      .catch(() => {
      });
  }, []);

  return (
    <div className="snap-x snap-always scroll-px-4 overflow-x-auto pt-3 px-4 mb-3 pb-3 flex flex-row gap-3 lg:gap-5 lg:pb-3 lg:grid lg:grid-cols-2 xl:grid-cols-2">
      <div className="lg:hidden snap-start shrink-0 w-2/5">
        <div className="bg-navy rounded-2xl shadow-lg p-4 h-full flex flex-col">
          <div className="flex-auto">
            <h3 className="text-white">Weekly News</h3>
            <div className="text-white text-xs font-light pt-2">
              <p>Published on a Friday each week during term time.</p>
            </div>
          </div>
          <div className="mt-auto">
            <Link to="/news" className="underline underline-offset-4 text-white text-xs font-semibold">View All</Link>
          </div>
        </div>
      </div>
      {newsItems.slice(0, 6).map((item) => (
        <div key={item.id} className="snap-start shrink-0 w-2/5 lg:w-full">
          <NewsCard
            newsDate={item.date_of_publication}
            newsTime={item.time_of_publication}
            isRead={item.read}
            schoolName={item.division.name}
            routePath={`/newsdetail?id=${item.id}`}
          />
        </div>
      ))}
    </div>
  );
};

export default NewsListBlock;
