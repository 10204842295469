import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Brand from './Brand';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import NotificationButton from './NotificationButton';
import BreadcrumbButton from './BreadcrumbButton';
import { toggleRightSidebar, setRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import * as uiActionCreators from '../../../store/actioncreators/uiActionCreators';
import { RootState } from '../../../store/reducers';
import BasketButton from './BasketButton';
import SearchEdit from '../SearchBar/SearchEdit';
import { isUrlHavingSearchData } from '../../../helpers/commonHelper';
import FavouriteButton from './FavouriteButton';

interface IProps {
  role: string;
}

const Header = (props: React.PropsWithChildren<IProps>) => {
  const {
    role,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location?.pathname || '/';
  const isGallery = pathName.toLowerCase().includes('gallery');
  const isNotification = (role === 'guardian' || role === 'student 11+' || role === 'staff');
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );

  const debounceSearchHandler = debounce((searchText: string) => {
    const { setSearchText } = bindActionCreators(uiActionCreators, dispatch);
    setSearchText(searchText);
  }, 1000);

  const onChangeSearchInput = (searchTxt: string) => {
    debounceSearchHandler(searchTxt);
  };

  const onSearchFocus = () => {
    if (sidebarName !== UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR) {
      setRightSidebar(
        UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR,
        document,
        dispatch,
      );
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768
      && window.location.search
      && sidebarName !== UISidebarType.SEARCH_SIDEBAR
    ) {
      if (isUrlHavingSearchData(window.location.search)) {
        setTimeout(() => toggleRightSidebar(
          UISidebarType.SEARCH_SIDEBAR,
          document,
          dispatch,
        ), 200);
      }
    }
  }, []);

  return (
    <header className="main-header relative">
      <div className="p-3 pl-4  md:p-7 md:pl-4 flex items-center border-b border-themered fixed top-0 right-0 left-0 z-50 transition-all bg-white">
        <BreadcrumbButton />
        <Brand url="/home" />
        <div className="ml-auto  flex items-center">
          {!isGallery && (
            <div className="w-full md:block hidden">
              <div className="relative  focus-within:text-black group">
                <SearchEdit
                  editId="headerInput"
                  editClassName="h-10 input w-60 lg:w-80 pl-4 pr-11 bg-zinc-100 rounded-lg text-xs outline outline-0 outline-themered border border-zinc-100 hover:outline-1 focus:outline-1"
                  isLeftSearchIcon={false}
                  isSubmitSearchBtn={false}
                  closeBtnClass="h-4 w-4 mt-2 mr-2 text-black fill-black group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
                  searchBtnClass="h-10 w-10 text-black group bg-themered bg-opacity-10 group flex items-center justify-center  text-sm font-light rounded-md cursor-pointer"
                  onSearchEditFocusFn={onSearchFocus}
                  onSearchEditChangeFn={(searchTxt) => onChangeSearchInput(searchTxt)}
                />
              </div>
            </div>
          )}

          <div className="md:pl-9 pl-0 flex md:gap-4 gap-2 items-center w-full">
            {role !== 'vip' && <FavouriteButton />}
            <Link to="/my-account" className="h-10 w-10 text-black group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center  text-sm font-light rounded-md cursor-pointer">
              <GetSVG ID={SVGID.PROFILE_ICON} />
            </Link>
            {!isGallery && (
              <div className="md:hidden block">
                <button
                  type="button"
                  className="h-10 w-10 text-black group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
                  onClick={() => toggleRightSidebar(
                    UISidebarType.SEARCH_SIDEBAR,
                    document,
                    dispatch,
                  )}
                >
                  <GetSVG ID={SVGID.SEARCH_ICON} />
                </button>
              </div>
            )}
            {isGallery && (
              <div className="relative">
                <BasketButton />
              </div>
            )}

            {isNotification && (
              <div className="relative">
                <NotificationButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
