import React, { useEffect, useState } from 'react';
import cateringImg from '../../../assets/img/catering.svg';
import termImg from '../../../assets/img/term.svg';
import uniformImg from '../../../assets/img/bag.svg';
import { getPinnedItems } from '../../../actions/mspUser';

interface IProps {
  divisionId: number;
  isNTK: boolean;
  setNTK: (isNTK: boolean) => void;
}

const PinnedItems = (props: React.PropsWithChildren<IProps>) => {
  const {
    divisionId,
    isNTK,
    setNTK,
  } = props;

  const [pinned, setPinned] = useState<any[]>([]);
  const pinnedImages: any = {
    catering_menu: cateringImg, term_dates: termImg, uniform_lookbook: uniformImg,
  };
  const predefinedOrder = [
    'term_dates',
    'uniform_lookbook',
    'catering_menu',
  ];

  useEffect(() => {
    setPinned([]);
    if (divisionId > -1) {
      getPinnedItems(divisionId)
        .then((result: any) => {
          let pinData = result?.data || [];
          pinData = pinData.sort((a: any, b: any) => predefinedOrder.indexOf(a.key.toLowerCase())
            - predefinedOrder.indexOf(b.key.toLowerCase()));
          setPinned(pinData);
        })
        .catch(() => {
        });
    }
  }, [divisionId]);

  const onClickArchivedMessage = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    event.preventDefault();
    setNTK(!isNTK);
  };

  return (
    <div className="sticky top-28 z-10 bg-themebody -mx-4 py-4 sm:mx-0 sm:py-0">
      <h2 className="pb-4">Pinned</h2>
      <div className="flex flex-nowrap sm:flex-wrap snap-x snap-always scroll-px-4 overflow-x-auto px-0 py-0 bg-transparent">
        <div className="snap-start shrink-0 w-2/5 sm:w-full mb-3">
          <a
            href="#"
            className="block bg-themered shadow-lg text-white rounded-lg relative lg:mb-0 w-full h-full overflow-hidden group"
            onClick={onClickArchivedMessage}
          >
            <div className="relative z-2 py-3 px-3 sm:pl-5 sm:py-5 sm:pr-28">
              <h3 className="text-white">{!isNTK ? 'NTK Messages' : 'All Archived Messages'}</h3>
            </div>
          </a>
        </div>
        {pinned.map((item) => (
          <div key={item.id} className="snap-start shrink-0 w-2/5 sm:w-full ml-4 mb-3 sm:ml-0">
            <a
              target="_blank"
              rel="noreferrer"
              href={item.url}
              className="block bg-navy shadow-lg text-white rounded-lg relative lg:mb-0 h-full overflow-hidden group"
            >
              <div className="relative z-2 py-3 px-3 sm:pl-5 sm:py-6 sm:pr-28">
                <h3 className="text-white">{item.label}</h3>
                {item.detail && <p className="text-xs font-light pt-1 sm:text-base sm:leading-5 sm:block hidden">{item.detail}</p>}
              </div>
              <img
                src={pinnedImages[item.key]}
                alt={item.label}
                className="absolute -bottom-8 -right-4 h-20 transition-all sm:h-32 group-hover:scale-125 group-hover:h-36 group-focus:scale-125 group-focus-visible:scale-125"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
export default PinnedItems;
