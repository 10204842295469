import { applyMiddleware, createStore, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers/index';
import config from '../config/config';

const composeEnhancers = (config.ENV !== 'production'
  && typeof window !== 'undefined'
  // @ts-ignore
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk)),
);

export default store;
