import React from 'react';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  studentName: string;
  imageName: string;
  imagePath: string;
  price: string;
  isVideo: boolean;
  actionText: string;
  onActionClick: () => void;
}

const BasketItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    studentName,
    imagePath,
    imageName,
    price,
    isVideo,
    actionText,
    onActionClick,
  } = props;

  const onAnchorClick = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    event.preventDefault();
    onActionClick();
  };

  return (
    <div className="mb-3">
      <div className="post-card is-card-zoom flex flex-row h-full overflow-hidden rounded-lg lg:rounded-xl shadow-lg bg-white">
        <div className="post-card-head flex-shrink-0 relative overflow-hidden" tabIndex={-1}>
          <img className="object-cover min-h-[8rem] h-full w-32 is-img-zoom" src={imagePath} alt="student" />
          {isVideo && (
            <span className="post-play absolute inset-0 flex items-center justify-center transition ease-in-out delay-150 hover:scale-110  duration-300">
              <GetSVG ID={SVGID.VIDEOPLAY_ICON} />
            </span>
          )}
        </div>
        <div className="post-card-body p-4 flex-col flex w-full">
          <span className="text-xs">{studentName}</span>
          <h2 className="h4 pb-1 !leading-tigh">
            <div className="hover:text-themered focus:text-themered focus-within:text-themered break-words break-all">
              {imageName}
            </div>
          </h2>
          <div className="flex justify-between items-center mt-auto flex-wrap">
            <span className="text-sm font-bold">{`£${price}`}</span>
            <a href="#" onClick={onAnchorClick} className="underline underline-offset-4 text-themered text-sm font-semibold hover:text-black">{actionText}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketItem;
