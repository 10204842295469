import React, { useEffect, useState } from 'react';
import { calendarEventType, getCurrentUser, saveCalendarSettings } from '../../../actions/mspUser';

const CalendarSettings = () => {
  const [loading, setLoading] = useState(true);
  const [allSchoolEvents, setAllSchoolEvents] = useState(true);
  const [allSportEvents, setAllSportEvents] = useState(true);
  const [schoolEventType, setSchoolEventType] = useState<calendarEventType>(calendarEventType.ALL);
  const [sportEventType, setSportEventType] = useState<calendarEventType>(calendarEventType.ALL);

  useEffect(() => {
    getCurrentUser(true)
      .then((result: any) => {
        const events = result?.data?.settings?.calendar?.events || null;
        if (events) {
          setAllSchoolEvents(events.school?.is_enabled);
          setAllSportEvents(events.sport?.is_enabled);
          setSchoolEventType(events.school?.type);
          setSportEventType(events.sport?.type);
        }
        setTimeout(() => setLoading(false), 300);
      })
      .catch(() => {
      });
  }, []);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'school') {
      setAllSchoolEvents(!allSchoolEvents);
    } else if (type === 'sport') {
      setAllSportEvents(!allSportEvents);
    } else if (type === 'subschool') {
      setSchoolEventType(
        schoolEventType === calendarEventType.ALL
          ? calendarEventType.CHILD_ONLY
          : calendarEventType.ALL,
      );
    } else if (type === 'subsport') {
      setSportEventType(
        sportEventType === calendarEventType.ALL
          ? calendarEventType.CHILD_ONLY
          : calendarEventType.ALL,
      );
    }
  };

  useEffect(() => {
    if (!loading) {
      saveCalendarSettings(allSchoolEvents, allSportEvents, schoolEventType, sportEventType);
    }
  }, [allSchoolEvents, allSportEvents, schoolEventType, sportEventType]);

  if (loading) {
    return null;
  }

  return (
    <div className="border-b border-[#E1E1E1] mb-14">
      <h2 className="pb-4">Calendar Settings</h2>
      <div className="border-t border-[#E1E1E1] py-3">
        <div className="field spaced">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="checkbox hover:text-themered hover:underline underline-offset-4 pb-4">
            <input
              type="checkbox"
              id="schoolevents"
              name="schoolevents"
              checked={allSchoolEvents}
              onChange={(e) => onChangeInput(e, 'school')}
            />
            <span className="check" />
            <span className="control-label text-base font-bold text-navy">Show School events</span>
          </label>
          <div className="checkbox-group text-xs font-medium">
            <div className="control mb-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className={`radio hover:text-themered hover:underline underline-offset-4 ${
                  !allSchoolEvents ? 'pointer-events-none text-gray-500' : ''
                }`}
              >
                <input
                  type="radio"
                  id="sub_allschool"
                  checked={schoolEventType === calendarEventType.ALL}
                  disabled={!allSchoolEvents}
                  onChange={(e) => onChangeInput(e, 'subschool')}
                />
                <span className="check" />
                <span className="control-label text-sm font-medium">Show All Events</span>
              </label>
            </div>
            <div className="control flex-column mb-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className={`radio hover:text-themered hover:underline underline-offset-4 ${
                  !allSchoolEvents ? 'pointer-events-none text-gray-500' : ''
                }`}
              >
                <input
                  type="radio"
                  id="sub_childschool"
                  checked={schoolEventType === calendarEventType.CHILD_ONLY}
                  disabled={!allSchoolEvents}
                  onChange={(e) => onChangeInput(e, 'subschool')}
                />
                <span className="check" />
                <span className="control-label text-sm font-medium">Show for my child only</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-[#E1E1E1] py-3">
        <div className="field spaced">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="checkbox hover:text-themered hover:underline underline-offset-4 pb-4">
            <input
              type="checkbox"
              id="sportevents"
              name="sportevents"
              checked={allSportEvents}
              onChange={(e) => onChangeInput(e, 'sport')}
            />
            <span className="check" />
            <span className="control-label text-base font-bold text-navy">Show Sports</span>
          </label>
          <div className="checkbox-group text-xs font-medium">
            <div className="control mb-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className={`radio hover:text-themered hover:underline underline-offset-4 ${
                  !allSportEvents ? 'pointer-events-none text-gray-500' : ''
                }`}
              >
                <input
                  type="radio"
                  id="sub_allsport"
                  checked={sportEventType === calendarEventType.ALL}
                  disabled={!allSportEvents}
                  onChange={(e) => onChangeInput(e, 'subsport')}
                />
                <span className="check" />
                <span className="control-label text-sm font-medium">Show All Events</span>
              </label>
            </div>
            <div className="control flex-column mb-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className={`radio hover:text-themered hover:underline underline-offset-4 ${
                  !allSportEvents ? 'pointer-events-none text-gray-500' : ''
                }`}
              >
                <input
                  type="radio"
                  id="sub_childsport"
                  checked={sportEventType === calendarEventType.CHILD_ONLY}
                  disabled={!allSportEvents}
                  onChange={(e) => onChangeInput(e, 'subsport')}
                />
                <span className="check" />
                <span className="control-label text-sm font-medium">Show for my child only</span>
              </label>
              <div className="text-xs text-gray-500 pl-7">
                If your child is selected for sports and fixtures in teams
                other than their own year, you will not see those sports and
                fixtures in the calendar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarSettings;
