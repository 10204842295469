import React from 'react';

interface IProps {
  activeYears: any[],
  onChangeTagBox: (selectedYears: any[]) => void
}

const GalleryFilterTagBox = (props: React.PropsWithChildren<IProps>) => {
  const {
    activeYears,
    onChangeTagBox,
  } = props;

  const onResetTags = () => {
    onChangeTagBox([]);
  };

  const onSelectedYearChange = (year: any) => {
    const curYears = activeYears;
    const index = curYears.findIndex(item => item.id === year.id);
    if (index >= 0) {
      curYears.splice(index, 1);
      onChangeTagBox([...curYears]);
    }
  };

  return (
    <div className="flex justify-between items-center gap-2 w-full pr-1">
      <div className="shrink flex justify-start items-center gap-2 flex-wrap">
        {activeYears.map((item) => (
          <button
            key={item.id}
            type="button"
            className="flex items-center rounded-md text-xs font-bold p-2 border border-transparent cursor-pointer hover:bg-themered hover:bg-opacity-5 hover:border-themered"
          >
            <svg className="i-svg fill-black h-4 w-4" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => onSelectedYearChange(item)}>
              <path d="M2 2L9.9429 10" stroke="#D10027" strokeWidth="2.5" strokeLinecap="round" />
              <path d="M2 10L9.9429 2" stroke="#D10027" strokeWidth="2.5" strokeLinecap="round" />
            </svg>
            <span className="pl-2 hover:text-themered focus:text-themered">{item.name}</span>
          </button>
        ))}
      </div>
      {activeYears.length > 0 && (
        <div className="flex-none">
          <button
            type="button"
            onClick={onResetTags}
            className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
          >
            Clear All
          </button>
        </div>
      )}
    </div>
  );
};

export default GalleryFilterTagBox;
