import { UIActionType, UISidebarType } from '../actiontypes/uiActionTypes';
import { UIAction } from '../actions/uiActions';
import IUiStates from '../../interfaces/uiStates';

const initialState: IUiStates = {
  sidebarName: UISidebarType.CLOSE_SIDEBAR,
  searchText: '',
  searchLoading: false,
  basketItemCount: 0,
  toggleLightbox: false,
  favouriteChangeItem: 0,
  archiveChangeItem: 0,
  searchItemHistory: [],
};

const reducer = (
  state: IUiStates = initialState,
  action: UIAction,
): IUiStates => {
  switch (action.type) {
    case UIActionType.SET_SIDEBAR:
      return { ...state, sidebarName: action.payload };
    case UIActionType.SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    case UIActionType.SET_SEARCH_LOADING:
      return { ...state, searchLoading: action.payload };
    case UIActionType.SET_BASKET_ITEM_COUNT:
      return { ...state, basketItemCount: action.payload };
    case UIActionType.SET_TOGGLE_LIGHTBOX:
      return { ...state, toggleLightbox: action.payload };
    case UIActionType.SET_FAVOURITECHANGE_ITEM:
      return { ...state, favouriteChangeItem: action.payload };
    case UIActionType.SET_ARCHIVECHANGE_ITEM:
      return { ...state, archiveChangeItem: action.payload };
    case UIActionType.SET_SEARCHHISTORY_ITEM:
      return { ...state, searchItemHistory: [...state.searchItemHistory, action.payload] };
    default:
      return state;
  }
};

export default reducer;
