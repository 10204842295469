import { Dispatch } from 'redux';
import { UserDataAction } from '../actions/userDataActions';
import { UserDataActionType } from '../actiontypes/userDataActionTypes';
import IUserDataStates from '../../interfaces/userDataStates';

export const setUserData = (userData: IUserDataStates) => (dispatch: Dispatch<UserDataAction>) => {
  dispatch({
    type: UserDataActionType.SET_USERDATA,
    payload: userData,
  });
};
