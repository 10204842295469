import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import FavouriteBarItem from './FavouriteBarItem';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { getFavouriteItems } from '../../../actions/mspUser';
import Loader from '../../Common/Loader';
import OverlayBlock from '../../Common/overlayBlock';

const FavouriteBar = () => {
  const dispatch = useDispatch();
  const favBarRef = useRef(null);
  const [favourites, setFavourites] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);

  const loadFunc = (pageToFetch: number) => {
    getFavouriteItems(pageToFetch)
      .then((result: any) => {
        setFavourites(pageToFetch === 1 ? [...result.data] : [...favourites, ...result.data]);

        const totalPages = result.meta?.last_page;
        const totalFetchedPage = result.meta?.current_page;
        setHasMore(totalPages !== totalFetchedPage);
        setCurPage(totalFetchedPage);
      })
      .catch(() => {
        setHasMore(false);
      });
  };

  useEffect(() => {
    const { current } = favBarRef;
    if (current) {
      setTimeout(() => {
        // @ts-ignore
        current.classList.add('!right-0');
      }, 100);
    }

    loadFunc(1);
  }, []);

  const onCloseSidebar = () => {
    toggleRightSidebar(
      UISidebarType.FAVOURITE_SIDEBAR,
      document,
      dispatch,
    );
  };

  return (
    <>
      <div
        id={UISidebarType.FAVOURITE_SIDEBAR}
        className="sidebar-popup fixed z-[39] bottom-0 top-16 md:top-24 transition-all duration-300 ease-in-out bg-white w-full drop-shadow-lg max-h-full md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem]  overflow-y-auto -right-full"
        ref={favBarRef}
      >
        <div className="pt-6 px-4 sticky bg-white z-10 right-0 left-0 top-0 border-b">
          <div className="flex space-x-2 items-center">
            <button
              type="button"
              className="h-8 w-8 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center  text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseSidebar}
            >
              <GetSVG ID={SVGID.LEFTARROW_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </button>
            <h2 className="h1">Favourites</h2>
          </div>
          <div className="font-bold pl-4 pt-6" />
        </div>
        <InfiniteScroll
          dataLength={favourites.length}
          next={() => loadFunc(curPage + 1)}
          scrollThreshold="20px"
          scrollableTarget={UISidebarType.FAVOURITE_SIDEBAR}
          hasMore={hasMore}
          loader={<Loader containerClass="ml-4 mt-6" label="Favourites Loading..." />}
        >
          {favourites.map((item) => (
            <FavouriteBarItem
              key={`${item.id}_${item.title}`}
              title="Notices"
              id={item.id}
              description={item.title}
              approveDate={item.calculated_date?.split(' ')[0]}
              itemRoutePath={`/need-to-know?id=${item.id}`}
              favourite={item.favourite}
              onClickFavourite={() => loadFunc(1)}
              SVGId={SVGID.INBOX_SIDEBAR}
            />
          ))}
        </InfiniteScroll>
      </div>
      <OverlayBlock
        id="notification_popup_mask"
        onClickOverlay={onCloseSidebar}
      />
    </>
  );
};

export default FavouriteBar;
