import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import EventListBlock from './EventListBlock';
import { getCalendarEvents } from '../../../../actions/mspUser';
import { formatDate, getDateFromEvent } from '../../../../helpers/commonHelper';
import ExternalSportsBtnLinks from './ExternalSportsBtnLinks';

interface IProps {
  onViewEvent: (event: any, selectedDate: Date) => void,
}

const CalendarEventSection = (props: React.PropsWithChildren<IProps>) => {
  const {
    onViewEvent,
  } = props;

  const curDate = new Date();
  const firstDay = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
  const lastDay = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);
  const [value, onChange] = useState<Date>(new Date());
  const [eventList, setEventList] = useState<any>(null);

  const getNumber = (data: string) => {
    if (!data) {
      return -1;
    }
    return parseInt(data, 10);
  };

  const fetchMonthEvents = (month: string) => {
    getCalendarEvents(month)
      .then((result: any) => {
        const lastDayNumber = lastDay.getDate();
        const monthEvents: any = {};
        for (let i = 1; i <= lastDayNumber; i += 1) {
          monthEvents[i] = result?.data.filter((item: any) => {
            const sDay: number = getNumber(item.start_date);
            const eDay: number = getNumber(item.end_date);
            if ((eDay > -1 && sDay <= i && eDay >= i) || (eDay === -1 && sDay === i)) {
              return true;
            }
            return false;
          });
        }

        for (let i = 1; i <= lastDayNumber; i += 1) {
          monthEvents[i].sort((a: any, b: any) => {
            if (a.is_all_day) {
              return -1;
            }
            if (!a.is_all_day && !b.is_all_day) {
              const aDate = formatDate(getDateFromEvent(a, true), 'DD-MM-YYYY HH:mm:ss', 'HH:mm:ss');
              const bDate = formatDate(getDateFromEvent(b, true), 'DD-MM-YYYY HH:mm:ss', 'HH:mm:ss');
              return aDate.localeCompare(bDate);
            }
            return 1;
          });
        }
        setEventList(monthEvents);
      })
      .catch(() => {
      });
  };

  const getEventForDay = (date: Date) => {
    if (!eventList) {
      return [];
    }
    const curDay: number = getNumber(moment(date).format('DD-MM-yyyy'));
    if (!eventList[curDay]) {
      return [];
    }
    // const cDate = moment(date).format('DD-MM-yyyy');
    return eventList[curDay]; // .filter((item: any) => item.start_date.includes(cDate));
  };

  const setTileContent = ({ date, view }: {date: Date, view: string}) => {
    if (view === 'month') {
      const events = getEventForDay(date);
      return (
        <>
          <div className="is-day-xs">{date.toLocaleString('default', { weekday: 'short' })}</div>
          {events.length > 0 && <span className="is-event">.</span>}
        </>
      );
    }
    return null;
  };

  const setTileClassName = ({ date, view }: {date: Date, view: string}) => {
    if (view === 'month') {
      const inDay: number = date.getDate();
      if (inDay > 4 && inDay < 15) {
        return ''; // 'is-break';
      }
    }
    return null;
  };

  useEffect(() => {
    const cDate = new Date();
    const month = moment(new Date(cDate.getFullYear(), cDate.getMonth(), 1)).format('MM-yyyy');
    fetchMonthEvents(month);
  }, []);

  return (
    <>
      <div className="md:hidden block">
        <div className="flex justify-between items-center mb-2">
          <h2>Calendar</h2>
          <Link
            to="/calendar"
            className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
          >
            View All
          </Link>
        </div>
      </div>
      <div className="md:flex justify-between items-center mb-4 hidden ">
        <h2>Calendar</h2>
        <Link
          to="/calendar"
          className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
        >
          View All
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 gap-5 mb-0 md:mb-0">
        <div className="home-calendar text-center overflow-x-auto pb-3 mb-3">
          <Calendar
            onChange={onChange}
            value={value}
            minDate={firstDay}
            maxDate={lastDay}
            showNavigation={false}
            tileContent={setTileContent}
            tileClassName={setTileClassName}
          />
        </div>
        <EventListBlock
          selectedDate={value}
          events={getEventForDay(value)}
          onViewEvent={(event: any) => onViewEvent(event, value)}
        />
      </div>
      <ExternalSportsBtnLinks />
    </>
  );
};

export default CalendarEventSection;
