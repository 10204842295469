import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import GetSVG, { SVGID } from './GetSVG';
import { toggleArchiveNotice } from '../../actions/mspUser';
import Loader from './Loader';
import * as uiActionCreators from '../../store/actioncreators/uiActionCreators';

interface IProps {
  contentId: number;
  archive: number;
  containerClass?: string;
  archiveIconClass?: string;
  onChangeArchive?: (archive: number) => void;
}

const ArchiveContent = (props: React.PropsWithChildren<IProps>) => {
  const {
    contentId,
    archive,
    containerClass,
    archiveIconClass,
    onChangeArchive,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setArchiveChangeItem } = bindActionCreators(uiActionCreators, dispatch);

  const onClickArchive = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    toggleArchiveNotice(contentId.toString())
      .then((res: any) => {
        if (onChangeArchive) {
          onChangeArchive(res.archived);
        }
        setArchiveChangeItem(contentId);
        setTimeout(() => setArchiveChangeItem(0), 200);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className={containerClass}>
        <Loader containerClass="justify-center w-8 h-8 mb-1" />
      </div>
    );
  }

  return (
    <button type="button" onClick={onClickArchive} className={containerClass}>
      <GetSVG
        ID={archive === 1 ? SVGID.UNARCHIVE_ICON : SVGID.ARCHIVE_ICON}
        svgClass={`fill-themered ${archiveIconClass}`}
      />
    </button>
  );
};

ArchiveContent.defaultProps = {
  containerClass: '',
  archiveIconClass: 'h-6 w-6',
  onChangeArchive: null,
};

export default ArchiveContent;
