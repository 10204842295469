import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import OverlayBlock from '../../Common/overlayBlock';
import { RootState } from '../../../store/reducers';
import printPartnerImg from '../../../assets/img/partner-img.svg';

interface IProps {
  imagePath: string;
  imageName: string;
  mediaCode: string;
  studentName: string;
  onClose: () => void;
}

const PrintImageSidebar = (props: React.PropsWithChildren<IProps>) => {
  const {
    imagePath,
    imageName,
    mediaCode,
    studentName,
    onClose,
  } = props;

  const [copied, setCopied] = useState(false);
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );

  const onCloseBar = () => {
    const el = document.getElementById('print-gallery-sidebar');
    if (el) {
      el.classList.remove('!right-0');
      // el.classList.remove('!z-30');
    }
    setTimeout(() => {
      onClose();
      if (!sidebarName) {
        document.body.classList.remove('overflow-sidebar');
      }
    }, 200);
  };

  useEffect(() => {
    if (sidebarName) {
      onCloseBar();
    }
  }, [sidebarName]);

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    setTimeout(() => {
      const el = document.getElementById('print-gallery-sidebar');
      if (el) {
        el.classList.add('!right-0');
        // el.classList.add('!z-30');
      }
    }, 100);
  }, []);

  const refName = mediaCode; // imageName.substring(0, imageName.lastIndexOf('.'));
  return (
    <>
      <div
        id="print-gallery-sidebar"
        className="sidebar-popup flex flex-col overflow-hidden fixed z-[39] bottom-0 top-16 md:top-24 transition-all duration-300 ease-in-out bg-themebody-sidebar w-full drop-shadow-lg max-h-full md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem] overflow-y-auto -right-full"
      >
        <div className="py-4 px-4 sticky z-20 right-0 left-0 top-0 bg-themebody-sidebar">
          <div className="flex justify-between items-center">
            <h2 className="h3">Order print from</h2>
            <div>
              <img src={printPartnerImg} alt="School snaps" />
            </div>
          </div>
        </div>
        <div className="card-head border-b-2 border-gray-300 p-5 relative bg-white">
          <div className="top-0 right-0 sticky z-10 text-right flex justify-end">
            <button
              type="button"
              className="absolute -top-2 -right-2 h-8 w-8 text-black bg-white bg-opacity-70 group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseBar}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </button>
          </div>
          <div className="block text-center">
            <a
              href={imagePath}
              className="inline-block relative mx-auto transition-all duration-500 ease-in-out hover:scale-105 focus:scale-105 overflow-hidden glightbox"
              data-gallery="gallery-download"
              data-glightbox="title:Katie"
            >
              <img src={imagePath} className="overflow-hidden rounded-xl mx-auto max-w-full max-h-72 object-cover" alt="student" />
            </a>
          </div>
        </div>
        <div className="card-body p-5 flex-auto">
          <div className="flex flex-wrap gap-3 items-center justify-between pb-7">
            <div className="text-base font-bold">{studentName}</div>
            <div className="text-xs break-words">{imageName}</div>
          </div>
          <div className="text-sm text-center pb-11 font-bold">
            <div className="flex items-center mx-auto mb-8">
              <div className="flex items-center mx-auto">
                <input
                  value={refName}
                  className="h-10 w-48 bg-white text-lg rounded-l-xl flex items-center justify-center px-3 whitespace-nowrap font-bold text-themered select-all lg:tracking-widest"
                  id="refname"
                  readOnly
                />
                <CopyToClipboard text={refName} onCopy={() => setCopied(true)}>
                  <button
                    type="button"
                    className={`flex justify-center items-center bg-navy h-10 w-10 text-center rounded-r-xl transition-all hover:bg-themered ${copied ? '!bg-green-600' : ''}`}
                    id="copy"
                    title="Copy to clipboard"
                  >
                    <GetSVG ID={SVGID.COPY_ICON} svgClass="i-svg h-4 w-4" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <p className="pb-4">
              You can order prints of this photo from School Snaps. Please use the
              reference number above to order your prints. School Snaps provides
              Thomas’s branded printing.
            </p>
            <p>
              Thomas does not profit from School Snaps sales, the transaction is
              between you and School Snaps.
            </p>
          </div>
        </div>
        <div className="mt-auto px-5 mb-4">
          <a
            href="https://www.schoolsnaps.co.uk"
            target="_blank"
            rel="noreferrer"
            className="btn btn-red w-full"
          >
            Visit the School Snaps Website
          </a>
        </div>
      </div>
      <OverlayBlock
        id="gallery_popup_mask"
        onClickOverlay={onCloseBar}
      />
    </>
  );
};

export default PrintImageSidebar;
