import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import { getShareContent } from '../../../actions/mspUser';
import Brand from '../../Layout/Header/Brand';
import NoticeDetail from '../Inbox/NoticeDetail';
import ArticleDetail from '../News/WeeklyNewsView/ArticleDetail';
import NotFound from '../NotFound';

const ShareContentPreview = (props: RouteComponentProps) => {
  const [content, setContent] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    // @ts-ignore
    const { match: { params: { token } } } = props;
    if (token) {
      getShareContent(token)
        .then((result: any) => {
          setContent(result?.content);
          setContentType(result?.type);
        })
        .catch(() => {
          setNotFound(true);
        });
    } else {
      setNotFound(true);
    }
  }, []);

  // @ts-ignore
  const isArticle = () => (contentType && contentType === 'article');

  const header = () => (
    <header className="main-header relative">
      <div className="p-4 pl-4  md:p-8 md:pl-12 flex items-center border-b border-themered fixed top-0 right-0 left-0 z-50 transition-all bg-white">
        <Brand url="#" />
      </div>
    </header>
  );

  if (notFound) {
    return (
      <>
        {header()}
        <NotFound showBackHomeBtn={false} />
      </>
    );
  }

  if (!content) {
    return null;
  }

  return (
    <>
      <GetMetaHeader page={isArticle() ? PAGEID.PAGE_ARTICLE : PAGEID.PAGE_NEEDTOKNOW} />
      <>
        {header()}
        <div className={`relative ${isArticle() ? 'mt-14 md:mt-24' : 'mt-20 md:mt-28'}`}>
          <div className="pb-4 md:ml-[20%] md:mr-[20%] ml-0 mr-0">
            {isArticle() && (
              <ArticleDetail
                article={content}
                bodyContainerClass="bg-white drop-shadow-[2px_4px_6px_rgba(0,0,0,1)] rounded-[30px] ml-[4%] mr-[4%] mt-[4%] mb-[4%]"
                showShareBtn={false}
              />
            )}
            {!isArticle() && (
              <div className="pt-6 bg-white drop-shadow-[2px_4px_6px_rgba(0,0,0,1)] rounded-[30px] ml-[4%] mr-[4%] mb-[4%]">
                <NoticeDetail
                  notice={content}
                  showShareBtn={false}
                  showFavArchiveBtn={false}
                />
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default withRouter(ShareContentPreview);
