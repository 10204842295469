import Home from './components/Pages/Home/Home';
import Inbox from './components/Pages/Inbox/Inbox';
import Highlights from './components/Pages/Highlights/Highlights';
import News from './components/Pages/News/NewsLanding/NewsLanding';
import NewsDetail from './components/Pages/News/WeeklyNewsView/WeeklyNewsView';
import CalendarView from './components/Pages/Calendar/CalendarView';
import Gallery from './components/Pages/Gallery/Gallery';
import MyAccount from './components/Pages/MyAccount/MyAccount';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/need-to-know',
    component: Inbox,
  },
  {
    path: '/highlights',
    component: Highlights,
  },
  {
    path: '/news',
    component: News,
  },
  {
    path: '/newsdetail',
    component: NewsDetail,
  },
  {
    path: '/calendar',
    component: CalendarView,
  },
  {
    path: '/gallery',
    component: Gallery,
  },
  {
    path: '/my-account',
    component: MyAccount,
  },
];

export default routes;
