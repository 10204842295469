export enum UIActionType {
  SET_SIDEBAR = 'set_sidebar',
  SET_SEARCH_TEXT = 'set_searchtext',
  SET_SEARCH_LOADING = 'set_searchloading',
  SET_BASKET_ITEM_COUNT = 'set_basketitemcount',
  SET_TOGGLE_LIGHTBOX = 'set_togglelightbox',
  SET_FAVOURITECHANGE_ITEM = 'set_favouritechangeitem',
  SET_ARCHIVECHANGE_ITEM = 'set_archivechangeitem',
  SET_SEARCHHISTORY_ITEM = 'set_searchhistoryitem'
}

export enum UISidebarType {
  CLOSE_SIDEBAR = '',
  FAVOURITE_SIDEBAR = 'favourite_sidebar',
  NOTIFICATION_SIDEBAR = 'notification_sidebar',
  VIEWCALENDAREVENT_SIDEBAR = 'viewecalendarvent_sidebar',
  SEARCH_SIDEBAR = 'search_sidebar',
  SEARCH_WITHOUTINPUT_SIDEBAR = 'search_withoutinput_sidebar',
  BASKET_SIDEBAR = 'basket_sidebar',
}
