import React from 'react';
import { Link } from 'react-router-dom';
import NewsListBlock from './NewsListBlock';

const LatestNewsSection = () => (
  <>
    <div className="justify-between items-center mb-2 md:flex hidden">
      <h2>Latest News</h2>
      <Link
        to="/news"
        className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
      >
        View All
      </Link>
    </div>
    <div className="-mx-4 mb-6 lg:mb-0">
      <NewsListBlock />
    </div>
  </>
);

export default LatestNewsSection;
