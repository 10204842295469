const local = require('./env/local');
const alpha = require('./env/alpha');
const uat = require('./env/uat');
const production = require('./env/production');

const env = process.env.REACT_APP_NODE_ENV || 'alpha';
const defaults = {
  ENV: env,
  MSP_URLS: {
    Battersea: 'https://bat.msp.thomas-s.co.uk',
    Clapham: 'https://cla.msp.thomas-s.co.uk',
    Fulham: 'https://ful.msp.thomas-s.co.uk',
    Kensington: 'https://ken.msp.thomas-s.co.uk',
    Kindergarten: 'https://kin.msp.thomas-s.co.uk',
    'Putney Vale': 'https://pv.msp.thomas-s.co.uk',
  },
};

const envConfig = {
  local,
  production,
  alpha,
  uat,
}[env];

const config = Object.assign(defaults, envConfig);
export default config;
