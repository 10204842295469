import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { getNotificationUnseenCount } from '../../../actions/mspUser';

const NotificationButton = () => {
  const dispatch = useDispatch();
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );
  const [totalUnreadNoti, setTotalUnreadNoti] = useState(0);
  const [isNotificationSide, setIsNotificationSide] = useState(false);

  const fetchUnseenCount = () => {
    getNotificationUnseenCount()
      .then((result: any) => {
        setTotalUnreadNoti(result);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    fetchUnseenCount();
  }, []);

  useEffect(() => {
    setIsNotificationSide(UISidebarType.NOTIFICATION_SIDEBAR === sidebarName);
    if (isNotificationSide && totalUnreadNoti > 0) {
      fetchUnseenCount();
    }
  }, [sidebarName]);

  let remWidth: string = '0.875rem';
  switch (totalUnreadNoti.toString().length) {
    case 2:
      remWidth = '1.25rem';
      break;
    case 3:
      remWidth = '1.5rem';
      break;
    case 4:
      remWidth = '1.75rem';
      break;
    case 5:
      remWidth = '2.25rem';
      break;
    default:
      remWidth = '0.875rem';
      break;
  }

  return (
    <button
      id="not_parent_btn"
      type="button"
      className="h-10 w-10 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer"
      onClick={() => toggleRightSidebar(UISidebarType.NOTIFICATION_SIDEBAR, document, dispatch)}
    >
      <span id="notification_btn">
        <GetSVG ID={SVGID.NOTIFICATION_ICON} />
        {totalUnreadNoti > 0 && (
          <>
            <span
              className="absolute bottom-0.5 right-1.5 h-3.5 tracking-tighter border-2 border-themered rounded-full bg-themered text-white flex items-center justify-center font-semibold"
              style={{ fontSize: '10px', width: remWidth }}
            >
              {totalUnreadNoti}
            </span>
            <span className="animate-ping h-3.5 bg-themered/50 rounded-full absolute bottom-0.5 right-1.5" style={{ width: remWidth }} />
          </>
        )}
      </span>

      <span id="not_close_btn" className="hidden">
        <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-5 w-5 fill-white hover:fill-themered group-hover:fill-themered" />
      </span>
    </button>
  );
};

export default NotificationButton;
