import React from 'react';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { toggleSidebar } from '../../../helpers/uiHelper';

const BreadcrumbButton = () => (
  <button
    type="button"
    className="h-10 w-10 mr-1 sm:mr-3 text-black group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center px-2 py-2 text-sm font-light rounded-md cursor-pointer --jbi-mobile-aside-toggle"
    onClick={() => toggleSidebar(document)}
  >
    <span className="i-expanded">
      <GetSVG ID={SVGID.BREADCRUMB_OPEN} />
      <GetSVG ID={SVGID.BREADCRUMB_CLOSE} />
    </span>
  </button>
);

export default BreadcrumbButton;
