import React, { useEffect, ComponentProps } from 'react';
// @ts-ignore
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as uiActionCreators from '../../store/actioncreators/uiActionCreators';

interface ILightBox {}
let lightbox: any = null;
const withLightBox = <T extends ILightBox>(WrappedComponent: React.ComponentType<T>) => {
  const ComponentWithExtraInfo = (props:ComponentProps<any>) => {
    const dispatch = useDispatch();
    const { setToggleLightbox } = bindActionCreators(uiActionCreators, dispatch);

    useEffect(() => {
      if (!lightbox) {
        lightbox = GLightbox({
          touchNavigation: true,
          loop: false,
          autoplayVideos: false,
          plyr: {
            config: {
              vimeo: {
                h: '7f7ed8d6de',
              },
            },
          },
        });
      } else {
        lightbox.reload();
      }

      // target: any
      lightbox.on('open', () => {
        setToggleLightbox(true);
      });

      lightbox.on('close', () => {
        setToggleLightbox(false);
      });

      lightbox.on('slide_before_load', (data: any) => {
        // data is an object that contain the following
        const { slideConfig } = data;
        if (slideConfig && slideConfig.type === 'video') {
          const curHref = slideConfig?.href || '';
          const matchParams = curHref.match(/vimeo\.com\/([0-9]*)\/([a-zA-Z0-9]*)/);
          if (matchParams && matchParams.length >= 3) {
            const hashVal = matchParams[2];
            if (lightbox.customOptions?.plyr?.config?.vimeo?.h) {
              lightbox.customOptions.plyr.config.vimeo.h = hashVal;
            }
            if (lightbox.settings?.plyr?.config?.vimeo?.h) {
              lightbox.settings.plyr.config.vimeo.h = hashVal;
            }
          }
        }
      });
    }, []);

    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <WrappedComponent {...props} />;
  };
  return ComponentWithExtraInfo;
};

export default withLightBox;
