import React, { useEffect, useState } from 'react';
import GetSVG, { SVGID } from '../../../Common/GetSVG';

interface IProps {
  activeYears: string[],
  activeSubjects: string[],
  allYears: string[],
  allSubjects: string[],
  onClose: () => void,
  onChangeFilter: (selectedYears: string[], selectedSubjects: string[]) => void
}

const ArticleFilterPopup = (props: React.PropsWithChildren<IProps>) => {
  const {
    activeYears,
    activeSubjects,
    allYears,
    allSubjects,
    onClose,
    onChangeFilter,
  } = props;

  const [selectedYears, setSelectedYears] = useState<string[]>([...activeYears]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([...activeSubjects]);

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('filter-modal')!.classList.add('active');
    }, 100);
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('filter-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const onSelectedYearChange = (year: string) => {
    const curYears = selectedYears;
    const index = curYears.indexOf(year);
    if (index < 0) {
      curYears.push(year);
    } else {
      curYears.splice(index, 1);
    }
    setSelectedYears([...curYears]);
  };

  const onSelectedSubjectChange = (subject: string) => {
    const curSubjects = selectedSubjects;
    const index = curSubjects.indexOf(subject);
    if (index < 0) {
      curSubjects.push(subject);
    } else {
      curSubjects.splice(index, 1);
    }
    setSelectedSubjects([...curSubjects]);
  };

  const onResetFilter = () => {
    setSelectedYears([]);
    setSelectedSubjects([]);
  };

  const onApplyFilter = () => {
    onChangeFilter(selectedYears, selectedSubjects);
    onClosePopup();
  };

  return (
    <>
      <div id="filter-modal" className="modal">
        <div className="modal-card">
          <div className="modal-card-body max-w-3xl">
            <div className="modal-card-head text-right relative">
              <button
                type="button"
                className="absolute md:relative right-0 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
                onClick={onClosePopup}
              >
                <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
              </button>
            </div>
            <div className="modal-card-info">
              {allYears.length > 0 && (
                <>
                  <h2 className="h3 mb-2">Year</h2>
                  <div className="filter-items relative overflow-y-auto max-h-screen pb-6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                    {allYears.map((item) => (
                      <button
                        key={item}
                        type="button"
                        className={`btn-filter-item toggle-filter ${selectedYears.includes(item) ? 'selected' : ''}`}
                        onClick={() => onSelectedYearChange(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </>
              )}
              {allSubjects.length > 0 && (
                <>
                  <h2 className="h3 mb-2">Subject</h2>
                  <div className="filter-items relative overflow-y-auto max-h-screen pb-6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                    {allSubjects.map((item) => (
                      <button
                        key={item}
                        type="button"
                        className={`btn-filter-item toggle-filter ${selectedSubjects.includes(item) ? 'selected' : ''}`}
                        onClick={() => onSelectedSubjectChange(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="modal-card-foot pt-4 grid grid-cols-2 gap-4">
              <button type="button" className="btn-link" onClick={onResetFilter}>Reset Filters</button>
              <button type="button" className="btn btn-red" onClick={onApplyFilter}>Apply</button>
            </div>
          </div>
        </div>
        <div className="modal-background" />
      </div>
    </>
  );
};

export default ArticleFilterPopup;
