import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Masonry } from '@mui/lab';
import StudentSelection, { studentData } from '../../Common/StudentSelection';
import GalleryImage from './GalleryImage';
import GallerySidebar from './GallerySidebar';
import ReportImageModal from './ReportImageModal';
import Loader from '../../Common/Loader';
import DownloadImageModal from './DownloadImageModal';
import AddToBasketModal from './AddToBasketModal';
import { RootState } from '../../../store/reducers';
import BasketSidebar from './BasketSidebar';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import EmptyDataBlock from '../../Common/EmptyDataBlock';
import { reportGalleryImage, getGallery, getAcademicYear } from '../../../actions/mspUser';
import { getBasket, setBasket } from '../../../actions/AuthUtil';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import PrintImageSidebar from './PrintImageSidebar';
import { isVideoFile } from '../../../helpers/commonHelper';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import GalleryFilterPopup from './GalleryFilterPopup';
import GalleryFilterTagBox from './GalleryFilterTagBox';

const Gallery = () => {
  const location = useLocation();
  // @ts-ignore
  const selInitTab = location?.state?.tab || 1;
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(
    (selInitTab < 1 || selInitTab > 3) ? 1 : selInitTab,
  );
  const [galleryMedias, setGalleryMedias] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [printImage, setPrintImage] = useState<any>(null);
  const [isImageReported, setIsImageReported] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showAddToBasketModal, setShowAddToBasketModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<studentData | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [allYears, setAllYears] = useState<any[]>([]);
  const [activeYears, setActiveYears] = useState<any[]>([]);

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const sidebarName: string = useSelector(
    (state: RootState) => state.ui.sidebarName,
  );

  const tabs = [
    {
      id: 1, name: 'Everyday',
    },
    {
      id: 2, name: 'Formal',
    },
    {
      id: 3, name: 'Productions',
    },
  ];

  const onItemChange = (item: studentData) => {
    setSelectedStudent(item);
  };

  const onTabClick = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>, tab: number) => {
    event.preventDefault();
    setSelectedTab(tab);
  };

  /* const onScroll = () => {
    const height = window.innerHeight + document.documentElement.scrollTop;
    const scrollingHeight = document?.scrollingElement?.scrollHeight || 0;
    if (height >= scrollingHeight - 1) {
      setIsLoading(true);
      setTimeout(() => {
        const newMedia = [...medias];
        setCurData((curState) => [...curState, ...newMedia]);
        setIsLoading((curState) => !curState);
      }, 4000);
    }
  };

  useEffect(() => {
    // window.addEventListener('scroll', onScroll, { passive: false });
    // return () => window.removeEventListener('scroll', onScroll);
  }, [curData]); */

  const onClickImage = (id: number) => {
    const img = galleryMedias.filter((item) => item.id === id);
    if (img && img.length > 0) {
      setSelectedImage(img[0]);
    }
  };

  const onClickReportImage = () => {
    if (selectedImage && selectedStudent) {
      reportGalleryImage(selectedStudent.id, selectedImage.id)
        .then(() => {
          setIsImageReported(true);
        })
        .catch(() => {
        });
    }
  };

  const onClickDownloadImage = () => {
    if (selectedImage) {
      if (selectedTab === 1 || selectedTab === 3) {
        setShowDownloadModal(true);
        /* forceDownloadFile(selectedImage.high_path, selectedImage.file_name)
          .then(() => {
          })
          .catch(e => {
            toast.error(e?.message);
          }); */
      } else {
        const basketObj = getBasket();
        const filterItem = basketObj.filter((item: any) => item.id === selectedImage.id);
        if (filterItem && filterItem.length > 0) {
          toggleRightSidebar(UISidebarType.BASKET_SIDEBAR, document, dispatch);
        } else {
          setShowAddToBasketModal(true);
        }
        // setShowDownloadModal(true);
      }
    }
  };

  const onClickPrintImage = () => {
    if (selectedImage) {
      setPrintImage(selectedImage);
      setSelectedImage(null);
    }
  };

  const onClickAddToBasket = () => {
    if (selectedImage) {
      const basketObj = getBasket();
      selectedImage.studentName = selectedStudent?.studentName || '';
      selectedImage.studentId = selectedStudent?.studentId || 0;
      basketObj.push(selectedImage);
      setBasket(basketObj, dispatch);
      setSelectedImage(null);
    }
  };

  const loadFunc = (pageToFetch: number, callReason: number) => {
    // setIsLoading(true);
    if (selectedStudent && selectedTab) {
      if (pageToFetch <= 1 && callReason === 1) {
        return;
      }
      setLoading(true);
      const yearIds = activeYears.map(item => item.id);
      getGallery(selectedStudent.id, selectedTab, yearIds, pageToFetch)
        .then((res: any) => {
          if (pageToFetch === 1) {
            setGalleryMedias([...res.data]);
          } else {
            setGalleryMedias([...galleryMedias, ...res.data]);
          }

          const totalPages = res.meta?.last_page;
          const totalFetchedPage = res.meta?.current_page;
          setHasMore(totalPages !== totalFetchedPage);
          setCurPage(totalFetchedPage);
          setLoading(false);
        })
        .catch(() => {
          setHasMore(false);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setGalleryMedias([]);
    setHasMore(true);
    setCurPage(0);
    setLoading(true);
    loadFunc(1, 0);
  }, [selectedStudent, selectedTab, activeYears]);

  useEffect(() => {
    getAcademicYear()
      .then((res: any) => {
        setAllYears(res?.data);
      })
      .catch(() => {
      });
  }, []);

  const onChangeFilter = (selectedYears: any[]) => {
    setActiveYears(selectedYears);
  };

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_GALLERY} />
      <section className="main-section">
        <div className="main-content-full grow -mx-4 lg:mx-0 p-4 !pt-0 lg:p-6">
          <StudentSelection onItemChange={onItemChange} />
          <div className="flex justify-between items-center mb-4">
            <GalleryFilterTagBox
              activeYears={activeYears}
              onChangeTagBox={
                (selectedYears) => onChangeFilter(selectedYears)
              }
            />
            <button
              type="button"
              className="flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5"
              onClick={() => setIsFilterPopupOpen(true)}
            >
              <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light rounded-md cursor-pointer">
                <GetSVG ID={SVGID.FILTER_ICON} />
              </div>
              <strong className="p-1 text-lg hover:text-themered group-hover:text-themered">Filter</strong>
            </button>
          </div>
          <div className="w-full">
            <ul className="flex flex-row mb-6 space-x-5 sm:space-x-10 text-lg font-bold text-center scroll-px-4 overflow-x-auto -mx-4 px-4 sm:px-0 sm:mx-0 sm:text-xl">
              {tabs.map((item) => (
                <li key={item.id}>
                  <a
                    href="#"
                    className={`flex m-1 pb-1 border-b-4 border-themered transition-all duration-200 ease-in-out ${selectedTab === item.id ? 'text-black' : 'border-transparent text-gray-400 hover:text-black hover:border-themered focus:text-black focus:border-themered'}`}
                    onClick={(e) => onTabClick(e, item.id)}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {(galleryMedias.length === 0 && !loading) ? (
            <EmptyDataBlock
              title="Nothing has been uploaded here."
              containerClass="p-4 pt-20"
              titleContainerClass="py-6"
              titleClass="text-lg font-bold"
            />
          ) : (
            <div className="w-full">
              <InfiniteScroll
                dataLength={galleryMedias.length}
                next={() => loadFunc(curPage + 1, 1)}
                scrollThreshold="20px"
                hasMore={hasMore}
                style={{ margin: '-10px -10px 0' }}
                loader={<Loader containerClass="ml-4" label="Loading..." />}
              >
                {galleryMedias.length > 0 && (
                  <Masonry
                    columns={
                      {
                        xs: 2, sm: 2, md: 3, lg: 4, xl: 4,
                      }
                    }
                    spacing={2.5}
                    style={{ overflow: 'auto', margin: '0px' }}
                  >
                    {galleryMedias.map((item, index) => (
                      <GalleryImage
                        key={item.id}
                        id={item.id}
                        index={index}
                        imagePath={item.thumb_path}
                        isVideo={isVideoFile(item.file_path)}
                        onClickImage={onClickImage}
                      />
                    ))}
                  </Masonry>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </section>
      {isFilterPopupOpen && (
        <GalleryFilterPopup
          allYears={allYears}
          activeYears={activeYears}
          onChangeFilter={
            (selectedYears) => onChangeFilter(selectedYears)
          }
          onClose={() => setIsFilterPopupOpen(false)}
        />
      )}
      {printImage && (
        <PrintImageSidebar
          imagePath={printImage?.thumb_path || ''}
          imageName={printImage?.file_name || ''}
          mediaCode={printImage?.media_code || ''}
          studentName={selectedStudent?.studentName || ''}
          onClose={() => setPrintImage(null)}
        />
      )}
      {selectedImage && (
        <>
          <GallerySidebar
            imageId={selectedImage.id}
            originalPath={selectedImage?.file_path || ''}
            imagePath={selectedImage?.thumb_path || ''}
            imageName={selectedImage?.file_name || ''}
            studentName={selectedStudent?.studentName || ''}
            categoryId={selectedTab}
            isVideo={isVideoFile(selectedImage?.file_path)}
            onClose={() => setSelectedImage(null)}
            onReportImage={() => onClickReportImage()}
            onPrintImage={() => onClickPrintImage()}
            onDownloadImage={() => onClickDownloadImage()}
          />
          {isImageReported && (
            <ReportImageModal onClose={() => setIsImageReported(false)} />
          )}
          {showDownloadModal && (
            <DownloadImageModal
              onClose={() => setShowDownloadModal(false)}
              imagePath={selectedImage.file_path}
              imageName={selectedImage.file_name}
              isVideo={isVideoFile(selectedImage.file_path)}
            />
          )}
          {showAddToBasketModal && (
            <AddToBasketModal
              onAddToBasket={() => onClickAddToBasket()}
              onClose={() => setShowAddToBasketModal(false)}
            />
          )}
        </>
      )}
      {sidebarName === UISidebarType.BASKET_SIDEBAR && (
        <BasketSidebar />
      )}
    </>
  );
};

export default withRouter(Gallery);
