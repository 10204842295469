import React from 'react';
import ArticleCard from './ArticleCard';
import { getYearTags } from '../../../../helpers/commonHelper';

interface IProps {
  news: any,
  activeYears: string[],
  activeSubjects: string[],
  onClickCard: (articleId: number) => void,
}

const ArticleFilterView = (props: React.PropsWithChildren<IProps>) => {
  const {
    news,
    activeYears,
    activeSubjects,
    onClickCard,
  } = props;

  if (activeYears.length === 0 && activeSubjects.length === 0) {
    return null;
  }

  const fetchAllFilteredArticles = (curNews: any) => {
    if (!curNews) return [];

    let allNews: any[] = [];
    if (curNews.heads_message) {
      allNews.push(curNews.heads_message);
    }
    allNews = [...allNews, ...curNews.pinned_articles, ...curNews.other_articles];
    const filteredNews: any[] = [];
    allNews.forEach(item => {
      if (item.subject && activeSubjects && activeSubjects.includes(item.subject.name)) {
        filteredNews.push(item);
      } else if (item.years && activeYears) {
        let found = false;
        for (let i = 0; i < item.years.length; i += 1) {
          if (activeYears.includes(item.years[i].name)) {
            found = true;
            break;
          }
        }
        if (found) {
          filteredNews.push(item);
        }
      }
    });

    return filteredNews;
  };

  const filteredArticles = fetchAllFilteredArticles(news);
  return (
    <div className="main-content w-full">
      <div className="pb-6 grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
        {filteredArticles.map((item) => (
          <div key={item.id}>
            <ArticleCard
              onClickCard={() => onClickCard(item.id)}
              containerClass="flex flex-row h-full rounded-lg lg:rounded-xl"
              bodyClass="p-4 flex-col flex w-full h-full"
              titleClass="h4"
              imgClass="min-h-[8rem] h-full w-32"
              imgData={item.header_image}
              title={item.title}
              category={item.subject?.name}
              minRead={item.time_to_read}
              yearTags={getYearTags(item.years, item.division, item.total_year_count)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleFilterView;
