import React from 'react';
import withLightBox from '../../../HOC/withLightBox';
import lightBulbImg from '../../../../assets/img/light-bulb.svg';

interface IProps {
  quote: string,
}

const ArticlePopupQuote = (props: React.PropsWithChildren<IProps>) => {
  const {
    quote,
  } = props;

  return (
    <div className="pb-6">
      <div className="flex items-center max-w-[450px] w-full m-auto">
        <div className="flex-none"><img src={lightBulbImg} alt="Bulb" className="w-24 h-16" /></div>
        <div className="pl-3 text-sm font-medium"><em>{quote}</em></div>
      </div>
      <div className="max-w-[450px] mx-auto">
        <svg className="i-svg fill-themered h-5 w-full" viewBox="0 0 465 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M290.61 1.81991C346.84 1.35991 403.07 0.419914 459.3 -8.62349e-05C461.45 -8.62349e-05 465 -0.310086 465 2.33991C465 4.98991 461.36 4.52991 459.19 4.56991C393.34 5.56991 327.47 5.89991 261.64 7.51991C212.06 8.73991 162.51 11.3699 112.96 13.7199C82.8395 15.1499 52.7995 17.2199 22.7495 18.9999C15.8895 19.4199 9.04954 20.0599 2.17954 20.3199C-0.300459 20.3199 -0.820459 16.6499 1.58954 15.9699C1.72439 15.9408 1.86163 15.924 1.99954 15.9199C20.7995 14.4899 39.6095 12.9999 58.4395 12.0499C112.72 9.22991 167.003 6.54658 221.29 3.99991C244.38 2.92991 267.5 2.52991 290.61 1.82991C290.6 1.65991 290.61 1.99991 290.61 1.81991Z" fill="#D10027" />
        </svg>
      </div>
    </div>
  );
};

export default withLightBox(ArticlePopupQuote);
