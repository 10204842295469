import React, { useEffect, useState } from 'react';
import { Masonry } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import HighlightItem from '../../Common/HighlightItem';
import { getHighlights } from '../../../actions/mspUser';
import Loader from '../../Common/Loader';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';

const Highights = () => {
  const [highlightItems, setHighlightItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);

  const loadFunc = (pageToFetch: number) => {
    getHighlights(pageToFetch)
      .then((result: any) => {
        if (pageToFetch === 1) {
          setHighlightItems([...result.data]);
        } else {
          setHighlightItems([...highlightItems, ...result.data]);
        }

        const totalPages = result.meta?.last_page;
        const totalFetchedPage = result.meta?.current_page;
        setHasMore(totalPages !== totalFetchedPage);
        setCurPage(totalFetchedPage);
      })
      .catch(() => {
        setHasMore(false);
      });
  };

  useEffect(() => {
    loadFunc(1);
  }, []);

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_HIGHLIGHT} />
      <section className="main-section">
        <div className="main-content-full -mx-4 lg:mx-0 p-4 lg:p-6 lg:pb-0">
          <h1 className="mb-4">Highlights</h1>
          <div className="mb-6 max-w-2xl">
            <p>See all the fun and exciting things happening at school.</p>
          </div>
          <div className="w-full">
            <InfiniteScroll
              dataLength={highlightItems.length}
              next={() => loadFunc(curPage + 1)}
              scrollThreshold="20px"
              hasMore={hasMore}
              style={{ margin: '-10px -10px 0' }}
              loader={<Loader containerClass="ml-2" label="Highlights Loading..." />}
            >
              <Masonry
                columns={
                  {
                    xs: 1, sm: 2, md: 3, lg: 3,
                  }
                }
                spacing={2.5}
                style={{ overflow: 'auto', margin: '0px' }}
              >
                {highlightItems.map((item) => (
                  <HighlightItem
                    key={item.id}
                    highlight={item}
                    containerClass="relative"
                  />
                ))}
              </Masonry>
            </InfiniteScroll>
          </div>
        </div>
      </section>
    </>
  );
};

export default Highights;
