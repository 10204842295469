import React, { useEffect } from 'react';
import staffImg from '../../../assets/img/staff.svg';
import parentImg from '../../../assets/img/parent.svg';
import vipImg from '../../../assets/img/vip.svg';
import logoImg from '../../../assets/img/tomcom-unicorn-red-logo-text.svg';
import RoleItem from './RoleItem';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';

interface IProps {
  roles: any[],
  onSelectRole: (role: any) => void,
}

const RoleSwitcher = (props: React.PropsWithChildren<IProps>) => {
  const {
    roles,
    onSelectRole,
  } = props;

  const predefinedOrder = [
    'parent',
    'staff',
    'vip',
  ];

  const getImgSrc = (name: string) => {
    switch (name.toLowerCase()) {
      case 'guardian':
        return parentImg;
      case 'staff':
        return staffImg;
      case 'vip':
        return vipImg;
      default:
        return staffImg;
    }
  };

  // eslint-disable-next-line arrow-body-style
  let curRoles = roles.map((item) => {
    let itemName = '';
    if (item.name.toLowerCase() === 'guardian') {
      itemName = 'Parent';
    } else if (item.name.toLowerCase() === 'vip') {
      itemName = 'Applicant';
    } else {
      itemName = item.name;
    }
    return {
      id: item.id,
      name: itemName,
      imgSrc: getImgSrc(item.name),
    };
  });
  curRoles = curRoles.sort((a, b) => predefinedOrder.indexOf(a.name.toLowerCase())
    - predefinedOrder.indexOf(b.name.toLowerCase()));

  const onChangeSelection = (id: number) => {
    const selectedItem = roles.filter(item => item.id === id);
    if (selectedItem && selectedItem.length > 0) {
      onSelectRole(selectedItem[0]);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_ROLES} />
      <section className="flex justify-center mx-auto items-center min-h-screen px-10 py-24 relative bg-themebody-bg 2xl:py-28">
        <div className="text-center w-full pb-16 sm:pb-30">
          <h1 className="mb-5 sm:mb-14">I’m logging in as a</h1>
          <div className="flex flex-wrap sm:flex-nowrap space-x-3 justify-center sm:space-x-11">
            {curRoles && curRoles.map(item => (
              <RoleItem
                key={item.id}
                id={item.id}
                name={item.name}
                imgSrc={item.imgSrc}
                onSelectRole={(id) => onChangeSelection(id)}
              />
            ))}
            {(!curRoles || curRoles.length === 0) && (
              <span>No Assigned Role</span>
            )}
          </div>
        </div>
        <div className="absolute bottom-9 right-0 left-0 text-center">
          <div className="inline-block mx-auto">
            <img src={logoImg} className="w-24 md:w-auto" alt="TomCom" />
          </div>
        </div>
      </section>
    </>
  );
};

export default RoleSwitcher;
