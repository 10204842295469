import React, { useEffect, useState } from 'react';
import { getExternalSportsCalendarLink } from '../../../../actions/mspUser';

const ExternalSportsBtnLinks = () => {
  const [linkItems, setLinkItems] = useState<any[]>([]);

  const fetchLinks = () => {
    getExternalSportsCalendarLink()
      .then((result: any) => {
        setLinkItems(result.data);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  if (linkItems.length === 0) {
    return <div className="md:mb-6" />;
  }

  return (
    <div className="-mx-4 mb-6 lg:mb-0">
      <div className="snap-x snap-always scroll-px-4 overflow-x-auto pt-3 px-4 mb-3 pb-3 flex flex-row gap-3 lg:gap-5 lg:pb-3 lg:grid lg:grid-cols-2 xl:grid-cols-2">
        {linkItems.map((item) => (
          <div className="snap-start shrink-0 w-4/5 md:w-full" key={item.id}>
            <a
              href={item.url}
              className="btn btn-red w-full flex flex-col text-center justify-between gap-10 lg:gap-3 lg:flex-initial lg:flex-row rounded-2xl shadow-lg h-full p-4 group"
              target="_blank"
              rel="noreferrer"
            >
              {item.text}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExternalSportsBtnLinks;
