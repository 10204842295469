import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import tomcomLineImg from '../../../assets/img/tomcom-line.svg';
import tomcomUnicornLineImg from '../../../assets/img/tomcom-unicorn-line.svg';
import appStoreImg from '../../../assets/img/app-store.svg';
import googlePlayImg from '../../../assets/img/google-play.svg';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { loginUser } from '../../../actions/mspUser';
import config from '../../../config/config';
import { getAuthToken, saveAuthToken } from '../../../actions/AuthUtil';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';
import INameId from '../../../interfaces/nameItem';
import CommonDropdown from '../../Common/commonDropdown';

const Login = () => {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const filterDropdownData: INameId[] = Object.keys(config.MSP_URLS).map((key, index) => ({
    id: index,
    name: key,
    value: config.MSP_URLS[key],
  }));
  const [selMSPItem, setSelMSPItem] = useState<INameId | null>(null);

  useEffect(() => {
    const authToken = getAuthToken();
    if (authToken) {
      history.push('/home');
    }
    setTimeout(() => setPasswordType('text'), 500);
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setToken(newValue);
    setShowError('');
  };

  const onLoginClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (token.trim().length === 0) {
      return;
    }

    setLoading(true);
    loginUser(token.trim())
      .then((result: any) => {
        const authToken = result?.token;
        saveAuthToken(authToken);
        setLoading(false);
        history.push('/home');
      })
      .catch((error) => {
        if (error?.data?.message.toLowerCase() === 'invalidlogintoken') {
          setShowError('Invalid login token');
        } else {
          setShowError(error?.data?.message);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_LOGIN} />
      <section className="bg-white bg-no-repeat bg-bottom min-h-screen overflow-hidden 2xl:py-16 xl:py-10 xl:px-28 md:pt-8 md:px-10 md:pb-16 md:bg-[url('../img/bg.svg')] md:bg-navy">
        <div className="grid md:grid-cols-2 sm:gap-5 lg:gap-10 xl:gap-64 relative">
          <div className="flex-col h-full hidden md:flex">
            <div className="2xl:mt-14 xl:mt-6">
              <h1>
                <img src={tomcomLineImg} alt="TomCom" className="select-none" />
              </h1>
              <h2 className=" text-white text-4xl mt-7 leading-normal">
                Be Kind
                <br />
                Be Thomas’s
              </h2>
            </div>
            <div className="mt-auto">
              <h3 className="text-xl text-white ">Get the app</h3>
              <div className="flex gap-5 mt-5">
                <a
                  href="https://apps.apple.com/in/app/tomcom/id1633602585"
                  target="_blank"
                  rel="noreferrer"
                  className="transform duration-500 ease-in-out rounded-xl hover:scale-105 focus:scale-105"
                >
                  <img src={appStoreImg} alt="App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.jbi.tomcom"
                  target="_blank"
                  rel="noreferrer"
                  className="transform duration-500 ease-in-out rounded-xl hover:scale-105 focus:scale-105"
                >
                  <img src={googlePlayImg} alt="Google Play" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-4 pt-8 pb-16 items-center bg-white text-black w-full lg:w-96 md:rounded-[45px] md:ml-auto md:pb-8">
            <div className="mt-4 2xl:mt-10 xl:mt-0">
              <img src={tomcomUnicornLineImg} alt="TomCom" className="select-none" />
            </div>
            <div className="font-bold text-center mt-16 text-sm  2xl:mt-7 xl:mt-4">
              To log in use the TOKEN provided
              {' '}
              <br className="md:hidden block" />
              on
              {' '}
              <br className="md:block hidden" />
              your My School Portal
              {' '}
              <br className="md:hidden block" />
              Dashboard
            </div>
            <div className="w-full mt-7">
              <div className="mx-auto">
                <form onSubmit={onLoginClick}>
                  <div className="field mb-3 has-required has-warning">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="label hidden" htmlFor="password">Password</label>
                    <input
                      id="password"
                      name="password"
                      className="is-required input"
                      type={passwordType}
                      placeholder="Enter token e.g. 12XU - 95JS"
                      value={token}
                      onChange={onInputChange}
                    />
                    {showError && (
                      <p className="help-block">
                        <span className="font-medium">Oops!</span>
                        {' '}
                        {showError}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn w-full"
                    disabled={token.trim().length === 0}
                  >
                    {loading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden animate-spin flex-none -ml-1 mr-3 h-5 w-5 text-white" />}
                    Log in
                  </button>
                </form>
              </div>
            </div>
            {/* <div className="w-full text-center mt-10 2xl:mt-24"> */}
            <div className="w-full text-center mt-10">
              <p className="text-gray-400 text-xs">
                *Your Token can be found on your My School Portal
                Dashboard. Each token is unique and required only the first
                time you log in.
              </p>
              <CommonDropdown
                btnContent={() => (
                  <>
                    <span className={`${selMSPItem?.name ? '' : 'text-gray-400'} text-base font-normal`}>{selMSPItem?.name || 'Select School'}</span>
                    <GetSVG ID={SVGID.DROPDOWN_ARROW} svgClass="w-2.5 h-5 ms-3 ml-auto" />
                  </>
                )}
                btnClass="inline-flex justify-center item-center items-center w-full rounded-md border border-themered shadow-sm px-4 py-4 text-black text-xs font-medium focus:outline-none"
                dropdownClass="origin-bottom-right absolute right-0 bottom-full w-full rounded-md border-gray-300 bg-opacity-80 shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                containerClass="relative inline-block text-left ml-auto pt-2 mt-2 mr-3 w-full"
                dropdownData={filterDropdownData}
                showCheckMark={false}
                selectedOption={selMSPItem || filterDropdownData[0]}
                onSelectChange={(selItem) => setSelMSPItem(selItem)}
              />
              <a
                href={selMSPItem?.value}
                target="_blank"
                rel="noreferrer"
                className="mt-5 mb-3 btn w-full !whitespace-normal !pl-2 !pr-2"
              >
                Get Token from MSP
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
