import moment, { Moment } from 'moment';
import config from '../config/config';

export const isExternalUrl = (url: string) => (
  url?.substr(0, 7) === 'http://'
    || url?.substr(0, 8) === 'https://'
    || url?.substr(0, 3) === 'www'
);

export const makeIcsDescription = (description: string, findOurUrl: string) => {
  let desc = '';
  if (description) {
    desc = description;
  }
  if (findOurUrl) {
    if (desc) {
      desc += '\\n';
    }
    desc += `Find our more : ${findOurUrl}`;
  }
  return desc;
};

export const makeIcsFile = (
  title: string,
  startDate: Moment,
  endDate: Moment | null,
  isAllDay: boolean,
  location: string,
  description: string,
) => {
  let dtFormat = 'YYYYMMDDTHHmmSS';
  const dtSuffix = ':';
  if (isAllDay) {
    dtFormat = 'YYYYMMDD';
    // dtSuffix = ';VALUE=DATE:';
  }

  const sDate = `${dtSuffix}${startDate.format(dtFormat)}`;
  let eDate = '';
  if (!isAllDay) {
    let curEndDate = endDate;
    if (!curEndDate) {
      curEndDate = startDate.add(1, 'hours');
    }
    eDate = `${dtSuffix}${curEndDate.format(dtFormat)}`;
  } else if (endDate && startDate.date() !== endDate.date()) {
    eDate = `${dtSuffix}${endDate.add(1, 'days').format(dtFormat)}`;
  }

  const test = 'BEGIN:VCALENDAR\n'
    + 'PRODID:Calendar\n'
    + 'VERSION:2.0\n'
    + 'BEGIN:VEVENT\n'
    + `${`SUMMARY:${title}\nDTSTART`}${sDate}\n`
    + `${eDate ? `DTEND${eDate}\n` : ''}`
    + `${location ? `LOCATION:${location}\n` : ''}`
    + `${description ? `DESCRIPTION:${description}\n` : ''}`
    + 'END:VEVENT\n'
    + 'END:VCALENDAR';

  const data = new File([test], 'text/plain');
  // If we are replacing a previously generated file we need to
  // manually revoke the object URL to avoid memory leaks.
  // if (icsFile !== null) {
  //  window.URL.revokeObjectURL(icsFile);
  // }

  return window.URL.createObjectURL(data);
};

export const formatDate = (date: string, inFormat: string, outFormat: string) => {
  if (!date || !inFormat || !outFormat) {
    return '';
  }
  const parsedDate = moment(date, inFormat);
  return (parsedDate && moment(parsedDate).format(outFormat)) || '';
};

export const getNameFromFilePath = (filePath: string) => {
  const tokens = filePath.split('/');
  if (tokens && tokens.length > 0) {
    return tokens[tokens.length - 1];
  }
  return '';
};

export const getYearTags = (years: any[], division: any, totalYearCount: number) => {
  let tags: string[] = [];
  if (division && division.name) {
    tags.push(`Thomas's ${division.name}`);
  }

  if (years && years.length > 0) {
    if (!totalYearCount || totalYearCount !== years.length) {
      tags = years.map((item: any) => item.name);
    }
  }
  return tags;
};

export const getYearWithoutDivision = (years: any[], division: any) => {
  if (!division) {
    return years;
  }

  if (!years || years.length === 0) {
    return [];
  }

  const { name } = division;
  const newYears = years.map((item: any) => {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.name = newItem.name.replace(name, '').trim();
    return newItem;
  });
  return newYears;
};

export const getDateFromEvent = (event: any, isStart: boolean) => {
  let dateString = isStart ? event.start_date : event.end_date;
  const { fixture } = event;
  if (fixture) {
    const meetTime = (fixture.meet_time && `${fixture.meet_time}:00`) || '';
    const returnTime = (fixture.return_time && `${fixture.return_time}:00`) || '';
    const fixtureTime = (fixture.time && `${fixture.time}:00`) || '';
    if (isStart) {
      let newTime = '';
      if (meetTime && fixtureTime) {
        newTime = meetTime.localeCompare(fixtureTime) < 0 ? meetTime : fixtureTime;
      } else if (meetTime) {
        newTime = meetTime;
      } else if (fixtureTime) {
        newTime = fixtureTime;
      }

      if (newTime) {
        dateString = `${dateString.split(' ')[0]} ${newTime}`;
      }
    } else if (returnTime) {
      if (!dateString) {
        dateString = event.start_date;
      }
      dateString = `${dateString.split(' ')[0]} ${returnTime}`;
    }
  }
  return dateString;
};

export const getTitleFromEvent = (event: any) => {
  const {
    fixture,
    // eslint-disable-next-line camelcase
    is_training,
    years,
    division,
  } = event;

  const yearWithoutDiv = getYearWithoutDivision(years, division);
  let titleAppend = '';
  // eslint-disable-next-line camelcase
  if ((fixture || is_training) && yearWithoutDiv && yearWithoutDiv.length) {
    titleAppend = ' - ';
    titleAppend += yearWithoutDiv.map((year: any) => year.name).join(', ');
  }
  return `${event.title}${titleAppend}`;
};

export const getFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  if (parts.length > 0) {
    return parts[parts.length - 1].toLowerCase();
  }
  return '';
};

export const isVideoFile = (fileName: string) => {
  const extension = getFileExtension(fileName);
  if (extension.length > 0) {
    switch (extension) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mov':
        return true;
      default:
        return false;
    }
  }
  return false;
};

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a target="_blank" href="${url}">${url}</a>`);
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

export const getNumber = (data: string) => {
  if (!data) {
    return -1;
  }
  return parseInt(data, 10);
};

export const isUrlHavingSearchData = (url: string) => {
  if (url.length === 0) {
    return false;
  }

  let searchQuery = url;
  if (url.indexOf('?') > 0) {
    const pathArr = url.split('?');
    const [firstStr, secondStr] = pathArr;
    if (firstStr && secondStr && secondStr.length > 0) {
      searchQuery = secondStr;
    }
  }

  const query: any = new URLSearchParams(searchQuery);
  return (query.get('search')?.length > 0 && query.get('type')?.length > 0 && query.get('sid')?.length > 0);
};

export const goBackIfUrlStateHavingSearchData = (history: any) => {
  const curState: string = history?.location?.state?.itemCurPath || '';
  if (curState && isUrlHavingSearchData(curState)) {
    history.push(curState);
  }
};

export const getMSPUrls = (curMSP: string[]) => {
  if (curMSP.length === 0 || (curMSP.length === 1 && curMSP[0].length === 0)) {
    return config.MSP_URLS;
  }

  curMSP.sort();
  const supportedMSPUrls: any = {};
  curMSP.forEach((msp) => {
    Object.keys(config.MSP_URLS).forEach((key) => {
      if (key.toString().toLowerCase().includes(msp.toLowerCase())) {
        supportedMSPUrls[key] = config.MSP_URLS[key];
      }
    });
  });
  return supportedMSPUrls;
};
