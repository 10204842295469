import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import NewsCard from '../../../Common/NewsCard';
import { getWeeklyNews } from '../../../../actions/mspUser';

/* interface INewsItem {
  day: number;
  month: string;
  schoolName: string;
  routePath: string;
  isNew?: boolean;
  isRead?: boolean;
} */

interface IProps {
  id: number;
  title: string;
  activeSchools: string[];
}

const NewsCategory = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    title,
    activeSchools,
  } = props;

  const [newsItems, setNewsItems] = useState<any[]>([]);

  const loadFunc = (pageToFetch: number) => {
    getWeeklyNews(id, pageToFetch)
      .then((result: any) => {
        const newData = result?.data || [];
        const totalPages = result.meta?.last_page;
        const totalFetchedPage = result.meta?.current_page;
        setNewsItems((state) => [...state, ...newData]);
        setTimeout(() => {
          if (totalPages !== totalFetchedPage) loadFunc(pageToFetch + 1);
        }, 500);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    loadFunc(1);
  }, []);

  let filteredNewsItems = newsItems;
  if (id === 0 && activeSchools.length > 0) {
    filteredNewsItems = newsItems.filter((item: any) => activeSchools.includes(item.division?.name || ''));
  }

  const splideOptions = {
    type: 'slide',
    arrowPath: 'M12.9264 0.650874C12.0281 -0.216958 10.5719 -0.216958 9.67365 0.650874C8.84454 1.45195 8.78077 2.71247 9.48232 3.58422L9.67365 3.79358L26.4455 20L9.67365 36.2065C8.84454 37.0076 8.78077 38.268 9.48232 39.1398L9.67365 39.3491C10.5028 40.1502 11.8074 40.2118 12.7097 39.534L12.9264 39.3491L31.3264 21.5714C32.1555 20.7703 32.2192 19.5098 31.5177 18.638L31.3264 18.4286L12.9264 0.650874Z',
    perPage: 3,
    perMove: 3,
    pagination: false,
    padding: '0rem',
    speed: 600,
    arrows: filteredNewsItems.length > 6,
    breakpoints: {
      1536: {
        perPage: 3,
        perMove: 3,
        arrows: filteredNewsItems.length > 6,
      },
      1024: {
        padding: '5%',
      },
      768: {
        arrows: false,
        perPage: 1,
        perMove: 1,
      },
    },
  };

  const renderCards = (items: any) => {
    const retArray = [];
    for (let i = 0; i < items.length; i += 2) {
      retArray.push((
        <SplideSlide
          key={`${items[i].division?.name}_${items[i].id}`}
          className="grid grid-cols-2 grid-flow-row md:grid-cols-1 md:grid-rows-2 md:grid-flow-col gap-3 md:gap-5"
        >
          <NewsCard
            newsDate={items[i].date_of_publication}
            newsTime={items[i].time_of_publication}
            isRead={items[i].read}
            schoolName={items[i].division.name}
            routePath={`/newsdetail?id=${items[i].id}`}
          />
          {i + 1 < items.length && (
            <NewsCard
              newsDate={items[i + 1].date_of_publication}
              newsTime={items[i].time_of_publication}
              isRead={items[i + 1].read}
              schoolName={items[i + 1].division.name}
              routePath={`/newsdetail?id=${items[i + 1].id}`}
            />
          )}
        </SplideSlide>
      ));
    }
    return retArray;
  };

  if (filteredNewsItems.length === 0) {
    return null;
  }

  const showCategory = (activeSchools.length === 0)
    || (id === 0)
    || (activeSchools.length > 0 && id > 0 && activeSchools.includes(title));
  if (!showCategory) {
    return null;
  }

  return (
    <div className="bulletin-slider-section">
      <div className="bulletin-slider-content">
        <div className="main-content-full -mx-4 lg:mx-0 p-4 lg:p-6 lg:pb-0">
          <div className="flex justify-between items-center">
            <h2>{title}</h2>
          </div>
          <Splide
            className="bulletin-slider"
            hasTrack={false}
            options={splideOptions}
          >
            <SplideTrack>
              {renderCards(filteredNewsItems)}
            </SplideTrack>
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default NewsCategory;
