import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../helpers/commonHelper';
import GetSVG from '../../Common/GetSVG';
import FavouriteContent from '../../Common/FavouriteContent';

interface IProps {
  id: number;
  title: string;
  description: string;
  approveDate: string;
  itemRoutePath: any;
  favourite: number;
  SVGId: string;
  onClickFavourite: () => void;
}

const FavouriteBarItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    title,
    approveDate,
    description,
    itemRoutePath,
    favourite,
    SVGId,
    onClickFavourite,
  } = props;
  const [hide, setHide] = useState(false);

  const onClickFavouriteButton = () => {
    setHide(true);
    onClickFavourite();
  };

  return (
    <div className={`border-b transition-all duration-300 ${hide ? 'scale-0' : 'scale-100'}`}>
      <Link
        to={itemRoutePath}
        className="flex gap-3.5  items-center px-5 py-4 outline-0 ring-inset ring-2 ring-transparent hover:bg-themered hover:bg-opacity-5 focus-visible:ring-black"
      >
        <div>
          <GetSVG ID={SVGId} />
        </div>
        <div className="grow">
          <div className="flex content-center">
            <h3 className="text-[#747474] font-light text-xs">{title}</h3>
            <span className="text-themered text-xs font-medium ml-auto">
              {formatDate(approveDate, 'yyyy-MM-DD', 'DD MMM yyyy')}
            </span>
          </div>
          <div className="text-xs font-bold" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <FavouriteContent
          contentId={id}
          favourite={favourite}
          onChangeFavourite={onClickFavouriteButton}
        />
      </Link>
    </div>
  );
};

export default FavouriteBarItem;
