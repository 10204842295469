import React from 'react';

interface IProps {
  id: string,
  onClickOverlay: () => void;
  overlayZIndex?: number;
}

const OverlayBlock = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    onClickOverlay,
    overlayZIndex,
  } = props;

  return (
    <div
      id={id}
      className="sidebar-mask fixed inset-0 bg-black w-screen h-screen bg-opacity-40 transition-all duration-300 ease-in-out"
      onClick={onClickOverlay}
      style={{ zIndex: overlayZIndex }}
    />
  );
};

OverlayBlock.defaultProps = {
  overlayZIndex: 38,
};

export default OverlayBlock;
