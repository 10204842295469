import React from 'react';
import { Link } from 'react-router-dom';
import GetSVG from '../../Common/GetSVG';
import { isExternalUrl } from '../../../helpers/commonHelper';

interface IProps {
  title: string;
  itemRoutePath: string;
  isActive: boolean;
  SVGId: string;
  liClass?: string;
  titleClass?: string;
  onClickItem: (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>, title: string) => void;
}

const SidebarItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    title,
    itemRoutePath,
    isActive,
    SVGId,
    liClass,
    titleClass,
    onClickItem,
  } = props;

  const isExternal = isExternalUrl(itemRoutePath);
  const renderChildren = () => (
    <>
      <small className={`link-active ${itemRoutePath === '/need-to-know' ? 'longtitle' : ''}`}>{title}</small>
      <GetSVG ID={SVGId} />
      <span className={`link-label ml-3 leading-tight w-fit group-hover:text-themered group-focus:text-themered ${titleClass}`}>{title}</span>
    </>
  );

  return (
    <li className={`h-10 w-full relative ${liClass}`}>
      {isExternal ? (
        <a
          href={itemRoutePath}
          target="_blank"
          rel="noreferrer"
          className={`link-item group ${isActive ? 'active' : ''}`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => onClickItem(e, title)}
        >
          {renderChildren()}
        </a>
      ) : (
        <Link
          to={itemRoutePath}
          className={`link-item group ${isActive ? 'active' : ''}`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => onClickItem(e, title)}
        >
          {renderChildren()}
        </Link>
      )}
    </li>
  );
};

SidebarItem.defaultProps = {
  liClass: 'mb-8',
  titleClass: '',
};

export default SidebarItem;
