import React, { useEffect, useRef, useState } from 'react';
import { animateOnMouseMove } from '../../../helpers/uiHelper';
import { formatDate } from '../../../helpers/commonHelper';
import FavouriteContent from '../../Common/FavouriteContent';
import ArchiveContent from '../../Common/ArchiveContent';

interface IProps {
  id: number;
  schoolName: string;
  detail: string;
  noticeDate: string;
  isRead: boolean;
  type: number;
  favourite: number;
  archive: number;
  onClickItem: () => void;
  onChangeFavourite?: () => void;
  onChangeArchive?: () => void;
}

const NoticeItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    schoolName,
    detail,
    noticeDate,
    isRead,
    type,
    favourite,
    archive,
    onClickItem,
    onChangeFavourite,
    onChangeArchive,
  } = props;
  const containerRef = useRef(null);
  const [hide, setHide] = useState(false);

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    e.preventDefault();
    animateOnMouseMove(e, containerRef?.current);
  };

  const onItemClick = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    event.preventDefault();
    onClickItem();
  };

  useEffect(() => {
    const el = containerRef?.current;
    if (el) {
      // @ts-ignore
      el.style.setProperty('--is-hovers-bg', '#f2f2f2');
    }
  }, []);

  const onClickArchiveButton = () => {
    setHide(true);
    if (onChangeArchive) {
      onChangeArchive();
    }
  };

  const noticeColor = type === 1 ? 'bg-themered' : 'bg-[#003aff]';
  const division = type === 1 ? schoolName : 'PTA';
  return (
    <a
      ref={containerRef}
      href="#"
      onClick={onItemClick}
      className={`is-hover block bg-white rounded-lg mb-4 shadow-lg group transition-all hover:shadow-xl focus:shadow-xl focus-within:shadow-xl duration-300 ${hide ? 'scale-0' : 'scale-100'}`}
      onMouseEnter={onMouseMoveEvent}
      onMouseLeave={onMouseMoveEvent}
    >
      <div className={`flex justify-between ${isRead ? 'bg-[#5A5A5A]' : noticeColor} text-white py-2 px-5 rounded-tl-lg rounded-tr-lg text-sm`}>
        <div className="lg:font-bold font-normal">
          <span>
            {formatDate(noticeDate, 'yyyy-MM-DD HH:mm:ss', 'DD MMM yyyy')}
          </span>
          <span className="ml-2">
            {formatDate(noticeDate, 'yyyy-MM-DD HH:mm:ss', 'HH:mm')}
          </span>
        </div>
        <div className="font-bold">{division}</div>
      </div>
      <div className="flex">
        <div className="flex w-full items-center">
          <div className="w-8 m-3 flex flex-col">
            {archive === 0 && (
              <FavouriteContent
                contentId={id}
                favourite={favourite}
                containerClass={`mr-3 h-full items-center ${favourite === 0 ? ' mb-3' : ''}`}
                favouriteIconClass="h-8 w-8"
                onChangeFavourite={onChangeFavourite}
              />
            )}
            {(favourite === 0 || archive === 1) && (
              <ArchiveContent
                contentId={id}
                archive={archive}
                containerClass="mr-3 ml-1 h-full items-center"
                archiveIconClass="w-5"
                onChangeArchive={onClickArchiveButton}
              />
            )}
          </div>
          <div className="flex flex-col pl-1 p-4 w-full">
            <div className="flex h-full items-center">
              <h3 className={`font-bold transition-all ${isRead ? 'group-hover:text-black group-focus:text-black' : 'group-hover:text-themered group-focus:text-themered'} group-focus-visible:text-themered`}>
                {detail}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex mr-3 mb-3">
          <i className={`i- ${isRead ? 'i-long-arrow-gray' : 'i-long-arrow'} mt-auto ml-auto transition-all group-hover:translate-x-1 group-focus:translate-x-1 group-focus-visible:translate-x-1`} />
        </div>
      </div>
    </a>
  );
};

NoticeItem.defaultProps = {
  onChangeFavourite: null,
  onChangeArchive: null,
};

export default NoticeItem;
