import React, { useState } from 'react';
import { ArrowContainer, Popover, PopoverState } from 'react-tiny-popover';
import { getShareLink } from '../../actions/mspUser';
import EmailPopup from './EmailPopup';
import GetSVG, { SVGID } from './GetSVG';

interface IProps {
  contentId: string;
  contentType: string;
  contentTitle: string;
  contentDate: string;
  parentRef?: any;
}

const ShareContentButton = (props: React.PropsWithChildren<IProps>) => {
  const {
    contentId,
    contentType,
    contentTitle,
    contentDate,
    parentRef,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [tooltipTxt, setTooltipTxt] = useState('');

  const showTooltip = (forCopyLink: boolean) => {
    setTooltipTxt(forCopyLink ? 'Link Copied to Clipboard!' : 'Email sent successfully!');
    setTimeout(() => {
      setTooltipTxt('');
    }, 2000);
  };

  const onClickCopyLink = () => {
    getShareLink(contentId, contentType)
      .then((result: any) => {
        if (result) {
          navigator.clipboard.writeText(result.url);
          showTooltip(true);
        }
      })
      .catch(() => {
      });
    setIsPopoverOpen(false);
  };

  const onClickShareEmail = () => {
    setIsPopoverOpen(false);
    setIsEmailPopupOpen(true);
  };

  const onCloseEmailPopup = (success: boolean) => {
    setIsEmailPopupOpen(false);
    if (success) {
      showTooltip(false);
    }
  };

  const renderMenu = ({ position, childRect, popoverRect }: PopoverState) => (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor="white"
      arrowSize={0}
      arrowStyle={{ opacity: 0.7 }}
      className="popover-arrow-container"
      arrowClassName="popover-arrow"
    >
      <div className="popover-dropdown w-40 z-[60]">
        <ul className="w-full rounded-lg overflow-hidden">
          <li className="border-b border-themered">
            <button
              type="button"
              className="text-left block w-full px-3 py-3 text-xs font-medium hover:bg-themered hover:bg-opacity-5 flex items-center"
              onClick={onClickCopyLink}
            >
              <GetSVG ID={SVGID.LINK_ICON} svgClass="fill-black h-6 w-6" />
              <span className="ml-2">Copy link</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="text-left block w-full px-3 py-3 text-xs font-medium hover:bg-themered hover:bg-opacity-5 flex items-center"
              onClick={onClickShareEmail}
            >
              <GetSVG ID={SVGID.EMAIL_ICON} svgClass="fill-black h-5 w-6" />
              <span className="ml-2">Share on Email</span>
            </button>
          </li>
        </ul>
      </div>
    </ArrowContainer>
  );

  return (
    <>
      <h3 className="mt-2 mb-2">{`Share ${contentType === 'notice' ? 'Notice' : 'Article'}`}</h3>
      <div className="relative mb-2 h-20 max-w-2xl">
        <Popover
          containerClassName="z-[60]"
          isOpen={isPopoverOpen}
          positions={['bottom', 'top']}
          align="start"
          padding={0}
          reposition
          onClickOutside={() => setIsPopoverOpen(false)}
          content={(popoverState) => renderMenu(popoverState)}
          parentElement={parentRef?.current || undefined}
        >
          <button
            type="button"
            className="btn btn-red w-full mb-4 btn-px-4 flex space-x-6 z-[60] absolute --jbi-popover-dropdown"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <span className="whitespace-normal text-left">
              Share
            </span>
            <span className="flex-none">
              <GetSVG ID={SVGID.SHARE_ICON} svgClass="fill-white h-6 w-6" />
            </span>
          </button>
        </Popover>
        {tooltipTxt && (
          <div className="z-[60] w-full relative flex items-center justify-center py-2 px-4 bottom-16 left-0 animate-fade-in">
            <span className="bg-black text-white rounded p-3">{tooltipTxt}</span>
          </div>
        )}
      </div>
      {isEmailPopupOpen && (
        <EmailPopup
          onClose={(success) => onCloseEmailPopup(success)}
          contentId={contentId}
          contentType={contentType}
          title={contentTitle}
          date={contentDate}
          parentRef={parentRef}
        />
      )}
    </>
  );
};

ShareContentButton.defaultProps = {
  parentRef: null,
};

export default ShareContentButton;
