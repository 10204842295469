import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import GetSVG, { SVGID } from './GetSVG';
import { toggleFavouriteNotice } from '../../actions/mspUser';
import * as uiActionCreators from '../../store/actioncreators/uiActionCreators';

interface IProps {
  contentId: number;
  favourite: number;
  containerClass?: string;
  favouriteIconClass?: string;
  onChangeFavourite?: (favourite: number) => void;
}

const FavouriteContent = (props: React.PropsWithChildren<IProps>) => {
  const {
    contentId,
    favourite,
    containerClass,
    favouriteIconClass,
    onChangeFavourite,
  } = props;

  const dispatch = useDispatch();
  const { setFavouriteChangeItem } = bindActionCreators(uiActionCreators, dispatch);

  const onClickFavourite = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleFavouriteNotice(contentId.toString())
      .then((res: any) => {
        if (onChangeFavourite) {
          onChangeFavourite(res.favourite);
        }
        setFavouriteChangeItem(contentId);
        setTimeout(() => setFavouriteChangeItem(0), 200);
      })
      .catch((err) => console.log(err));
  };

  return (
    <button type="button" onClick={onClickFavourite} className={containerClass}>
      <GetSVG
        ID={favourite === 0 ? SVGID.UNFAVOURITE_ICON : SVGID.FAVOURITE_ICON}
        svgClass={`fill-themered ${favouriteIconClass}`}
      />
    </button>
  );
};

FavouriteContent.defaultProps = {
  containerClass: '',
  favouriteIconClass: 'h-6 w-6',
  onChangeFavourite: null,
};

export default FavouriteContent;
