import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import userDataReducer from './userDataReducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  userData: userDataReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>
