import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import NotificationBarItem from './NotificationBarItem';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import { getNotificationItems, markAllNotificationAsRead } from '../../../actions/mspUser';
import Loader from '../../Common/Loader';
import OverlayBlock from '../../Common/overlayBlock';

const NotificationBar = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [allRead, setAllRead] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);

  const loadFunc = (pageToFetch: number) => {
    getNotificationItems(pageToFetch)
      .then((result: any) => {
        if (pageToFetch === 1) {
          setNotifications([...result.data]);
          const unReadItems = result?.meta?.total_unread;
          setAllRead(unReadItems === 0);
        } else {
          setNotifications([...notifications, ...result.data]);
        }

        const totalPages = result.meta?.last_page;
        const totalFetchedPage = result.meta?.current_page;
        setHasMore(totalPages !== totalFetchedPage);
        setCurPage(totalFetchedPage);
      })
      .catch(() => {
        setHasMore(false);
      });
  };

  const onClickMarkAllAsRead = () => {
    markAllNotificationAsRead()
      .then(() => {
        setCurPage(0);
        loadFunc(1);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(UISidebarType.NOTIFICATION_SIDEBAR)!.classList.add('!right-0');
    }, 100);

    loadFunc(1);
  }, []);

  const getSvgId = (contentType: string) => {
    switch (contentType.toLowerCase()) {
      case 'news':
        return SVGID.NEWS_SIDEBAR;
      case 'notice':
        return SVGID.INBOX_SIDEBAR;
      case 'calendar_event':
        return SVGID.CALENDAR_SIDEBAR;
      default:
        return SVGID.NEWS_SIDEBAR;
    }
  };

  const getPageUrl = (notification: any) => {
    let urlType = '';
    const contentType = notification?.content?.type.toLowerCase() || '';
    switch (contentType) {
      case 'news':
        urlType = 'newsdetail';
        break;
      case 'notice':
        urlType = 'need-to-know';
        break;
      case 'calendar_event':
        urlType = 'calendar';
        break;
      default:
        urlType = '';
    }
    return `/${urlType}?id=${notification?.content?.id}`;
  };

  const setPrefixToDescription = (notification: any) => {
    let prefix = '';
    const contentType = notification?.content?.type.toLowerCase() || '';
    const isImp = notification?.important === 1;
    const isPTA = notification?.type === 2;
    let msgPrefix = '';
    switch (contentType) {
      case 'news':
        prefix = 'Weekly news ';
        break;
      case 'notice':
        prefix = '';
        if (isImp) {
          msgPrefix = 'TomCom IMPORTANT - ';
        } else if (isPTA) {
          msgPrefix = 'TomCom PTA - ';
        } else {
          msgPrefix = 'TomCom - ';
        }
        break;
      case 'calendar_event':
        prefix = '';
        break;
      default:
        prefix = '';
    }
    return `${msgPrefix}${prefix}${notification.title}`;
  };

  const onCloseSidebar = () => {
    toggleRightSidebar(
      UISidebarType.NOTIFICATION_SIDEBAR,
      document,
      dispatch,
    );
  };

  return (
    <>
      <div
        id={UISidebarType.NOTIFICATION_SIDEBAR}
        className="sidebar-popup fixed z-[39] bottom-0 top-16 md:top-24 transition-all duration-300 ease-in-out bg-white w-full drop-shadow-lg max-h-full md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem]  overflow-y-auto -right-full"
      >
        <div className="pt-6 px-4 sticky bg-white z-10 right-0 left-0 top-0 border-b">
          <div className="flex space-x-2 items-center">
            <button
              type="button"
              className="h-8 w-8 text-black group relative hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center  text-sm font-light rounded-md cursor-pointer"
              onClick={onCloseSidebar}
            >
              <GetSVG ID={SVGID.LEFTARROW_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </button>
            <h2 className="h1">Notifications</h2>
            {notifications && notifications.length > 0 && (
              <button
                type="button"
                className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black !ml-auto"
                onClick={onClickMarkAllAsRead}
              >
                Mark All As Read
              </button>
            )}
          </div>
          <div className="font-bold pl-4 pt-6" />
        </div>
        <InfiniteScroll
          dataLength={notifications.length}
          next={() => loadFunc(curPage + 1)}
          scrollThreshold="20px"
          scrollableTarget={UISidebarType.NOTIFICATION_SIDEBAR}
          hasMore={hasMore}
          loader={<Loader containerClass="ml-4 mt-6" label="Notifications Loading..." />}
        >
          {notifications.map((item) => (
            <NotificationBarItem
              key={item.id}
              id={item.id}
              publishDate={item.date}
              title={item.division?.name}
              description={setPrefixToDescription(item)}
              itemRoutePath={getPageUrl(item)}
              SVGId={getSvgId(item.content?.type)}
              isRead={item.read}
              isAllRead={allRead}
            />
          ))}
        </InfiniteScroll>
      </div>
      <OverlayBlock
        id="notification_popup_mask"
        onClickOverlay={onCloseSidebar}
      />
    </>
  );
};

export default NotificationBar;
