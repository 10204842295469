import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoticeItem from './NoticeItem';
import { getArchivedNotices, getNoticeById, getNotices } from '../../../actions/mspUser';
import Loader from '../../Common/Loader';
import { getSelectedRole } from '../../../actions/AuthUtil';
import EmptyDataBlock from '../../Common/EmptyDataBlock';
import { RootState } from '../../../store/reducers';

interface IProps {
  studentId: number,
  divisionId: number;
  division: string;
  isNTK: boolean;
  onClickNotice: (notice: any) => void;
}

const NoticeListBox = (props: React.PropsWithChildren<IProps>) => {
  const {
    studentId,
    divisionId,
    division,
    isNTK,
    onClickNotice,
  } = props;

  const [notices, setNotices] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);
  const [selRole, setSelRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const favouriteChangeItem: number = useSelector(
    (state: RootState) => state.ui.favouriteChangeItem,
  );
  const archiveChangeItem: number = useSelector(
    (state: RootState) => state.ui.archiveChangeItem,
  );

  const setNoticesAsPerPage = (pageToFetch: number, result: any) => {
    setNotices(pageToFetch === 1 ? [...result.data] : [...notices, ...result.data]);
    const totalPages = result.meta?.last_page;
    const totalFetchedPage = result.meta?.current_page;
    setCurPage(totalFetchedPage);
    setHasMore(totalPages !== totalFetchedPage);
    setIsLoading(false);
  };

  const loadFunc = (pageToFetch: number) => {
    setIsLoading(true);
    if (isNTK) {
      getNotices(divisionId, studentId, pageToFetch)
        .then((result: any) => {
          setNoticesAsPerPage(pageToFetch, result);
        })
        .catch(() => {
          setHasMore(false);
          setIsLoading(false);
        });
    } else {
      getArchivedNotices(pageToFetch)
        .then((result: any) => {
          setNoticesAsPerPage(pageToFetch, result);
        })
        .catch(() => {
          setHasMore(false);
          setIsLoading(false);
        });
    }
  };

  const changeNoticeItem = (id: number, isReadOrFav: boolean) => {
    const newNotices = [...notices];
    for (let i = 0; i < newNotices.length; i += 1) {
      if (newNotices[i].id === id) {
        if (isReadOrFav) {
          newNotices[i].read = !newNotices[i].read;
        } else {
          newNotices[i].favourite = (1 - newNotices[i].favourite);
        }
        break;
      }
    }
    setNotices([...newNotices]);
  };

  useEffect(() => {
    const selectedRole = getSelectedRole();
    if (selectedRole) {
      setSelRole(selectedRole?.name || '');
    }

    setNotices([]);
    if (divisionId > -1 || studentId > -1) {
      setHasMore(true);
      setCurPage(0);
      loadFunc(1);
    }
  }, [divisionId, studentId, isNTK]);

  useEffect(() => {
    if (favouriteChangeItem > 0) {
      changeNoticeItem(favouriteChangeItem, false);
    }
  }, [favouriteChangeItem]);

  const onChangeArchiveNotice = () => {
    loadFunc(1);
  };

  useEffect(() => {
    if (archiveChangeItem > 0) {
      onChangeArchiveNotice();
    }
  }, [archiveChangeItem]);

  const onNoticeItemClick = (notice: any) => {
    onClickNotice(notice);

    if (notice.read) {
      return;
    }

    getNoticeById(notice?.id)
      .then((result: any) => {
        if (result?.data?.read) {
          changeNoticeItem(result.data.id, true);
        }
      })
      .catch(() => {
      });
  };

  let title = 'Notices';
  if (selRole.toLowerCase() === 'staff' || selRole.toLowerCase() === 'vip') {
    title = 'Messages';
  } else if (selRole.toLowerCase() === 'guardian' || selRole.toLowerCase() === 'student 11+') {
    title = 'Need to Know messages';
  }
  if (!isNTK) {
    title = 'All Archived Messages';
  }

  return (
    <div className="main-content grow">
      <h2 className="pb-4">{title}</h2>
      <div className="notice-lists mb-6">
        {(notices.length === 0 && !isLoading) && (
          <EmptyDataBlock
            title="No notices have been sent yet"
            containerClass="p-4 pt-20"
            titleContainerClass="py-6"
            titleClass="text-lg font-bold"
          />
        )}
        {(notices.length > 0 || isLoading) && (
          <InfiniteScroll
            dataLength={notices.length}
            next={() => loadFunc(curPage + 1)}
            scrollThreshold="20px"
            hasMore={hasMore}
            loader={<Loader containerClass="mt-6" label="Notices Loading..." />}
          >
            {notices.map((item) => (
              <NoticeItem
                key={item.id}
                id={item.id}
                schoolName={item.division_name || division}
                detail={item.title}
                noticeDate={item.calculated_date}
                isRead={item.read}
                type={item.type}
                favourite={item.favourite}
                archive={item.archived}
                onClickItem={() => onNoticeItemClick(item)}
                onChangeArchive={onChangeArchiveNotice}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
export default NoticeListBox;
