import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { createPaymentOrder } from '../../../actions/mspUser';
import { getBasket, setBasket } from '../../../actions/AuthUtil';
import config from '../../../config/config';

interface IProps {
  media: any[],
  onSuccess: (orderId: string) => void,
}

const GalleryCheckoutForm = (props: React.PropsWithChildren<IProps>) => {
  const {
    media,
    onSuccess,
  } = props;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [payId, setPayId] = useState('');
  const [elementReady, setElementReady] = useState(false);
  const [elementLoading, setElementLoading] = useState(false);
  const amount = media.length * config.PHOTO_COST;

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null || stripe == null || payId || loading) {
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      /* confirmParams: {
        return_url: 'http://localhost:3000/my-account',
      }, */
      redirect: 'if_required',
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      if (result.error.type !== 'validation_error') {
        toast.error(result.error.message);
      }
      setLoading(false);
    } else {
      const refId = result?.paymentIntent?.id || '';
      if (refId) {
        const basketObj = getBasket();
        const orderData = {
          reference: refId,
          total: amount,
          // eslint-disable-next-line arrow-body-style
          items: basketObj.map((item: any) => {
            return {
              media_id: item.id,
              student_id: item.studentId,
              price: config.PHOTO_COST,
            };
          }),
        };
        createPaymentOrder(orderData)
          .then((res: any) => {
            setPayId(refId);
            setLoading(false);
            setBasket([], dispatch);
            onSuccess(res?.data?.id);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        toast.error('Error processing payment');
        setLoading(false);
      }
    }
  };

  const onReadyElements = () => {
    setElementReady(true);
  };

  const onLoaderStarts = () => {
    setElementLoading(true);
  };

  const onLoadError = (e: any) => {
    if (e.error?.message) {
      toast.error(e.error?.message);
    }
  };

  let btnText = `Pay £${amount.toFixed(2)}`;
  if (loading) {
    btnText = 'Processing...';
  } else if (payId) {
    btnText = 'Success';
  }
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => onReadyElements()}
        onLoaderStart={() => onLoaderStarts()}
        onLoadError={(e) => onLoadError(e)}
      />
      {!elementLoading && (
        <div className="w-full justify-center items-center flex">
          <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden item-center animate-spin flex-none h-16 w-16 text-themered" />
        </div>
      )}
      {elementReady && (
        <button className="btn btn-red mt-4 w-full" type="submit" disabled={!stripe || !elements}>
          {loading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden animate-spin flex-none -ml-1 mr-3 h-5 w-5 text-white" />}
          {btnText}
        </button>
      )}
    </form>
  );
};

export default GalleryCheckoutForm;
