import React, { useEffect } from 'react';
import {
  Route, Switch, withRouter, RouteComponentProps,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import * as uiActionCreators from './store/actioncreators/uiActionCreators';
import routes from './routes';
import NotFound from './components/Pages/NotFound';
import Login from './components/Pages/Auth/Login';
import Authentication from './components/Pages/Auth/Authentication';
import PaymentConfirmed from './components/Pages/PaymentConfirmed';
import withLayoutManager from './components/HOC/withLayoutManager';
import AccessDenied from './components/Pages/AccessDenied';
import config from './config/config';
import { removeCookie } from './helpers/cookieHelper';
import ShareContentPreview from './components/Pages/ShareContentPreview/ShareContentPreview';

const AppRouter = (props: RouteComponentProps) => {
  const { location } = props;
  const cookieName = `CookieConsent_${config.ENV}`;
  const transitionStarted = () => {
    document.body.classList.add('transition');
  };

  const transitionEnded = () => {
    document.body.classList.remove('transition');
  };

  const dispatch = useDispatch();
  const isGAEnabled = () => getCookieConsentValue(cookieName) === 'true';

  const initGA = () => {
    const gaId = config.GA_ID;
    if (gaId && isGAEnabled()) {
      ReactGA.initialize(gaId);
    } else {
      removeCookie('_ga');
      removeCookie('_gat');
      removeCookie('_gid');
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    const gaId = config.GA_ID;
    if (ReactGA && gaId && isGAEnabled()) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return () => {
      const { setSearchText } = bindActionCreators(uiActionCreators, dispatch);
      setSearchText('');
    };
  }, [location]);

  const currentKey = location.pathname || '/';
  const timeout = { enter: 1000, exit: 0 };
  return (
    <TransitionGroup className="transition-wrapper">
      <CSSTransition
        key={currentKey}
        timeout={timeout}
        classNames="transition-page"
        /* mountOnEnter={false}
        unmountOnExit */
        onEnter={transitionStarted}
        onExited={transitionEnded}
      >
        <div>
          <Switch location={location}>
            { routes.map((route: any) => (
              <Route
                path={route.path}
                component={withLayoutManager(route.component)}
                exact
                key={route.path}
              />
            )) }
            <Route path="/login" exact component={Login} key="/login" />
            <Route path="/access-denied" exact component={AccessDenied} key="/accessdenied" />
            <Route path="/:school/authenticate/sso" exact component={Authentication} key="/schoolauthenticatesso" />
            <Route path="/:school/authenticate/:sso" exact component={Authentication} key="/schoolauthenticatesso" />
            <Route path="/:school/authenticate" exact component={Authentication} key="/schoolauthenticate" />
            <Route path="/payment-confirmed" exact component={PaymentConfirmed} key="/payment-confirmed" />
            <Route path="/content/:token" exact component={ShareContentPreview} key="/share-content" />
            <Route path="*" exact component={withLayoutManager(NotFound)} key="*" />
          </Switch>
          {location.pathname !== '/payment-confirmed' && (
            <CookieConsent
              enableDeclineButton
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              // style={{ backgroundColor: '#1A1430', color: 'white' }}
              // buttonStyle={{ backgroundColor: '#d10027', color: 'white' }}
              buttonClasses="btn-accept"
              declineButtonClasses="btn-decline"
              contentClasses="cookie-body-info"
              buttonWrapperClasses="cookie-action"
              // declineButtonStyle={{ backgroundColor: 'transparent', color: 'white' }}
              cookieName={`CookieConsent_${config.ENV}`}
              onAccept={() => initGA()}
            >
              This website uses cookies to improve your experience.
              We&apos;ll assume you&apos;re ok with this, but you can opt-out if you wish.
            </CookieConsent>
          )}
          <ToastContainer theme="colored" position="bottom-right" />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default withRouter(AppRouter);
