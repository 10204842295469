import React, { useEffect, useState } from 'react';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  activeYears: any[],
  allYears: any[],
  onClose: () => void,
  onChangeFilter: (selectedYears: any[]) => void
}

const GalleryFilterPopup = (props: React.PropsWithChildren<IProps>) => {
  const {
    activeYears,
    allYears,
    onClose,
    onChangeFilter,
  } = props;

  const [selectedYears, setSelectedYears] = useState<any[]>([...activeYears]);

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('filter-modal')!.classList.add('active');
    }, 100);
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('filter-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const onSelectedYearChange = (year: any) => {
    const curYears = selectedYears;
    const index = curYears.findIndex(item => item.id === year.id);
    if (index < 0) {
      curYears.push(year);
    } else {
      curYears.splice(index, 1);
    }
    curYears.sort((a, b) => a.name.localeCompare(b.name));
    setSelectedYears([...curYears]);
  };

  const onResetFilter = () => {
    setSelectedYears([]);
  };

  const onApplyFilter = () => {
    onChangeFilter(selectedYears);
    onClosePopup();
  };

  return (
    <>
      <div id="filter-modal" className="modal">
        <div className="modal-card">
          <div className="modal-card-body max-w-3xl">
            <div className="modal-card-head text-right relative">
              <button
                type="button"
                className="absolute md:relative right-0 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
                onClick={onClosePopup}
              >
                <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
              </button>
            </div>
            <div className="modal-card-info">
              {allYears.length > 0 && (
                <>
                  <h2 className="h3 mb-2">Academic Year</h2>
                  <div className="filter-items relative overflow-y-auto max-h-screen pb-6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                    {allYears.map((item) => (
                      <button
                        key={item.id}
                        type="button"
                        className={`btn-filter-item toggle-filter ${selectedYears.findIndex(sItem => sItem.id === item.id) > -1 ? 'selected' : ''}`}
                        onClick={() => onSelectedYearChange(item)}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="modal-card-foot pt-4 grid grid-cols-2 gap-4">
              <button type="button" className="btn-link" onClick={onResetFilter}>Reset Filters</button>
              <button type="button" className="btn btn-red" onClick={onApplyFilter}>Apply</button>
            </div>
          </div>
        </div>
        <div className="modal-background" />
      </div>
    </>
  );
};

export default GalleryFilterPopup;
