import React, { useRef } from 'react';
import moment from 'moment';
import EventItem from '../../../Common/EventItem';
import EmptyDataBlock from '../../../Common/EmptyDataBlock';
import { getDateFromEvent, getTitleFromEvent } from '../../../../helpers/commonHelper';

interface IProps {
  selectedDate: Date;
  events: any[];
  onViewEvent: (event: any) => void,
}

const EventListBlock = (props: React.PropsWithChildren<IProps>) => {
  const {
    selectedDate,
    events,
    onViewEvent,
  } = props;

  const containerRef = useRef(null);

  const dateTitle = moment(selectedDate).format('dddd, Do MMMM');
  return (
    <div className="home-todays-events">
      <h2 className="text-xs mb-3 font-medium">{dateTitle}</h2>
      <div className="-mx-4 md:mx-0">
        <div
          ref={containerRef}
          className="relative snap-x snap-always scroll-px-4 overflow-x-auto px-4 mb-3 pb-3 flex flex-row gap-3 md:overflow-y-auto md:max-h-[17.25rem] md:grid md:mb-0 md:px-0 md:pb-3 md:pr-2 md:-mr-2 md:rounded-lg overflow-auto-themered-only"
        >
          <div className="bg-c1 bg-c2 bg-c3 bg-c4 bg-c5 bg-c6 bg-c7 bg-c8 bg-c9 bg-c10 hidden" />
          {events.length === 0 && (
            <EmptyDataBlock
              title="No events are listed today"
              containerClass="pt-8"
              imgClass="w-28 h-28"
              titleContainerClass="pt-4"
              titleClass="text-xs font-bold"
            />
          )}
          {events.map((item) => (
            <EventItem
              key={item.id}
              title={getTitleFromEvent(item)}
              schoolName={item.division?.name || ''}
              startDate={getDateFromEvent(item, true)}
              endDate={getDateFromEvent(item, false)}
              isFullDay={item.is_all_day}
              location={item.location}
              description={item.description}
              findOurUrl={item.url || ''}
              colorCode={item.event_type?.color}
              parentRef={containerRef}
              containerClass="snap-start shrink-0 w-4/5 md:w-full"
              onViewEvent={() => onViewEvent(item)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventListBlock;
