import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GetSVG, { SVGID } from '../../../Common/GetSVG';
import ArticleCard from './ArticleCard';
import { getArticleById, sendViewTime } from '../../../../actions/mspUser';
import {
  getNumber,
  getYearTags,
  goBackIfUrlStateHavingSearchData,
} from '../../../../helpers/commonHelper';
import OverlayBlock from '../../../Common/overlayBlock';
import { getSelectedRole } from '../../../../actions/AuthUtil';
import ArticleDetail from './ArticleDetail';

interface IProps {
  news: any,
  articleId: number,
  onClose: () => void,
}

const ArticlePopup = (props: React.PropsWithChildren<IProps>) => {
  const {
    news,
    articleId,
    onClose,
  } = props;
  const popupRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [article, setArticle] = useState<any>(null);
  const [thisWeekArticles, setThisWeekArticles] = useState<any>([]);
  const [curIndex, setCurIndex] = useState(0);
  const timeInterval = useRef<any>(null);
  const viewTimer = useRef<any>(null);

  const scrollToTop = () => {
    popupRef?.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.body.classList.add('overflow-sidebar');
    setTimeout(() => {
      document.getElementById('article_popup')!.classList.add('!right-0');
      document.getElementById('article_popup_mask')!.classList.add('!block');
    }, 100);
  }, []);

  const fetchThisWeekArticles = () => {
    if (!news) return [];

    let allNews: any[] = [];
    if (news.heads_message) {
      allNews.push(news.heads_message);
    }
    allNews = [...allNews, ...news.pinned_articles, ...news.other_articles];
    return allNews; // .filter(item => item.id !== articleId);
  };

  const fetchArticleById = (newsId: number, artId: number) => {
    setArticle(null);
    getArticleById(newsId, artId)
      .then((result: any) => {
        setArticle(result?.data);
        let allNews = [];
        if (thisWeekArticles.length === 0) {
          allNews = fetchThisWeekArticles();
          setThisWeekArticles(allNews);
        } else {
          allNews = thisWeekArticles;
        }
        const findIndex = allNews.findIndex((item: any) => item.id === artId);
        if (findIndex > -1) {
          setCurIndex(findIndex);
        }
      })
      .catch(() => {
      });
  };

  const startTimer = () => {
    const selRole = getSelectedRole();
    if (selRole && (selRole.name.trim().toLowerCase() === 'guardian' || selRole.name.trim().toLowerCase() === 'vip') && article) {
      if (getNumber(timeInterval?.current) > 0) {
        window.clearInterval(timeInterval.current);
        viewTimer.current = 0;
      }
      timeInterval.current = window.setInterval(() => {
        viewTimer.current += 1;
      }, 1000);
    }
  };

  const endTimer = () => {
    if (article && timeInterval.current !== 0) {
      window.clearInterval(timeInterval.current);
      if (viewTimer.current > 5) {
        sendViewTime(3, article.id.toString(), viewTimer.current)
          .then((result: any) => {
            console.log(result);
          })
          .catch(() => {
          });
      }
      timeInterval.current = 0;
      viewTimer.current = 0;
    }
  };

  useEffect(() => {
    fetchArticleById(news.id, articleId);
  }, [news?.id, articleId]);

  useEffect(() => {
    startTimer();
    return () => {
      endTimer();
    };
  }, [article]);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-sidebar');
    document.getElementById('article_popup')!.classList.remove('!right-0');
    document.getElementById('article_popup_mask')!.classList.remove('!block');
    setTimeout(() => {
      onClose();
      goBackIfUrlStateHavingSearchData(history);
    }, 200);
  };

  const onTraverseArticle = (nextOrPre: boolean) => {
    let cIndex = curIndex;
    cIndex = nextOrPre ? cIndex + 1 : cIndex - 1;
    if (cIndex >= thisWeekArticles.length) {
      cIndex = 0;
    } else if (cIndex <= -1) {
      cIndex = thisWeekArticles.length - 1;
    }
    fetchArticleById(news?.id, thisWeekArticles[cIndex].id);
  };

  const readMoreSection = false;
  return (
    <>
      <div ref={popupRef} id="article_popup" className="sidebar-bulletin-popup fixed z-[51] bottom-0 top-0 transition-all duration-300 ease-in-out bg-white overflow-y-auto w-full md:w-4/5 lg:w-[36rem] -right-full">
        <div className="sidebar-top-action py-2 px-2 sticky z-50 top-0 flex items-center font-semibold text-sm text-slate-900  bg-white ring-1 ring-slate-900/10">
          <button type="button" className="flex items-center group ml-auto" onClick={onClosePopup}>
            <span className="p-1 hover:text-themered group-hover:text-themered font-bold">Close</span>
            <div className="h-6 w-6 text-black relative flex items-center justify-center hover:bg-themered hover:bg-opacity-5 text-sm font-light rounded-md cursor-pointer">
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
            </div>
          </button>
        </div>
        {article && (
          <ArticleDetail
            article={article}
            showShareBtn
            showGalleryBlock
            parentRef={popupRef}
          />
        )}
        {article && thisWeekArticles.length > 1 && (
          <div className="flex justify-between items-center mb-6 px-5 md:px-12">
            <button
              type="button"
              onClick={() => onTraverseArticle(false)}
              className={`flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 ${curIndex <= 0 ? 'invisible' : ''}`}
            >
              <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light ">
                <GetSVG ID={SVGID.LEFTARROW_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
              </div>
              <h3 className="p-1 hover:text-themered group-hover:text-themered">Previous</h3>
            </button>
            <button
              type="button"
              onClick={() => onTraverseArticle(true)}
              className={`flex items-center group rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 ${curIndex >= thisWeekArticles.length - 1 ? 'invisible' : ''}`}
            >
              <h3 className="p-1 hover:text-themered group-hover:text-themered">Next</h3>
              <div className="h-8 w-8 text-black relative flex items-center justify-center text-sm font-light ">
                <GetSVG ID={SVGID.RIGHTARROW_ICON} svgClass="h-4 w-4 fill-themered hover:fill-themered group-hover:fill-themered" />
              </div>
            </button>
          </div>
        )}
        {(thisWeekArticles.length > 0 && readMoreSection) && (
          <>
            <div className="border border-themered my-10" />
            <div>
              <h2 className="pb-6">Read more from this week</h2>
              <div className="pb-6 grid gap-4 grid-cols-1">
                {thisWeekArticles.map((item: any) => (
                  <ArticleCard
                    onClickCard={() => scrollToTop()}
                    key={item.id}
                    containerClass="flex flex-row h-full rounded-lg lg:rounded-xl"
                    bodyClass="p-4 flex-col flex w-full h-full"
                    titleClass="h4"
                    imgClass="min-h-[8rem] h-full w-32"
                    imgData={item.header_image}
                    title={item.title}
                    category={item.subject?.name}
                    minRead={item.time_to_read}
                    yearTags={getYearTags(item.years, item.division, item.total_year_count)}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <OverlayBlock
        id="article_popup_mask"
        overlayZIndex={50}
        onClickOverlay={onClosePopup}
      />
    </>
  );
};

export default ArticlePopup;
