import React from 'react';
import ArticleCard from './ArticleCard';
import { getYearTags } from '../../../../helpers/commonHelper';

interface IProps {
  news: any,
  activeYears: string[],
  activeSubjects: string[],
  onClickCard: (articleId: number) => void,
}

const ArticleNormalView = (props: React.PropsWithChildren<IProps>) => {
  const {
    news,
    activeYears,
    activeSubjects,
    onClickCard,
  } = props;

  if (activeYears.length > 0 || activeSubjects.length > 0) {
    return null;
  }

  const headMessage = news?.heads_message || null;
  const pinnedArticles: any[] = news?.pinned_articles || [];
  const otherArticles: any[] = news?.other_articles || [];

  const xxlCol = 1;
  const xlCol = 1;
  const lgCol = 1;
  const mdCol = 1;
  const smCol = 1;
  const otherCol = 1;

  return (
    <>
      <div className="main-content w-auto lg:w-3/5">
        <div className="w-full pb-4">
          {headMessage && (
            <ArticleCard
              onClickCard={() => onClickCard(headMessage.id)}
              containerClass="block rounded-2xl lg:rounded-3xl"
              headClass="block ratio ratio-16x9"
              bodyClass="p-5 flex-auto"
              imgClass="w-full object-center"
              imgData={headMessage.header_image}
              title={headMessage.title}
              category="Head's Message"
              minRead={headMessage.time_to_read}
              yearTags={getYearTags(
                headMessage.years,
                headMessage.division,
                headMessage.total_year_count,
              )}
            />
          )}
        </div>
        <div className="grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 sm:grid-cols-1 sm:grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 xl:grid-cols-1 xl:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-1 2xl:grid-cols-2 2xl:grid-cols-3 2xl:grid-cols-4 hidden" />
        <div className={`pb-6 grid gap-4 grid-cols-${otherCol} sm:grid-cols-${smCol} md:grid-cols-${mdCol} lg:grid-cols-${lgCol} xl:grid-cols-${xlCol} 2xl:grid-cols-${xxlCol}`}>
          {pinnedArticles.map(item => (
            <ArticleCard
              onClickCard={() => onClickCard(item.id)}
              key={item.id}
              containerClass="flex flex-col h-full rounded-lg lg:rounded-xl"
              headClass="block flex-shrink-0"
              bodyClass="p-4 flex-col flex w-full h-full"
              titleClass="h4"
              imgClass="h-40 object-center w-full"
              imgData={item.header_image}
              title={item.title}
              category={item.subject?.name}
              minRead={item.time_to_read}
              yearTags={getYearTags(item.years, item.division, item.total_year_count)}
            />
          ))}
        </div>
      </div>
      <div className="sidebar-content flex-none w-auto lg:w-2/5 sticky">
        {otherArticles.length > 0 && <h2 className="pb-2">Other news</h2>}
        <div className="pb-6 flex space-y-4 flex-col lg:pr-2 lg:rounded-xl">
          {otherArticles.map(item => (
            <div key={item.id}>
              <ArticleCard
                onClickCard={() => onClickCard(item.id)}
                containerClass="flex flex-row h-full rounded-lg lg:rounded-xl"
                headClass="flex-shrink-0"
                bodyClass="p-4 flex-col flex w-full"
                titleClass="h4"
                imgClass="object-center min-h-[8rem] max-h-[8rem] h-full w-32"
                imgData={item.header_image}
                title={item.title}
                category={item.subject?.name}
                minRead={item.time_to_read}
                yearTags={getYearTags(item.years, item.division, item.total_year_count)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ArticleNormalView;
