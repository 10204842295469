import React, { ComponentProps, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import { UISidebarType } from '../../store/actiontypes/uiActionTypes';
import NotificationBar from '../Layout/NotificationBar/NotificationBar';
import SearchBar from '../Layout/SearchBar/SearchBar';
import { RootState } from '../../store/reducers';
import {
  getAuthToken,
  getSelectedMSP,
  getSelectedRole,
  saveSelectedMSP,
  saveSelectedRole,
} from '../../actions/AuthUtil';
import IUserDataStates from '../../interfaces/userDataStates';
import { getCurrentUser } from '../../actions/mspUser';
import * as userDataActionCreators from '../../store/actioncreators/userDataActionCreators';
import { getUserMSP, isPageAccesible } from '../../helpers/roleHelper';
import { toggleRightSidebar } from '../../helpers/uiHelper';
import RoleSwitcher from '../Pages/RoleSwitcher/RoleSwitcher';
import FavouriteBar from '../Layout/FavouriteBar/FavouriteBar';

interface ILayoutManager {}
const withLayoutManager = <T extends ILayoutManager>(WrappedComponent: React.ComponentType<T>) => {
  const ComponentWithExtraInfo = (props:ComponentProps<any>) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const pathName = location?.pathname || '/';

    const sidebarName: string = useSelector(
      (state: RootState) => state.ui.sidebarName,
    );
    const userData: IUserDataStates | null = useSelector(
      (state: RootState) => state.userData,
    );

    const onSelectRole = (role: any) => {
      const { setUserData } = bindActionCreators(userDataActionCreators, dispatch);
      saveSelectedRole(role.id, role.name);
      if (userData) {
        // eslint-disable-next-line prefer-destructuring
        userData.selectedRole = role;
        getUserMSP(role.name || '')
          .then((res: any) => {
            saveSelectedMSP(res);
            userData.selectedMSP = res;
            setUserData(userData);
          })
          .catch(() => {
            setUserData(userData);
          });
      }
    };

    useEffect(() => {
      const token = getAuthToken();
      if (!token) {
        history.push('/login', { prevPath: window.location.pathname });
        return;
      }

      if (!userData) {
        getCurrentUser()
          .then((result: any) => {
            const { setUserData } = bindActionCreators(userDataActionCreators, dispatch);
            const curUserData: IUserDataStates = result.data as IUserDataStates;
            const selRole = getSelectedRole();
            if (!selRole) {
              if (curUserData.roles.length === 1) {
                saveSelectedRole(curUserData.roles[0].id, curUserData.roles[0].name);
                // eslint-disable-next-line prefer-destructuring
                curUserData.selectedRole = curUserData.roles[0];
              }
            } else {
              // eslint-disable-next-line prefer-destructuring
              curUserData.selectedRole = selRole;
            }
            getUserMSP(curUserData.selectedRole?.name || '')
              .then((res: any) => {
                saveSelectedMSP(res);
                curUserData.selectedMSP = res;
                setUserData(curUserData);
              })
              .catch(() => {
                setUserData(curUserData);
              });
          })
          .catch(() => {

          });
      } else {
        const selRole = getSelectedRole();
        if (!selRole && userData.selectedRole) {
          saveSelectedRole(userData.selectedRole.id, userData.selectedRole.name);
        }

        const selMSP = getSelectedMSP();
        if (!selMSP && userData.selectedMSP) {
          saveSelectedMSP(userData.selectedMSP);
        }
      }
    }, []);

    useEffect(() => {
      if (userData
        && userData?.selectedRole
        && !isPageAccesible(pathName, userData?.selectedRole.name)
      ) {
        history.push('/access-denied');
      }

      toggleRightSidebar(
        UISidebarType.CLOSE_SIDEBAR,
        document,
        dispatch,
      );
    }, [location, userData]);

    const token = getAuthToken();
    if (!token || !userData) {
      return null;
    }

    if (!userData?.selectedRole) {
      return (
        <RoleSwitcher roles={userData.roles} onSelectRole={(role) => onSelectRole(role)} />
      );
    }

    if (!isPageAccesible(pathName, userData?.selectedRole.name)) {
      return null;
    }

    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Header {...props} role={userData?.selectedRole?.name?.toLowerCase() || ''} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Sidebar {...props} role={userData?.selectedRole?.name?.toLowerCase() || ''} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WrappedComponent {...props} />
        {sidebarName === UISidebarType.NOTIFICATION_SIDEBAR && <NotificationBar />}
        {sidebarName === UISidebarType.SEARCH_SIDEBAR && <SearchBar />}
        {sidebarName === UISidebarType.SEARCH_WITHOUTINPUT_SIDEBAR && <SearchBar withoutInput />}
        {sidebarName === UISidebarType.FAVOURITE_SIDEBAR && <FavouriteBar />}
      </>
    );
  };
  return ComponentWithExtraInfo;
};

export default withLayoutManager;
