import { getCurrentUserDivisions, getCurrentUserStudents } from '../actions/mspUser';

export const isPageAccesible = (url: string, role: string) => {
  const userRole = role?.toLowerCase();
  switch (url) {
    case '/':
    case '/home':
      return true;
    case '/need-to-know':
      return userRole !== 'vip';
    case '/highlights':
      return userRole !== 'student 11+';
    case '/news':
      return true;
    case '/calendar':
      return true;
    case '/gallery':
      return userRole === 'guardian';
    default:
      return true;
  }
};

export function getUserMSP(role: string): Promise<any> {
  return new Promise((resolve) => {
    if (!role) {
      resolve(['']);
    } else if (role.toLowerCase() === 'staff' || role.toLowerCase() === 'student 11+') {
      getCurrentUserDivisions()
        .then((result: any) => {
          const divs: string[] = [];
          result?.data.forEach((item: any) => {
            if (!divs.includes(item?.name)) {
              divs.push(item?.name);
            }
          });
          resolve(divs);
        })
        .catch(() => {
          resolve(['']);
        });
    } else if (role.toLowerCase() === 'vip' || role.toLowerCase() === 'guardian') {
      getCurrentUserStudents()
        .then((result: any) => {
          const divs: string[] = [];
          result?.data.forEach((item: any) => {
            if (item?.divisions?.length > 0 && !divs.includes(item?.divisions[0]?.name)) {
              divs.push(item?.divisions[0]?.name);
            }
          });
          resolve(divs);
        })
        .catch(() => {
          resolve(['']);
        });
    }
  });
}
