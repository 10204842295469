import React from 'react';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  index: number;
  id: number;
  imagePath: string;
  isVideo: boolean;
  onClickImage: (id: number) => void
}

const GalleryImage = (props: React.PropsWithChildren<IProps>) => {
  const {
    index,
    id,
    imagePath,
    isVideo,
    onClickImage,
  } = props;

  const onImageClick = (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>, imgId: number) => {
    event.preventDefault();
    onClickImage(imgId);
  };

  const showIndex = false;
  return (
    <div className="group relative block overflow-hidden rounded-lg lg:rounded-2xl drop-shadow-xl">
      <a href="#" onClick={(e) => onImageClick(e, id)} className="overflow-hidden block rounded-2xl">
        <img
          className="w-full transition-all duration-500 ease-in-out scale-100 group-hover:scale-105 focus-hover:scale-105"
          src={imagePath}
          alt="title"
        />
        {isVideo && (
          <span className="post-play absolute inset-0 flex items-center justify-center transition ease-in-out delay-150 hover:scale-110  duration-300">
            <GetSVG ID={SVGID.VIDEOPLAY_ICON} />
          </span>
        )}
        <div className="absolute inset-0 bg-navy bg-opacity-0 text-white text-center flex justify-center items-center w-full transition-all ease-in-out duration-500 group-hover:bg-opacity-70">
          <span className="flex justify-center items-center gap-2 font-bold text-xl -translate-y-6 transition-all ease-in-out duration-500 opacity-0 group-hover:translate-y-0 group-hover:opacity-100">
            Download
            <i className="i- i-download-white i-18px flex-none" />
          </span>
        </div>
      </a>
      {showIndex && (
        <div className="absolute right-3 bottom-3 transition-all ease-in-out duration-500 group-hover:-bottom-10">
          <a href="#" title="Click to Download" className="h-8 w-8 bg-white text-black flex items-center justify-center px-2 py-2 text-sm font-light rounded-md cursor-pointer shadow-xl transition-all duration-500 ease-in-out scale-100 opacity-75 hover:scale-105 hover:opacity-100 focus:scale-105 focus:opacity-100">
            {index + 1}
          </a>
        </div>
      )}
      {!showIndex && (
        <div className="absolute right-3 bottom-3 transition-all ease-in-out duration-500 group-hover:-bottom-10">
          <div title="Click to Download" className="h-8 w-8 bg-white text-black flex items-center justify-center px-2 py-2 text-sm font-light rounded-md cursor-pointer shadow-xl transition-all duration-500 ease-in-out scale-100 opacity-75 hover:scale-105 hover:opacity-100 focus:scale-105 focus:opacity-100">
            <i className="i- i-download flex-none" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryImage;
