import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSelectedRole } from '../../actions/AuthUtil';
import { isPageAccesible } from '../../helpers/roleHelper';

interface IProps {
  title: string;
  description?: string;
  routePath: string;
  image: string;
  imgClass: string;
}

const FeatureBlock = (props: React.PropsWithChildren<IProps>) => {
  const {
    title,
    description,
    routePath,
    image,
    imgClass,
  } = props;
  const [role, setRole] = useState('');

  useEffect(() => {
    const selRole = getSelectedRole();
    if (selRole) {
      setRole(selRole?.name || '');
    }
  }, []);

  if (!isPageAccesible(routePath, role)) {
    return null;
  }

  return (
    <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-6">
      <Link to={routePath} className="card-hero is-hover-hero flex justify-between items-center mb-0 group">
        <div className="relative z-1 grow px-4 py-6 lg:px-7 lg:py-10">
          <div className="flex gap-4">
            <h2>{title}</h2>
            <i className="i- i-long-arrow flex-none transition-all group-hover:translate-x-1 group-focus:translate-x-1 group-focus-visible:translate-x-1" />
          </div>
          {description && <div className="text-xs pt-3">{description}</div>}
        </div>
        <div className="relative z-1 flex-none px-4 py-6 lg:px-7 lg:py-10 w-30 lg:w-56 flex items-center justify-center h-full">
          <img src={image} alt={title} className={imgClass} />
        </div>
      </Link>
    </div>
  );
};

FeatureBlock.defaultProps = {
  description: '',
};

export default FeatureBlock;
