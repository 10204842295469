import React from 'react';
// import { Link } from 'react-router-dom';
import tomcomImg from '../../assets/img/tomcom-unicorn-logo.svg';
import GetMetaHeader, { PAGEID } from '../Common/seoHelper';

const PaymentConfirmed = () => (
  <>
    <GetMetaHeader page={PAGEID.PAGE_PAYMENTCONFIRMED} />
    <section className="max-w-3xl flex justify-center mx-auto items-center min-h-screen relative sm:py-4 bg-white sm:bg-transparent">
      <div className="bg-white sm:drop-shadow-xl sm:rounded-3xl">
        <div className="flex flex-col items-center 2xl:py-16 xl:py-10 2xl:px-16 xl:px-10 py-14 px-4 relative">
          <img src={tomcomImg} alt="TomCom" />
          <h1 className="text-2xl font-bold text-navy pt-4 pb-6 max-w-sm text-center lg:text-3xl lg:leading-10">
            Payment Confirmed
          </h1>
          <p className="text-sm text-center font-bold lg:text-lg">
            You may now close this window
            <br />
            and return to Tomcom
          </p>
        </div>
      </div>
    </section>
  </>
);

export default PaymentConfirmed;
