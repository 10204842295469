import React from 'react';
import { Link } from 'react-router-dom';
import { markNotificationAsRead } from '../../../actions/mspUser';
import { formatDate } from '../../../helpers/commonHelper';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  id: string;
  title: string;
  publishDate: string;
  description: string;
  itemRoutePath: any;
  SVGId: string;
  isRead: boolean;
  isAllRead: boolean;
}

const NotificationBarItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    title,
    publishDate,
    description,
    itemRoutePath,
    SVGId,
    isRead,
    isAllRead,
  } = props;

  const onClickItem = () => {
    if (!isRead) {
      markNotificationAsRead(id);
    }
  };

  return (
    <div className="border-b">
      <Link
        to={itemRoutePath}
        onClick={onClickItem}
        className="flex gap-3.5  items-center px-5 py-4 outline-0 ring-inset ring-2 ring-transparent hover:bg-themered hover:bg-opacity-5 focus-visible:ring-black"
      >
        {!isAllRead && <div className={`w-3 h-3 rounded-full flex-none ${isRead ? '' : 'bg-themered'}`} />}
        <div>
          <GetSVG ID={SVGId} />
        </div>
        <div className="grow">
          <div className="flex flex-between content-center">
            <h3 className="text-themered text-sm font-bold">{title}</h3>
            <span className="text-themered text-xs font-medium ml-auto">
              {formatDate(publishDate, 'yyyy-MM-DD HH:mm', 'DD MMM yyyy HH:mm')}
            </span>
          </div>
          <p className="text-xs font-bold">{description}</p>
        </div>
        <div className="ml-auto">
          <GetSVG ID={SVGID.RIGHTARROW_ICON} />
        </div>
      </Link>
    </div>
  );
};

export default NotificationBarItem;
