import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useSelector } from 'react-redux';
import htmlTruncate from 'html-truncate';
import withLightBox from '../HOC/withLightBox';
import GetSVG, { SVGID } from './GetSVG';
import { animateOnMouseMove } from '../../helpers/uiHelper';
import { getVideoThumbnailByUrl, sendViewTime } from '../../actions/mspUser';
import { formatDate, getNumber, getYearTags } from '../../helpers/commonHelper';
import { RootState } from '../../store/reducers';
import { getSelectedRole } from '../../actions/AuthUtil';

interface IProps {
  highlight: any;
  containerClass: string;
}

const HighlightItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    highlight,
    containerClass,
  } = props;
  const containerRef = useRef(null);
  const timeInterval = useRef<any>(null);
  const viewTimer = useRef<any>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [curSelIndex, setCurSelIndex] = useState(0);
  const [videoThumb, setVideoThumb] = useState('');
  const highlightMsg = highlight.message?.replace(/<p>/g, '<span>')?.replace(/<\/p>/g, '</span>').replace(/<br>/g, '<br/>');
  const truncatedHtml = htmlTruncate(highlightMsg, 150);
  const isTruncated = highlightMsg !== truncatedHtml;

  const title: string[] = getYearTags(
    [],
    highlight.division,
    0,
  );
  const gToggleLightbox: boolean = useSelector(
    (state: RootState) => state.ui.toggleLightbox,
  );

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
    animateOnMouseMove(e, containerRef?.current);
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const startTimer = () => {
    const selRole = getSelectedRole();
    if (selRole && (selRole.name.trim().toLowerCase() === 'guardian' || selRole.name.trim().toLowerCase() === 'vip') && highlight) {
      if (getNumber(timeInterval?.current) > 0) {
        window.clearInterval(timeInterval.current);
        viewTimer.current = 0;
      }
      timeInterval.current = window.setInterval(() => {
        viewTimer.current += 1;
      }, 1000);
    }
  };

  const endTimer = () => {
    if (highlight && timeInterval.current !== 0) {
      window.clearInterval(timeInterval.current);
      if (highlight && viewTimer.current >= 2) {
        sendViewTime(1, highlight.id, viewTimer.current)
          .then((result: any) => {
            console.log(result);
          })
          .catch(() => {
          });
      }
      timeInterval.current = 0;
      viewTimer.current = 0;
    }
  };

  const onHighlightMouseMoveEvent = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
    if (e?.type === 'mouseenter') {
      startTimer();
    } else if (e?.type === 'mouseleave' && !gToggleLightbox) {
      endTimer();
    }
  };

  const isImage = highlight.asset_type === 'image';
  const hImages = highlight.images || [];
  const totalAssets = isImage ? hImages.length : 1;
  const galleryId = `highlight_postimg${highlight.id}`;
  const splideOptions = {
    type: 'slide',
    arrowPath: 'M30.2389 19.2026L11.3695 0.333211C10.9252 -0.11107 10.2127 -0.11107 9.76841 0.333211C9.32413 0.777493 9.32413 1.49002 9.76841 1.9343L27.8331 19.999L9.76841 38.0636C9.32413 38.5079 9.32413 39.2204 9.76841 39.6647C9.98636 39.8826 10.2797 40 10.5648 40C10.8498 40 11.1432 39.891 11.3611 39.6647L30.2305 20.7953C30.6748 20.3594 30.6748 19.6385 30.2389 19.2026Z',
    perPage: 1,
    gap: '1rem',
    pagination: totalAssets > 1,
    arrows: totalAssets > 1,
  };

  /* const handleResize = () => {
    let top = 0;
    let oldDotEl: any;
    let oldSpanEl: any;
    let heightEl = 0;
    for (let i = 0; i < title.length; i += 1) {
      const spanEl = document.getElementById(`${highlight.id}-spantag-${i}`);
      const dotEl = document.getElementById(`${highlight.id}-dottag-${i}`);
      if (spanEl) {
        const elTop = spanEl.getBoundingClientRect().top;
        const elHeight = spanEl.getBoundingClientRect().height;
        if (top === 0) {
          top = elTop;
          oldDotEl = dotEl;
          oldSpanEl = spanEl;
          heightEl = elHeight;
        }

        if (oldDotEl) {
          oldDotEl.style.visibility = 'visible';
        }
        if (top !== elTop) {
          if (oldDotEl && oldSpanEl.getBoundingClientRect().height === heightEl) {
            oldDotEl.style.visibility = 'hidden';
          }
        }
        top = elTop;
        oldDotEl = dotEl;
        oldSpanEl = spanEl;
      }
    }
  }; */

  useEffect(() => {
    const videoUrl = highlight.video_url;
    if (videoUrl) {
      getVideoThumbnailByUrl(videoUrl)
        .then((res:any) => {
          if (res) {
            setVideoThumb(res);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }

    /* handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }; */
  }, []);

  useEffect(() => {
    if (!gToggleLightbox && timeInterval.current !== 0) {
      endTimer();
    }
  }, [gToggleLightbox]);

  let assetData = [];
  if (isImage) {
    // eslint-disable-next-line arrow-body-style
    assetData = hImages.map((item: any) => {
      return {
        id: item.id,
        isImage,
        thumbImg: item.high_path,
        highImg: item.high_path,
      };
    });
  } else if (highlight.video) {
    assetData = [{
      id: highlight.id,
      isImage: false,
      thumbImg: highlight.video.high_path,
      highImg: highlight.video.file_path,
    }];
  } else if (highlight.video_url) {
    assetData = [{
      id: highlight.id,
      isImage: false,
      thumbImg: '',
      highImg: highlight.video_url,
    }];
  }

  return (
    <div
      className={containerClass}
      onMouseEnter={onHighlightMouseMoveEvent}
      onMouseLeave={onHighlightMouseMoveEvent}
    >
      <div className="post-card-slider block relative overflow-hidden rounded-lg shadow-lg bg-white h-full">
        <Splide
          className="slider-block is-card-slider"
          hasTrack={false}
          options={splideOptions}
          onMove={(splide, newIndex) => { setCurSelIndex(newIndex); }}
        >
          <SplideTrack>
            {assetData.map((item: any) => (
              <SplideSlide key={item.id}>
                <a href={item.highImg} className="post-card-head is-card-zoom block h-56 relative overflow-hidden glightbox" data-gallery={galleryId}>
                  {!item.isImage && (
                    <span className="post-play absolute z-1 inset-0 flex items-center justify-center transition ease-in-out delay-150 hover:scale-110  duration-300">
                      <GetSVG ID={SVGID.VIDEOPLAY_ICON} />
                    </span>
                  )}
                  {(item.thumbImg || videoThumb) && (
                    <img className="object-contain w-full h-56 is-img-zoom" src={item.thumbImg || videoThumb} alt="Highlight" />
                  )}
                </a>
              </SplideSlide>
            ))}
          </SplideTrack>
          {assetData.length > 1 && (
            <span
              className="post-badge is-card-slide-count --is-not-hover absolute right-4 top-4 rounded-full px-3 py-1.5 bg-black bg-opacity-70 text-white font-medium text-xs"
            >
              {`${curSelIndex + 1}/${assetData.length}`}
            </span>
          )}
        </Splide>
        <div
          ref={containerRef}
          className="post-card-body block p-4 flex-auto postimg1-desc hover:text-white focus:text-white transition duration-500 ease-in-out is-hover"
          onMouseMove={onMouseMoveEvent}
          onMouseLeave={onMouseMoveEvent}
        >
          <div className="mt-auto pb-2" style={{ lineHeight: '14px' }}>
            {title.map((item: string, index: number) => (
              <span className="text-sm font-bold" key={item} id={`${highlight.id}-spantag-${index}`}>
                {item}
                {title.length - 1 !== index && <div className="w-1 h-1 bg-themered rounded-full flex-none inline-block align-middle mx-2" id={`${highlight.id}-dottag-${index}`} />}
              </span>
            ))}
          </div>
          <p className="text-xs font-medium pb-1">
            {formatDate(highlight.published_at, 'yyyy-MM-DD HH:mm', 'DD MMM yyyy HH:mm')}
          </p>
          <div className="text-sm post-detail-info">
            <span
              dangerouslySetInnerHTML={{ __html: isExpanded ? highlight.message : truncatedHtml }}
            />
            {(isTruncated && !isExpanded) && (
              <button type="button" onClick={toggleExpand} className="ml-1 text-themered underline text-xs font-semibold">
                Read More
              </button>
            )}
          </div>
        </div>
      </div>
      {/* imgPaths.length > 1 && (
        <span className="hidden">
          {imgPaths.slice(1).map((item, index) => (
            <a
              key={item}
              href={item}
              className="glightbox"
              data-gallery={galleryId}
              data-glightbox={index === 0 ? 'description: .postimg1-desc' : ''}
            >
              {index + 1}
            </a>
          ))}
        </span>
      ) */}
    </div>
  );
};

export default withLightBox(HighlightItem);
