import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import ActionError from './ActionError';
import ActionSuccess from './ActionSuccess';
import { getAuthToken } from './AuthUtil';
import config from '../config/config';
import { logoutUser } from './mspUser';

export interface IRestRequest {
  url: string,
  method?: string,
  headerObj?: any,
  body?: any,
  authEnabled?: boolean,
  cors?: boolean
}

export const Request = ({
  url,
  method = 'GET',
  headerObj = null,
  body = null,
  authEnabled = false,
  cors = true,
}: IRestRequest) => {
  const requestObject: any = {};
  requestObject.authEnabled = authEnabled;
  requestObject.execute = (tokenString = '') => {
    if (!url) {
      throw new Error('Url is not specified');
    }
    const headers = headerObj ? new Headers(headerObj) : new Headers();
    if (authEnabled) {
      headers.append('Authorization', tokenString);
    }
    // eslint-disable-next-line
    let requestParams: RequestInit = {
      method,
      headers,
      mode: 'cors',
      body: null,
    };
    if (cors) {
      requestParams.mode = 'cors';
    } else {
      requestParams.mode = 'no-cors';
    }

    if (body) {
      requestParams = { ...requestParams, body };
    }
    return fetch(url, requestParams);
  };
  return requestObject;
};

export function restCall(request:any) {
  if (request.authEnabled) {
    const authToken = getAuthToken();
    return request.execute(`Bearer ${authToken}`);
  }
  return request.execute();
}

export function genericHttpSuccessHandler(resolve: any) {
  return (actionSuccess: any) => {
    if (resolve && actionSuccess instanceof ActionSuccess) {
      resolve({ actionDataObj: actionSuccess });
    }
  };
}

export function genericHttpErrorHandler(reject: any) {
  return (actionError: any) => {
    if (reject) {
      if (actionError instanceof ActionError) {
        reject({ actionDataObj: actionError });
      } else {
        reject(actionError);
      }
    }
  };
}

export function handleHttpResponse(httpResponse: any, successHandler: any, errorHandler: any) {
  if (httpResponse) {
    // handle codes for which json may not come in response.
    if (httpResponse.status >= 500 && httpResponse.status <= 505) {
      const clientMessage = 'Server is unable to fulfill request at the moment. Please try again later or contact support';
      errorHandler(new ActionError(httpResponse.status, clientMessage));
    } else if (httpResponse.status === 200
        || (httpResponse.status >= 400 && httpResponse.status <= 404)
        || httpResponse.status === 422) {
      // Get the json data out of the response and handle
      httpResponse
        .json()
        .then((jsonData: any) => {
          if (httpResponse.status === 200) {
            successHandler(new ActionSuccess('', jsonData));
          } else if (httpResponse.status >= 400 && httpResponse.status <= 404) {
            if (httpResponse.status === 401) {
              logoutUser()
                .then(() => {
                  window.location.href = '/login';
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            errorHandler(new ActionError(httpResponse.status, 'Error', jsonData));
          } else if (httpResponse.status >= 422) {
            errorHandler(new ActionError(httpResponse.status, jsonData?.message, jsonData));
          }
        })
        .catch((error: any) => {
          if (httpResponse.status === 404) {
            errorHandler(new ActionError(httpResponse.status, 'Not found'));
          } else {
            throw error;
          }
        });
    } else {
      errorHandler(
        new ActionError(httpResponse.status, 'Error in executing request'),
      );
    }
  }
}

export const errorHandler = (error: AxiosError) => {
  const response: any = error?.response;
  if (response) {
    if (response?.status === 401) {
      logoutUser()
        .then(() => {
          window.location.href = '/login';
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (response?.status === 403) {
      let toastMessage = '';
      const responseMessage = response?.data?.message?.toLowerCase() || '';
      if (responseMessage === 'notassigneddivision') {
        toastMessage = 'Division is not assigned';
      } else if (responseMessage === 'notassignedrole') {
        toastMessage = 'Role is not assigned';
      } else {
        toastMessage = response?.data?.message || '';
      }
      toast.error(toastMessage);
    } else if (response?.status === 404) {
      toast.error('Not Found');
    } else if (response?.status >= 500 && response?.status <= 505) {
      toast.error('Server is unable to fulfill request at the moment. Please try again later or contact support');
    }
  }
};

export const endpoint = (urlSuffix: string) => {
  const apiServer = config.API_SERVER_URL;
  return `${apiServer}/api/${urlSuffix}`;
};
