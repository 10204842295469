import React, { useEffect, useState } from 'react';
import { getMagazines } from '../../../../actions/mspUser';
import MagazineCard from '../../../Common/MagazineCard';

const MagazineSection = () => {
  const [magazineItems, setMagazineItems] = useState<any[]>([]);

  useEffect(() => {
    getMagazines(-1)
      .then((result: any) => {
        setMagazineItems(result?.data || []);
      })
      .catch(() => {
      });
  }, []);

  if (!magazineItems || magazineItems.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mb-2 md:block hidden">
        <div className="flex justify-between items-center">
          <h2 className="mb-2">Thomas’s Magazine</h2>
        </div>
        <p>
          Celebrating the last academic year.
        </p>
      </div>
      <div className="-mx-4 mb-6 lg:mb-0">
        <div className="snap-x snap-always scroll-px-4 overflow-x-auto pt-3 px-4 mb-3 pb-3 flex flex-row gap-3 lg:gap-5 lg:pb-3 lg:grid lg:grid-cols-2 xl:grid-cols-2">
          <div className="lg:hidden snap-start shrink-0 w-2/5">
            <div className=" bg-navy rounded-2xl shadow-lg p-4 h-full flex flex-col">
              <div className="flex-auto">
                <h3 className="text-white text-lg font-bold">Thomas’s Magazine</h3>
                <div className="text-white text-xs font-light pt-2">
                  <p>
                    Celebrating the last academic year.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {magazineItems.map((item) => (
            <div key={item.id} className="snap-start shrink-0 w-2/5 lg:w-full">
              <MagazineCard
                issueDate={item.issue_date}
                schoolName={item.division?.name}
                routePath={item.pdf}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MagazineSection;
