import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import GalleryCheckoutForm from './GalleryCheckoutForm';
import { createPaymentIntent } from '../../../actions/mspUser';
import config from '../../../config/config';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';

interface IProps {
  media: any[],
  onSuccess: () => void;
  onClose: () => void;
}

const CheckoutModal = (props: React.PropsWithChildren<IProps>) => {
  const {
    media,
    onSuccess,
    onClose,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const stripePromise = loadStripe(config.STRIPE_PUB_KEY);
  const [payIntent, setPayIntent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('checkout-modal')!.classList.add('active');
    }, 100);

    if (media.length <= 0) {
      return;
    }

    setLoading(true);
    createPaymentIntent(media.length * config.PHOTO_COST * 100)
      .then(async (res) => {
        // @ts-ignore
        const { paymentIntent } = res;
        // const client_secret = res?.paymentIntent;
        setPayIntent(paymentIntent);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('checkout-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const appearance: any = {
    variables: {
      fontFamily: 'Poppins, sans-serif',
      colorPrimary: '#D10027',
    },
    rules: {
      '.Input': {
        borderColor: '#D10027',
      },
    },
  };

  // eslint-disable-next-line dot-notation
  appearance['labels'] = 'floating';

  const options = {
    // passing the client secret obtained from the server
    clientSecret: payIntent,
    /* customerOptions: { customer: customerId, ephemeralKey: ephemeral }, */
    appearance,
  };

  const onSuccessPayment = (orderId: string) => {
    onSuccess();
    toggleRightSidebar(UISidebarType.BASKET_SIDEBAR, document, dispatch);
    onClose();
    setTimeout(() => history.push(`/my-account?order=${orderId}`), 500);
  };

  return (
    <div id="checkout-modal" className="modal">
      <div className="modal-card">
        <div className="modal-card-body !rounded-xl !shadow-md max-w-sm">
          <div className="modal-card-head text-right relative">
            <button
              type="button"
              className="absolute md:relative -right-3 -top-3 md:-right-6 md:-top-4 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
              onClick={onClosePopup}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
            </button>
          </div>
          <div className="text-center sm:pb-6">
            <h2 className="mb-3">Checkout</h2>
            <div className="w-full justify-center items-center flex">
              {loading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden item-center animate-spin flex-none h-16 w-16 text-themered" />}
            </div>
            {payIntent && (
              <Elements stripe={stripePromise} options={options}>
                <GalleryCheckoutForm
                  media={media}
                  onSuccess={(orderId) => onSuccessPayment(orderId)}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
      <div className="modal-background" />
    </div>
  );
};

export default CheckoutModal;
