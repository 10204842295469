import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HighlightItem from '../../../Common/HighlightItem';
import { getHighlights } from '../../../../actions/mspUser';
import { getSelectedRole } from '../../../../actions/AuthUtil';
import { isPageAccesible } from '../../../../helpers/roleHelper';

const HighlightSection = () => {
  const [highlightItems, setHighlightItems] = useState<any[]>([]);
  const [role, setRole] = useState('');

  useEffect(() => {
    const selRole = getSelectedRole();
    if (selRole) {
      setRole(selRole?.name || '');
    }

    getHighlights()
      .then((result: any) => {
        setHighlightItems(result?.data);
      })
      .catch(() => {
      });
  }, []);

  if (!isPageAccesible('/highlights', role)) {
    return null;
  }

  return (
    <div className="sidebar-content bg-themebody-bg -mx-4 lg:mx-0 p-4 lg:p-6 flex-none w-auto lg:w-[22rem] max-h-full ">
      <div className="lg:fixed lg:w-80 -mx-4">
        <div className="flex justify-between items-center mb-4 px-4 lg:pr-0">
          <h2 className="mr-4">Highlights</h2>
          <Link
            to="/highlights"
            className="underline underline-offset-4 text-themered text-xs font-semibold p-2 hover:text-black"
          >
            View All
          </Link>
        </div>
        <div className="flex gap-3 snap-x snap-always scroll-px-4 overflow-x-auto pb-4 lg:rounded-lg px-4 mr-0 lg:pr-2 lg:-mr-2 lg:pb-4 lg:max-h-[calc(100vh_-_11rem)] lg:overflow-y-scroll overflow-auto-themered-only lg:flex-wrap">
          {highlightItems.map((item) => (
            <HighlightItem
              key={item.id}
              highlight={item}
              containerClass="snap-start shrink-0 w-4/5 md:w-2/5 lg:w-full"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HighlightSection;
