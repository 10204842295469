import React, { useRef } from 'react';
import { animateOnMouseMove } from '../../../helpers/uiHelper';
import { formatDate } from '../../../helpers/commonHelper';

interface IProps {
  reference: string;
  date: string;
  total: string;
  onClickItem: () => void
}

const OrderItem = (props: React.PropsWithChildren<IProps>) => {
  const {
    reference,
    date,
    total,
    onClickItem,
  } = props;
  const containerRef = useRef(null);

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
    animateOnMouseMove(e, containerRef?.current);
  };

  return (
    <button
      ref={containerRef}
      type="button"
      className="is-hover group p-4 bg-white rounded-lg shadow-md transition-all text-sm w-full mb-3"
      onClick={onClickItem}
      onMouseEnter={onMouseMoveEvent}
      onMouseLeave={onMouseMoveEvent}
    >
      <div className="pb-1 text-left leading-6 group-hover:text-white group-focus:text-white transition duration-500 ease-in-out">
        <strong>Order Reference:</strong>
        {' '}
        {reference}
        <br />
        <strong>Order Date:</strong>
        {' '}
        {formatDate(date, 'DD-MM-yyyy HH:mm:ss', 'DD/MM/yyyy')}
        <br />
        <strong>Total:</strong>
        {' '}
        {`£${total}`}
      </div>
    </button>
  );
};

export default OrderItem;
