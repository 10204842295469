import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { RootState } from '../../store/reducers/index';
import IUserDataStates from '../../interfaces/userDataStates';
import GetSVG, { SVGID } from './GetSVG';
import { getShareLink, sendShareEmail } from '../../actions/mspUser';

interface IProps {
  onClose: (success: boolean) => void,
  contentId: string,
  contentType: string,
  title: string,
  date: string,
  parentRef?: any,
}

const EmailPopup = (props: React.PropsWithChildren<IProps>) => {
  const {
    onClose,
    contentId,
    contentType,
    title,
    date,
    parentRef,
  } = props;

  const [shareUrl, setShareUrl] = useState('');
  const [shareToken, setShareToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const userData: IUserDataStates | null = useSelector(
    (state: RootState) => state.userData,
  );
  const emailInputRef = useRef<HTMLInputElement>(null);

  const getEmailSubject = () => `${title} - ${date}`;
  const getEmailBody = () => (
    <>
      <p>
        {`You are receiving this email because ${userData?.name || ''} has shared a message from Thomas's London Day Schools with you. Please see the message here: ${shareUrl}`}
      </p>
    </>
  );

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    parentRef?.current.classList.remove('overflow-y-auto');
    setTimeout(() => {
      document.getElementById('email-modal')!.classList.add('active');
      emailInputRef?.current?.focus();
    }, 100);
    getShareLink(contentId, contentType)
      .then((linkResult: any) => {
        setShareUrl(linkResult?.url);
        setShareToken(linkResult?.token);
      })
      .catch((err) => console.log(err));
  }, []);

  const onClosePopup = (success: boolean) => {
    document.body.classList.remove('overflow-modal');
    parentRef?.current.classList.add('overflow-y-auto');
    document.getElementById('email-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose(success);
    }, 200);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (shareToken) {
      sendShareEmail(
        email,
        getEmailSubject(),
        ReactDOMServer.renderToString(getEmailBody()),
        shareToken,
      )
        .then(() => {
          setIsLoading(false);
          onClosePopup(true);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div id="email-modal" className="modal" style={{ zIndex: 61 }}>
        <div className="modal-card">
          <div className="modal-card-body max-w-3xl">
            <div className="modal-card-info">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email Address*
                    <input
                      className="shadow appearance-none border rounded mt-1 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      name="email"
                      required
                      autoComplete="off"
                      value={email}
                      onChange={handleEmailChange}
                      ref={emailInputRef}
                    />
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                    Subject
                    <input
                      className="shadow appearance-none border rounded mt-1 w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="subject"
                      type="text"
                      name="subject"
                      value={getEmailSubject()}
                      readOnly
                    />
                  </label>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                    Message
                    <div
                      className="break-anywhere shadow appearance-none border rounded mt-1 w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline overflow-y-auto overflow-x-hidden bg-gray-100 text-sm font-normal"
                      id="message"
                    >
                      {getEmailBody()}
                    </div>
                  </label>
                </div>
                <div className="modal-card-foot pt-2 grid grid-cols-2 gap-4">
                  <button type="button" onClick={() => onClosePopup(false)} className="btn-link">Close</button>
                  <button type="submit" className="btn btn-red">
                    {isLoading && (
                      <GetSVG
                        ID={SVGID.LOADER_ICON}
                        svgClass="motion-reduce:hidden animate-spin flex-none h-5 w-5 mr-4 text-white"
                      />
                    )}
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="modal-background" />
      </div>
    </>
  );
};

EmailPopup.defaultProps = {
  parentRef: null,
};
export default EmailPopup;
