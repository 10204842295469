import React, { useEffect } from 'react';
import config from '../../../config/config';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  onClose: () => void;
  onAddToBasket: () => void;
}

const AddToBasketModal = (props: React.PropsWithChildren<IProps>) => {
  const {
    onClose,
    onAddToBasket,
  } = props;

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('digital-modal')!.classList.add('active');
    }, 100);
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('digital-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const onClickAddToBasket = () => {
    onClosePopup();
    onAddToBasket();
  };

  return (
    <div id="digital-modal" className="modal">
      <div className="modal-card">
        <div className="modal-card-body !rounded-xl !shadow-md max-w-sm">
          <div className="modal-card-head text-right relative">
            <button
              type="button"
              className="absolute md:relative -right-3 -top-3 md:-right-6 md:-top-4 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
              onClick={onClosePopup}
            >
              <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
            </button>
          </div>
          <div className="text-center sm:pb-6">
            <h2 className="mb-2">Digital Purchase</h2>
            <p className="text-base">
              Purchase a high resolution version
              <br />
              {`of this photo for £${config.PHOTO_COST}`}
            </p>
            <button
              type="button"
              className="btn btn-red btn-px-4 w-full"
              onClick={onClickAddToBasket}
            >
              Add to Basket
            </button>
          </div>
        </div>
      </div>
      <div className="modal-background" />
    </div>
  );
};

export default AddToBasketModal;
