import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/tomcom-dark.svg';

interface IProps {
  url: string;
}

const Brand = (props: React.PropsWithChildren<IProps>) => {
  const {
    url,
  } = props;
  return (
    <Link to={url} className="site-logo flex-none p-1 rounded-md">
      <img className="h-5 sm:h-6" src={logo} alt="TomCom" />
    </Link>
  );
};

export default Brand;
