import React from 'react';
import GetSVG, { SVGID } from './GetSVG';

interface IProps {
  label?: string;
  containerClass?: string;
}

const Loader = (props: React.PropsWithChildren<IProps>) => {
  const {
    label,
    containerClass,
  } = props;

  return (
    <div className={`flex items-center flex-nowrap text-xs font-bold mb-6 ${containerClass}`}>
      <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden animate-spin h-6 w-6 mr-3 text-themered" />
      {label}
    </div>
  );
};

Loader.defaultProps = {
  containerClass: '',
  label: '',
};

export default Loader;
