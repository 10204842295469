import React, { Fragment, useEffect, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import withLightBox from '../../../HOC/withLightBox';

interface IProps {
  imageCarousel: any[],
}

const ArticlePopupImageCarousel = (props: React.PropsWithChildren<IProps>) => {
  const {
    imageCarousel,
  } = props;
  const [validValue, setValidValue] = useState(true);
  useEffect(() => {
    let validVal = false;
    for (let i = 0; i < imageCarousel.length; i += 1) {
      if (imageCarousel[i]) {
        validVal = true;
        break;
      }
    }
    setValidValue(validVal);
  }, []);

  if (!validValue) {
    return null;
  }

  const splideOptions = {
    type: 'slide',
    arrowPath: 'M30.2389 19.2026L11.3695 0.333211C10.9252 -0.11107 10.2127 -0.11107 9.76841 0.333211C9.32413 0.777493 9.32413 1.49002 9.76841 1.9343L27.8331 19.999L9.76841 38.0636C9.32413 38.5079 9.32413 39.2204 9.76841 39.6647C9.98636 39.8826 10.2797 40 10.5648 40C10.8498 40 11.1432 39.891 11.3611 39.6647L30.2305 20.7953C30.6748 20.3594 30.6748 19.6385 30.2389 19.2026Z',
    perPage: 3,
    gap: '1rem',
    arrows: imageCarousel.length > 1,
    breakpoints: {
      400: {
        arrows: false,
        perPage: 2,
        perMove: 1,
      },
    },
  };
  return (
    <div className="mb-6">
      <Splide
        className="slider-block is-card-slider"
        hasTrack={false}
        options={splideOptions}
      >
        <SplideTrack>
          {imageCarousel.map((imgItem) => (
            <Fragment key={imgItem?.id}>
              {imgItem && (
                <SplideSlide>
                  <a
                    href={imgItem.high_path}
                    className="overflow-hidden glightbox is-card-zoom ratio ratio-1x1 !rounded-lg block"
                    data-gallery="postimg_image_carousel"
                  >
                    <img src={imgItem.high_path} alt="Img Title" className="object-contain w-full img is-img-zoom" />
                  </a>
                </SplideSlide>
              )}
            </Fragment>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  );
};

export default withLightBox(ArticlePopupImageCarousel);
