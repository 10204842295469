import React, { useEffect, useState } from 'react';
import GetSVG, { SVGID } from '../../Common/GetSVG';

interface IProps {
  allDivsAndYears: any[],
  allEventCategories: any[],
  activeYears: string[],
  activeSchools: string[],
  activeCategories: string[],
  onClose: () => void,
  onChangeFilter: (
    selectedYears: string[],
    selectedSchools: string[],
    selectedCategories: string[]
  ) => void
}

const CalendarFilterPopup = (props: React.PropsWithChildren<IProps>) => {
  const {
    allDivsAndYears,
    allEventCategories,
    activeYears,
    activeSchools,
    activeCategories,
    onClose,
    onChangeFilter,
  } = props;

  const [selectedYears, setSelectedYears] = useState<string[]>([...activeYears]);
  const [selectedSchools, setSelectedSchools] = useState<string[]>([...activeSchools]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([...activeCategories]);

  useEffect(() => {
    document.body.classList.add('overflow-modal');
    setTimeout(() => {
      document.getElementById('filter-modal')!.classList.add('active');
    }, 100);
  }, []);

  const onClosePopup = () => {
    document.body.classList.remove('overflow-modal');
    document.getElementById('filter-modal')!.classList.remove('active');
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const onSelectedYearChange = (year: string) => {
    const curYears = selectedYears;
    const index = curYears.indexOf(year);
    if (index < 0) {
      curYears.push(year);
    } else {
      curYears.splice(index, 1);
    }
    setSelectedYears([...curYears]);
  };

  const onSelectedSchoolChange = (school: string) => {
    const curSchools = selectedSchools;
    const index = curSchools.indexOf(school);
    if (index < 0) {
      curSchools.push(school);
    } else {
      curSchools.splice(index, 1);
    }
    setSelectedSchools([...curSchools]);
  };

  const onSelectedCategoryChange = (Category: string) => {
    const curCategories = selectedCategories;
    const index = curCategories.indexOf(Category);
    if (index < 0) {
      curCategories.push(Category);
    } else {
      curCategories.splice(index, 1);
    }
    setSelectedCategories([...curCategories]);
  };

  const onResetFilter = () => {
    setSelectedYears([]);
    setSelectedSchools([]);
    setSelectedCategories([]);
  };

  const onApplyFilter = () => {
    onChangeFilter(selectedYears, selectedSchools, selectedCategories);
    onClosePopup();
  };

  return (
    <>
      <div id="filter-modal" className="modal">
        <div className="modal-card">
          <div className="modal-card-body max-w-3xl">
            <div className="modal-card-head text-right relative">
              <button
                type="button"
                className="absolute md:relative right-0 h-8 w-8 text-black ml-auto group hover:bg-themered hover:bg-opacity-5 group flex items-center justify-center text-sm font-light rounded-md cursor-pointer --jbi-modal-close"
                onClick={onClosePopup}
              >
                <GetSVG ID={SVGID.CLOSE_ICON} svgClass="i-svg fill-themered h-4 w-4 group-hover:fill-themered group-focus:fill-themered" />
              </button>
            </div>
            <div className="modal-card-info">
              <h2 className="h3 mb-2">School</h2>
              <div className="filter-items relative overflow-y-auto max-h-[215px] mb-6 -mr-2 pr-2 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                {allDivsAndYears.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    className={`btn-filter-item toggle-filter ${selectedSchools.includes(item.name) ? 'selected' : ''}`}
                    onClick={() => onSelectedSchoolChange(item.name)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
              <h2 className="h3 mb-2">Year</h2>
              <div className="filter-items relative overflow-y-auto max-h-[215px] mb-6 -mr-2 pr-2 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                {allDivsAndYears.map((divs) => (
                  divs.years.map((year: any) => (
                    <button
                      key={year.id}
                      type="button"
                      className={`btn-filter-item toggle-filter ${selectedYears.includes(year.name) ? 'selected' : ''}`}
                      onClick={() => onSelectedYearChange(year.name)}
                    >
                      {year.name}
                    </button>
                  ))
                ))}
              </div>
              <h2 className="h3 mb-2">Category</h2>
              <div className="filter-items relative overflow-y-auto max-h-[215px] mb-6 -mr-2 pr-2 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 md:gap-4">
                {allEventCategories.map((type) => (
                  <button
                    key={type.id}
                    type="button"
                    className={`btn-filter-item toggle-filter ${selectedCategories.includes(type.name) ? 'selected' : ''}`}
                    onClick={() => onSelectedCategoryChange(type.name)}
                  >
                    {type.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="modal-card-foot pt-4 grid grid-cols-2 gap-4">
              <button type="button" className="btn-link" onClick={onResetFilter}>Reset Filters</button>
              <button type="button" className="btn btn-red" onClick={onApplyFilter}>Apply</button>
            </div>
          </div>
        </div>
        <div className="modal-background" />
      </div>
    </>
  );
};

export default CalendarFilterPopup;
