import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BasketItem from './BasketItem';
import { toggleRightSidebar } from '../../../helpers/uiHelper';
import EmptyBasketImg from '../../../assets/img/img-empty.svg';
import { UISidebarType } from '../../../store/actiontypes/uiActionTypes';
import OverlayBlock from '../../Common/overlayBlock';
import { getBasket, setBasket } from '../../../actions/AuthUtil';
import CheckoutModal from './CheckoutModal';
import config from '../../../config/config';
import { isVideoFile } from '../../../helpers/commonHelper';

const BasketSidebar = () => {
  const dispatch = useDispatch();
  const [medias, setMedias] = useState<any[]>([]);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(UISidebarType.BASKET_SIDEBAR);
      if (el) {
        el.classList.add('!right-0');
      }
    }, 100);

    const basketObj = getBasket();
    setMedias(basketObj);
  }, []);

  const onSuccessPayment = () => {
    const basketObj = getBasket();
    setMedias(basketObj);
  };

  const onCloseBar = () => {
    toggleRightSidebar(UISidebarType.BASKET_SIDEBAR, document, dispatch);
  };

  const removeMedia = (id: number) => {
    const curMedias = medias;
    const index = curMedias.findIndex(item => item.id === id);
    if (index >= 0) {
      curMedias.splice(index, 1);
      setMedias([...curMedias]);
      setBasket(curMedias, dispatch);
    }
  };

  return (
    <>
      <div
        id={UISidebarType.BASKET_SIDEBAR}
        className="sidebar-popup max-h-full flex flex-col fixed z-[39] bottom-0 top-16 transition-all duration-300 ease-in-out bg-themebody-sidebar w-full drop-shadow-lg -right-full md:top-24 md:max-w-[25rem] xl:max-w-[25rem] 2xl:max-w-[29rem]"
      >
        <div className={`pt-6 px-4 pb-6 sticky z-10 right-0 left-0 top-0 bg-white ${medias.length > 0 ? 'pb-6' : ''}`}>
          <div className={`flex gap-2 items-center ${medias.length > 0 ? 'pb-12' : 'pb-2'}`}>
            <button
              type="button"
              className="h-8 w-8 text-black group relative group flex items-center justify-center text-sm font-light rounded-md cursor-pointer hover:bg-themered hover:bg-opacity-5 focus:bg-themered focus:bg-opacity-5"
              onClick={onCloseBar}
            >
              <em className="i- i-arrow-left" />
            </button>
            <h2 className="text-2xl">Basket</h2>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <div className="text-xs pb-1">Total Cost:</div>
              <span className="text-sm font-bold">{`£${(medias.length * config.PHOTO_COST).toFixed(2)}`}</span>
            </div>
            <button
              type="button"
              onClick={() => setShowCheckout(true)}
              className="btn btn-red !pl-10 !pr-10"
              disabled={medias.length === 0}
            >
              Checkout
            </button>
          </div>
        </div>
        <div className="px-4 py-6 overflow-y-auto overflow-auto-themered-only flex-auto">
          <div className="pb-6">
            {medias.map((item) => (
              <BasketItem
                key={item.id}
                imageName={item.file_name}
                price={(config.PHOTO_COST).toFixed(2)}
                studentName={item.studentName}
                imagePath={item.thumb_path}
                isVideo={isVideoFile(item.file_path)}
                actionText="Remove"
                onActionClick={() => removeMedia(item.id)}
              />
            ))}
            {medias.length > 0 && (
              <div className="pt-3">
                <button
                  type="button"
                  className="no-underline underline-offset-4 text-black text-sm font-semibold hover:text-themered hover:underline"
                  onClick={onCloseBar}
                >
                  +Add more to this order
                </button>
              </div>
            )}
            {medias.length === 0 && (
              <div className="text-center p-6 pt-20">
                <div className="mx-auto inline-block">
                  <img src={EmptyBasketImg} alt="There’s nothing here!" />
                </div>
                <div className="py-6">
                  <strong className="text-lg font-bold">
                    Your basket is empty
                  </strong>
                  <br />
                  <Link
                    to={{
                      pathname: '/gallery',
                      state: { tab: 2 },
                    }}
                    className="text-themered font-semibold text-xs underline hover:text-black focus:text-black"
                    onClick={onCloseBar}
                  >
                    You can purchase downloads from
                    <br />
                    the Formal gallery
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <OverlayBlock
        id="basket_popup_mask"
        onClickOverlay={onCloseBar}
      />
      {showCheckout && (
        <CheckoutModal
          media={medias}
          onSuccess={() => onSuccessPayment()}
          onClose={() => setShowCheckout(false)}
        />
      )}
    </>
  );
};

export default BasketSidebar;
