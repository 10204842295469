import React, { useEffect, useState } from 'react';
import withLightBox from '../../../HOC/withLightBox';
import { getVideoThumbnailByUrl } from '../../../../actions/mspUser';

interface IProps {
  id: number,
  videoUrl: string,
  videoThumb: string,
}

const ArticlePopupVideo = (props: React.PropsWithChildren<IProps>) => {
  const {
    id,
    videoUrl,
    videoThumb,
  } = props;

  const [vimeoThumb, setVimeoThumb] = useState<string>('');

  useEffect(() => {
    if (!videoThumb) {
      getVideoThumbnailByUrl(videoUrl)
        .then((res:any) => {
          if (res) {
            setVimeoThumb(res);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }, []);

  return (
    <a
      href={videoUrl}
      className="mb-6 post-card is-card-zoom block overflow-hidden rounded-3xl shadow-lg h-full group glightbox"
      data-gallery={`postimg${id}`}
    >
      <div className="post-card-head max-h-[30rem] relative overflow-hidden">
        <span className="post-play absolute inset-0 flex items-center justify-center transition ease-in-out delay-150 hover:scale-110 duration-300">
          <svg className="i-svg h-20 w-20" viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="91" cy="91" r="83" stroke="white" strokeWidth="16" />
            <path d="M125.541 86.3826C128.82 88.2927 128.859 93.0159 125.613 94.9806L82.1906 121.258C78.8744 123.265 74.6345 120.898 74.6021 117.022L74.1688 65.1969C74.1364 61.3208 78.3361 58.8836 81.6855 60.8348L125.541 86.3826Z" fill="white" />
          </svg>
        </span>
        {(videoThumb || vimeoThumb) && (
          <div
            className="object-cover w-full aspect-video is-img-zoom bg-black"
            style={{ backgroundImage: `url(${videoThumb || vimeoThumb})` }}
          />
        )}
      </div>
    </a>
  );
};

export default withLightBox(ArticlePopupVideo);
