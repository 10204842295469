import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StudentSelection, { studentData } from '../../Common/StudentSelection';
import NoticeListBox from './NoticeListBox';
import PinnedItems from './PinnedItems';
import NoticeDetailSidebar from './NoticeDetailSidebar';
import { getNoticeById } from '../../../actions/mspUser';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';

const Inbox = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<studentData | null>(null);
  const [viewNoticeItem, setViewNoticeItem] = useState<any>(null);
  const [isNTK, setIsNTK] = useState<boolean>(true);

  const onItemChange = (item: studentData) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const noticeId: number = parseInt(query.get('id') || '0', 10);
    if (noticeId <= 0) {
      return;
    }

    // history.replace({ state: {} });
    getNoticeById(noticeId)
      .then((result: any) => {
        setViewNoticeItem(result?.data);
      })
      .catch(() => {
      });
  }, []);

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_NEEDTOKNOW} />
      <section className="main-section">
        <div className="main-content-full grow -mx-4 lg:mx-0 p-4 !pt-0 lg:p-6">
          <StudentSelection isNeedToNow onItemChange={onItemChange} />
          <div className="main-sidebar-section space-x-4 sm:space-x-8 xl:space-x-16 flex flex-col-reverse flex-wrap sm:flex-row sm:flex-nowrap">
            <NoticeListBox
              studentId={selectedItem?.studentId || -1}
              divisionId={selectedItem?.schoolId || -1}
              division={selectedItem?.schoolName || ''}
              isNTK={isNTK}
              onClickNotice={(notice) => setViewNoticeItem(notice)}
            />
            <div className="sidebar-content flex-none w-full sm:w-64 lg:w-[22rem] max-h-full sticky top-16 z-10">
              <PinnedItems
                isNTK={isNTK}
                setNTK={(isNtk) => setIsNTK(isNtk)}
                divisionId={selectedItem?.schoolId || -1}
              />
            </div>
          </div>
        </div>
      </section>
      {viewNoticeItem && (
        <NoticeDetailSidebar
          onClose={() => setViewNoticeItem(null)}
          notice={viewNoticeItem}
        />
      )}
    </>
  );
};

export default Inbox;
