import React from 'react';
import { Helmet } from 'react-helmet';

export const PAGEID = {
  PAGE_DASHBOARD: 'PAGE_DASHBOARD',
  PAGE_NEEDTOKNOW: 'PAGE_NEEDTOKNOW',
  PAGE_HIGHLIGHT: 'PAGE_HIGHLIGHT',
  PAGE_NEWS: 'PAGE_NEWS',
  PAGE_NEWSDETAIL: 'PAGE_NEWSDETAIL',
  PAGE_ARTICLE: 'PAGE_ARTICLE',
  PAGE_CALENDAR: 'PAGE_CALENDAR',
  PAGE_GALLERY: 'PAGE_GALLERY',
  PAGE_MYACCOUNT: 'PAGE_MYACCOUNT',
  PAGE_AUTHENTICATE: 'PAGE_AUTHENTICATE',
  PAGE_LOGIN: 'PAGE_LOGIN',
  PAGE_ACCESSDENIED: 'PAGE_ACCESSDENIED',
  PAGE_NOTFOUND: 'PAGE_NOTFOUND',
  PAGE_SERVERERROR: 'PAGE_SERVERERROR',
  PAGE_ROLES: 'PAGE_ROLES',
  PAGE_PAYMENTCONFIRMED: 'PAGE_PAYMENTCONFIRMED',
};

interface IProps {
  page: string;
  customTitle?: string;
}

const GetMetaHeader = (props: React.PropsWithChildren<IProps>) => {
  const {
    customTitle,
    page,
  } = props;

  let title = '';
  if (!customTitle || customTitle.length === 0) {
    switch (page) {
      case PAGEID.PAGE_DASHBOARD:
        title = 'Home';
        break;
      case PAGEID.PAGE_NEEDTOKNOW:
        title = 'Need to Know';
        break;
      case PAGEID.PAGE_HIGHLIGHT:
        title = 'Highlights';
        break;
      case PAGEID.PAGE_NEWS:
        title = 'News';
        break;
      case PAGEID.PAGE_NEWSDETAIL:
        title = 'News Detail';
        break;
      case PAGEID.PAGE_ARTICLE:
        title = 'Article';
        break;
      case PAGEID.PAGE_CALENDAR:
        title = 'Calendar';
        break;
      case PAGEID.PAGE_GALLERY:
        title = 'Gallery';
        break;
      case PAGEID.PAGE_MYACCOUNT:
        title = 'My Account';
        break;
      case PAGEID.PAGE_LOGIN:
        title = 'Login';
        break;
      case PAGEID.PAGE_AUTHENTICATE:
        title = 'Authenticate';
        break;
      case PAGEID.PAGE_ACCESSDENIED:
        title = 'Access Denied';
        break;
      case PAGEID.PAGE_NOTFOUND:
        title = 'Page Not Found';
        break;
      case PAGEID.PAGE_SERVERERROR:
        title = 'Server Error';
        break;
      case PAGEID.PAGE_ROLES:
        title = 'Role Selection';
        break;
      case PAGEID.PAGE_PAYMENTCONFIRMED:
        title = 'Payment Confirmed';
        break;
      default:
        title = '';
    }
  } else {
    title = customTitle;
  }

  if (title.length > 0) {
    title = `${title} | Tomcom`;
  }

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

GetMetaHeader.defaultProps = {
  customTitle: '',
};

export default GetMetaHeader;
