import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import OrderItem from './OrderItem';
import EmptyBasketImg from '../../../assets/img/img-empty.svg';
import { getOrderHistories } from '../../../actions/mspUser';
import Loader from '../../Common/Loader';

interface IProps {
  onClickOrderItem: (id: number) => void
}

const MyOrders = (props: React.PropsWithChildren<IProps>) => {
  const {
    onClickOrderItem,
  } = props;

  const [orders, setOrders] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [curPage, setCurPage] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const loadFunc = (pageToFetch: number) => {
    setLoading(true);
    getOrderHistories(pageToFetch)
      .then((result: any) => {
        if (pageToFetch === 1) {
          setOrders([...result.data]);
        } else {
          setOrders([...orders, ...result.data]);
        }

        const totalPages = result.meta?.last_page;
        const totalFetchedPage = result.meta?.current_page;
        setHasMore(totalPages !== totalFetchedPage);
        setCurPage(totalFetchedPage);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadFunc(1);
  }, []);

  return (
    <div className="sidebar-content bg-themebody-bg -mx-4 lg:mx-0 p-4 lg:p-6 flex-none w-auto lg:w-[22rem] max-h-full">
      <div className="lg:fixed lg:w-80">
        <div className="flex justify-between items-center mb-4 lg:pr-0">
          <h2 className="mr-4">My Orders</h2>
        </div>
        <div id="myorder-sidebar" className="flex justify-start flex-wrap -mx-4 mr-0 py-0 p-4 lg:py-4 overflow-x-auto lg:rounded-lg max-h-[calc(100vh_-_11rem)] overflow-y-scroll overflow-auto-themered-only">
          <InfiniteScroll
            dataLength={orders.length}
            next={() => loadFunc(curPage + 1)}
            scrollThreshold="20px"
            scrollableTarget="myorder-sidebar"
            hasMore={hasMore}
            loader={<Loader containerClass="ml-2 mt-2" label="Orders Loading..." />}
          >
            {orders.map((item: any) => (
              <OrderItem
                key={item.id}
                reference={item.reference}
                date={item.order_date}
                total={item.total}
                onClickItem={() => onClickOrderItem(item.id)}
              />
            ))}
          </InfiniteScroll>
        </div>
        {orders.length === 0 && !loading && (
          <div className="text-center p-6 pt-20">
            <div className="mx-auto inline-block">
              <img src={EmptyBasketImg} alt="There’s nothing here!" />
            </div>
            <div className="py-6">
              <strong className="text-lg font-bold">
                There’s nothing here!
              </strong>
              <br />
              <Link
                to="/gallery"
                className="text-themered font-semibold text-xs underline hover:text-black focus:text-black"
              >
                Why not look around and
                <br />
                order something?
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
