import React, { useRef } from 'react';
import moment from 'moment';
import { animateOnMouseMove } from '../../helpers/uiHelper';
import magazineDeskImg from '../../assets/img/magazine-d.png';
import magazineMobImg from '../../assets/img/magazine-m.png';

interface IProps {
  issueDate: string;
  schoolName: string;
  routePath: string;
}

const MagazineCard = (props: React.PropsWithChildren<IProps>) => {
  const {
    issueDate,
    schoolName,
    routePath,
  } = props;
  const containerRef = useRef(null);

  const onMouseMoveEvent = (e: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => {
    e.preventDefault();
    animateOnMouseMove(e, containerRef?.current);
  };

  const parsedDate = moment(`${issueDate}/01`, 'YYYY/MM/DD');
  const month = parsedDate.format('MM');
  const year = parsedDate.format('YYYY');
  return (
    <a
      ref={containerRef}
      href={routePath}
      target="_blank"
      rel="noreferrer"
      className="is-hover flex flex-col justify-between gap-10 xl:gap-0 xl:flex-initial xl:flex-row bg-white rounded-2xl shadow-lg h-full transition-all group"
      onMouseEnter={onMouseMoveEvent}
      onMouseLeave={onMouseMoveEvent}
    >
      <div className="xl:h-28">
        <img className="object-cover object-left-top w-36 h-28 xl:block hidden" src={magazineDeskImg} alt="Thomas’s Magazine" />
        <img className="w-full block xl:hidden" src={magazineMobImg} alt="Thomas’s Magazine" />
      </div>
      <div className="md:p-4 p-2 flex justify-between gap-1 md:gap-3 flex-row">
        <div className="flex items-center">
          <div className="text-4xl sm:text-5xl font-bold transition duration-500 ease-in-out --is-text-hover">{month}</div>
          <span className="sm:text-5xl text-4xl font-bold leading-none text-themered sm:pl-2.5 pl-1">/</span>
        </div>
        <div className="text-right mt-auto">
          <h3 className="text-xs sm:text-base font-normal leading-tight text-black text-overflow transition duration-500 ease-in-out --is-text-hover">
            {schoolName}
            <br />
            Magazine
          </h3>
          <div className="lg:text-sm text-lg text-themered font-bold transition duration-500 ease-in-out --is-text-hover">{year}</div>
        </div>
      </div>
    </a>
  );
};

MagazineCard.defaultProps = {};

export default MagazineCard;
