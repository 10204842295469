import React, { useEffect, useRef, useState } from 'react';
import {
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getLoginToken, refreshLoginToken } from '../../../actions/mspUser';
import tomcomImg from '../../../assets/img/tomcom-unicorn-logo.svg';
import GetSVG, { SVGID } from '../../Common/GetSVG';
import { clearAllAuthData, getAuthToken } from '../../../actions/AuthUtil';
import GetMetaHeader, { PAGEID } from '../../Common/seoHelper';

const Authentication = (props: RouteComponentProps) => {
  const showStoreLink = useRef(1);
  const wFocusRef = useRef(1);
  const history = useHistory();
  const { location } = props;
  const [loginToken, setLoginToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showError, setShowError] = useState('');
  const [refreshTokenUrl, setRefreshTokenUrl] = useState('');
  const [loginLink, setLoginLink] = useState('/login');
  const [showPage, setShowPage] = useState(false);

  const getDeviceType = () => {
    // @ts-ignore
    const t = window.navigator.userAgent || window.navigator.vendor || window.opera;
    let device = '';
    if (/windows phone/i.test(t)) {
      device = 'desktop';
    } else if (/android/i.test(t) || /crios/i.test(t)) {
      device = 'android';
    } else if (/iPad|iPhone|iPod/.test(t) && !window.MSStream) {
      device = 'ios';
    } else {
      device = 'desktop';
    }
    return device;
  };

  const iosBlurListener = () => {
    wFocusRef.current = 0;
  };

  const iosFocusListener = () => {
    wFocusRef.current = 1;
    window.removeEventListener('focus', iosFocusListener);
    setTimeout(() => {
      if (wFocusRef.current === 1) {
        window.location.href = 'https://apps.apple.com/gb/app/tomcom/id1633602585';
      }
    }, 1500);
  };

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      history.push('/home');
      return;
    }

    const deviceType = getDeviceType();
    if (deviceType === 'desktop') {
      setLoginLink('/login');
    } else {
      setLoginLink('tomcom://');
    }

    /* window.addEventListener('pagehide', () => {
      alert('hide');
    });

    window.addEventListener('pageshow', () => {
      alert('show');
    }); */

    document.addEventListener('visibilitychange', () => {
      showStoreLink.current = 0;
    });

    const query = new URLSearchParams(location.search);
    let ssoToken = query.get('sso');
    if (!ssoToken) {
      let searchStr = location.search;
      if (searchStr.indexOf('?=') === 0) {
        searchStr = searchStr.substr(2);
        if (searchStr && searchStr.length > 0) {
          ssoToken = searchStr;
        }
      }
    }

    if (!ssoToken) {
      // @ts-ignore
      // eslint-disable-next-line react/destructuring-assignment
      const pathsso = props?.match?.params?.sso || '';
      if (pathsso) {
        const splitstr = pathsso.split('=');
        if (splitstr && splitstr.length > 1 && splitstr[0].toLowerCase() === 'sso') {
          // eslint-disable-next-line
          ssoToken = splitstr[1];
        }
      }
    }

    if (ssoToken) {
      const { match } = props;
      // @ts-ignore
      const { school } = match?.params || '';
      setLoading(true);
      getLoginToken(school, ssoToken)
        .then((result: any) => {
          setLoginToken(result?.login_token || '');
          setRefreshTokenUrl(result?.refresh_token?.url || '');
          setLoading(false);
          setShowError('');
        })
        .catch(error => {
          if (error.status === 500) {
            setShowError('Invalid SSO token');
          } else {
            setShowError(error?.data?.message);
          }
          setLoading(false);
        });
    } else {
      setShowError('No SSO token provided');
    }

    setShowPage(true);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('focus', iosFocusListener);
      window.removeEventListener('blur', iosBlurListener);
    };
  }, []);

  const onRefreshTokenClick = () => {
    setRefreshLoading(true);
    if (refreshTokenUrl.length > 0) {
      refreshLoginToken(refreshTokenUrl)
        .then((result: any) => {
          setLoginToken(result?.login_token || '');
          setRefreshTokenUrl(result?.refresh_token?.url || '');
          setRefreshLoading(false);
          setCopied(false);
        })
        .catch(() => {
          setRefreshLoading(false);
        });
    }
  };

  /* const isPageHidden = () => {
    const browserSpecificProps = { hidden:1, mozHidden:1, msHidden:1, webkitHidden:1 };
    for (const p in browserSpecificProps) {
      // @ts-ignore
      if (typeof document[p] !== 'undefined') {
        // @ts-ignore
        return document[p];
      }
    }
    return false; // Actually inconclusive, assuming not
  }; */

  const onLoginLinkClick = (e: any) => {
    e.preventDefault();
    clearAllAuthData();
    showStoreLink.current = 1;
    const deviceType = getDeviceType();
    if (deviceType === 'desktop') {
      window.location.href = loginLink;
    } else {
      const now = new Date().valueOf();
      if (deviceType === 'android') {
        setTimeout(() => {
          if (new Date().valueOf() - now > 1350) {
            return;
          }

          if (showStoreLink.current === 1) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.jbi.tomcom';
          }
        }, 1300);
      } else if (deviceType === 'ios') {
        setTimeout(() => {
          if (new Date().valueOf() - now > 100) {
            return;
          }

          // handle event to check app installed or not
          window.addEventListener('focus', iosFocusListener);
          window.addEventListener('blur', iosBlurListener);
        }, 50);
      }
      window.location.href = loginLink;
    }
  };

  if (!showPage) {
    return null;
  }

  return (
    <>
      <GetMetaHeader page={PAGEID.PAGE_AUTHENTICATE} />
      <section className="max-w-3xl flex justify-center mx-auto items-center min-h-screen relative sm:py-4 bg-white sm:bg-transparent">
        <div className="bg-white sm:drop-shadow-xl sm:rounded-3xl">
          <div className="flex flex-col items-center 2xl:py-16 xl:py-10 2xl:px-16 xl:px-10 py-14 px-4 relative">
            <img src={tomcomImg} alt="TomCom" />
            <h1 className="text-2xl font-bold text-navy pt-4 pb-6 max-w-sm text-center lg:text-3xl lg:leading-10">
              Authenticate Your
              <br />
              TomCom Account
            </h1>
            <div className="2xl:mb-12 xl:mb-8 mb-14">
              <div className="mx-auto bg-themebody-bg rounded-l-xl">
                <div className="flex items-center mx-auto">
                  <div
                    className={`${loginToken ? 'px-5' : 'w-48 lg:w-96'} h-16 lg:h-20 flex items-center justify-center text-2xl whitespace-nowrap font-bold text-themered select-all lg:text-5xl lg:tracking-widest`}
                    id="text"
                  >
                    {loginToken}
                  </div>
                  <CopyToClipboard text={loginToken} onCopy={() => setCopied(true)}>
                    <button
                      type="button"
                      className={`flex items-center bg-navy h-16 lg:h-20 px-5 rounded-r-xl transition-all hover:bg-themered ${loading ? 'cursor-progress' : ''} ${copied ? '!bg-green-600' : ''}`}
                      id="copy"
                      title="Copy to clipboard"
                    >
                      {loading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden animate-spin flex-none h-5 w-5 text-white" />}
                      {!loading && <GetSVG ID={SVGID.COPY_ICON} svgClass="i-svg h-5 w-5" />}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              {showError && (
                <p className="help-block">
                  <span className="font-medium">Oops!</span>
                  {' '}
                  {showError}
                </p>
              )}
            </div>
            <h2 className="text-sm text-center font-bold pb-6 lg:text-lg">
              TOKEN for TomCom App
              <br />
              Link your My School Portal and TomCom Accounts
            </h2>
            <a
              href={loginLink}
              className="btn btn-red mb-12 w-full sm:w-80"
              onClick={onLoginLinkClick}
            >
              Log in to TomCom
            </a>
            <p className="text-center mb-6 text-xs lg:text-base">
              *Your Token can be found on your My School Portal Dashboard. Each
              {' '}
              <br className="sm:block hidden" />
              token is unique and required only the first time you log in.
            </p>
            <button type="button" className="btn w-full sm:w-80" onClick={onRefreshTokenClick}>
              {refreshLoading && <GetSVG ID={SVGID.LOADER_ICON} svgClass="motion-reduce:hidden animate-spin flex-none -ml-1 mr-3 h-5 w-5 text-white" />}
              Refresh Token
            </button>
          </div>
        </div>
        <div className="bg-navy h-1 w-32 absolute rounded-full bottom-2 left-0 right-0 mx-auto my-auto lg:hidden block" />
      </section>
    </>
  );
};

export default withRouter(Authentication);
